
import { Box, Button, Container, IconButton, MenuItem, Select, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import PersonalDetails from "../Components/OnboardingForms/PersonalDetails";
import SpouseDetails from "../Components/OnboardingForms/SpouseDetails";
import DependentsDetails from "../Components/OnboardingForms/DependentDetails";
import ElectronicBankingDetails from "../Components/OnboardingForms/ElectronicBanking";
import OccupationDetails from "../Components/OnboardingForms/Occupation";
import IncomeTypes from "../Components/OnboardingForms/IncomeTypes";
import InvestmentPropertyDetails from "../Components/OnboardingForms/InvestmentProperty";
import CompanyDetails from "../Components/OnboardingForms/CompanyDetails";
import TrustDetails from "../Components/OnboardingForms/TrustDetails";
import BankInterestDetails from "../Components/OnboardingForms/BankInterestDetails";
import DividendDetails from "../Components/OnboardingForms/DividendDetails";
import OnboardingDoneButton from "../Components/OnboardingDoneButton";
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { saveTempDetails } from "../Components/Features/temporarydata";
import Login from "./Login";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { SendEmailFunction } from "../GlobalVariables/SendEmailFunction";
import { useHistory } from "react-router-dom";
import { updateKTACustomer } from "../graphql/mutations";
import { updatePersonalFields } from "../Components/Features/profile";

const NewOnboarding = (props) => {
    
    const employees = useSelector(state => state.employees.value);
    const profile = useSelector(state => state.profile.value);
    const history = useHistory();

    const user = useSelector(state => state.user.value);
    const dispatch = useDispatch();
    const [customerfacingemployees, setCustomerFacingEmployees] = useState([]);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [custid, setCustid] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dob, setDob] = useState(null);
    const [tfn, setTfn] = useState("");
    const [straddr, setStraddr] = useState("");
    const [password, setPassword] = useState("");
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [onboardingAgent, setOnboardingAgent] = useState(profile.engagingtaxagent);
    
    
    useEffect(() => {
      if (employees) {
        setCustomerFacingEmployees( employees.filter(emp => emp.customerfacing_flag === true));
      }
    }, [employees]);

    useEffect(() => {
      if (profile) {
        setOnboardingAgent( profile.engagingtaxagent);
        onboardingAgentForm.initialValues.onboardingAgent = profile.engagingtaxagent;
        setFname(profile.fname);
        setCustid(profile.id);
      }
    }, [profile]);

    useEffect(() => {
        const updateOnlineStatus = () => {
          setIsOnline(navigator.onLine);
        };
    
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    
        return () => {
          window.removeEventListener('online', updateOnlineStatus);
          window.removeEventListener('offline', updateOnlineStatus);
        };
      }, []);

    useEffect(() => {
        if (employees) {
          setCustomerFacingEmployees( employees.filter(emp => emp.customerfacing_flag === true));
        }
      }, [employees]);

    const handleLogout = () => {
        window.localStorage.removeItem('COGNITO_USER');
        props.signout();
    }
    const [step, setStep] = useState(1);
    const handleNext = () => {
      if (step===6 && user){
        setStep(8);
      } else {
        setStep(step+1);
      }
    }
    const handlePrevious = () => {
        setStep(step-1);
    }

    function sendEmail(subject) {

        var inputs = {
            fromEmail: 'info@karvistax.com.au',
            toEmails: JSON.stringify(['itsupport@karvistax.com.au', 'info@karvistax.com.au']),
            subject: subject + fname,
            textbody: '', // Leave it blank
            title: subject + fname,
            greeting: 'Dear Karvistax Team,',
            body: subject + fname + '. Please check the details in the link below.',
            link: 'https://company.karvistax.com.au/clientsonboardingdata/' + custid
    
    
        }

        if (onboardingAgent !=='' && onboardingAgent !== null && onboardingAgent !== undefined && onboardingAgent !== 'dummy'){
            inputs.toEmails = JSON.stringify(['itsupport@karvistax.com.au', onboardingAgent, 'info@karvistax.com.au']);
            inputs.subject = subject + profile.fname + " | Onboarding Agent: " + onboardingAgent;
            SendEmailFunction(inputs);
        } else {
            inputs.toEmails = JSON.stringify(['itsupport@karvistax.com.au', 'info@karvistax.com.au']);
            inputs.subject = subject + profile.fname + " | Onboarding Agent: None Selected";
            SendEmailFunction(inputs);
        }
    
        
    
        
    
    }

    function sendSignInEmail(subject, customerID) {

      var inputs = {
          fromEmail: 'info@karvistax.com.au',
          toEmails: JSON.stringify(['itsupport@karvistax.com.au', 'info@karvistax.com.au']),
          subject: subject + fname,
          textbody: '', // Leave it blank
          title: subject + fname,
          greeting: 'Dear Karvistax Team,',
          body: subject + fname + '. Please check the details in the link below.',
          link: 'https://company.karvistax.com.au/clientsonboardingdata/' + customerID
  
  
      }

      if (onboardingAgent !=='' && onboardingAgent !== null && onboardingAgent !== undefined && onboardingAgent !== 'dummy'){
          inputs.toEmails = JSON.stringify(['itsupport@karvistax.com.au', onboardingAgent, 'info@karvistax.com.au']);
          inputs.subject = subject + profile.fname + " | Onboarding Agent: " + onboardingAgent;
          SendEmailFunction(inputs);
      } else {
          inputs.toEmails = JSON.stringify(['itsupport@karvistax.com.au', 'info@karvistax.com.au']);
          inputs.subject = subject + profile.fname + " | Onboarding Agent: None Selected";
          SendEmailFunction(inputs);
      }
  
      
  
      
  
  }
  
    

    const handleFinish = () => {
        sendEmail("Onboarding Form Submitted by: ");
        history.push("/thankyou");
    }

    const validationSchema = yup.object({
       
        dob: yup
            .date('Enter your date of birth')
            .required('Date of birth is required'),
        fName: yup
            .string('Enter your first name')
            .required('First name is required'),
        lName: yup
            .string('Enter your last name')
            .required('Last name is required'),
        strAddr: yup
            .string('Enter your street address')
            .required('Street address is required'),
        state: yup
            .string('Enter your state')
            .required('State is required'),
        postcode: yup
            .string()
            .matches(/^\d{4}$/, "4 digit Postcode")
            .required('Postcode is required'),
        phone: yup
                .string()
                .matches(/^04\d{8}$/, "10 digit  Mobile number starting with 04")
                .required('Phone number is required'),
        tfn: yup
            .string()
            .matches(/^\d{9}$/, "9 digit Tax File Number")
            .required('Tax File Number is required'),
    
      });

      const formik = useFormik({
        initialValues: { fName:'', lName:'', strAddr: '', state:'', postcode:'', phone:'', dob:null, tfn:'' },
        validationSchema: validationSchema,
        onSubmit: (values) => {
        //   alert(JSON.stringify(values, null, 2));
            setFname(values.fName);
            setLname(values.lName);
            setStraddr(values.strAddr+ " | " + values.state + " | " + values.postcode);
            setTfn(values.tfn);
            setPhone(values.phone);
            setDob(values.dob);

          dispatch(saveTempDetails(values));
            
            handleNext();
        },
      });

      const onboardingAgentValidationSchema = yup.object({
       
       
        onboardingAgent: yup
            .string('Enter your state')
            .required('State is required'),
    
      });

      const onboardingAgentForm = useFormik({
        initialValues: { onboardingAgent:'' },
        validationSchema: onboardingAgentValidationSchema,
        onSubmit: (values) => {
        
          setOnboardingAgent(values.onboardingAgent);
          
          
          let personaldata = {
            id: profile.id,
            engagingtaxagent: values.onboardingAgent,
          }
          API.graphql(graphqlOperation(updateKTACustomer, {input: personaldata})).then(res => {
            console.log("Customer Data", res);
            if (res.data.updateKTACustomer){
              console.log(res.data.updateKTACustomer);
              dispatch(updatePersonalFields(personaldata));
              handleNext();
              
            } else{
              console.error("Error updating customer data");
            
            }
          }).catch(e => {
            console.error(e);
            
          });
  

        },
      });

      const steps = [
        {
          label: 'Enter Email-ID and Password to create your account',
          description: `This will be your login credentials for the Karvis Customer Portal. Please use the email which you can access on a permanent basis. This email will be used for sending you "Verification Code" and other important notifications and updates.`,
        },
        {
          label: 'Verify your email',
          description:
            'Immediately after creating your account, you will receive a verification code on your email. Please enter the code to verify your email.',
        },
        {
          label: 'Login to Proceeed',
          description: `At this screen login with the same email and password which you used to create your account. You will be redirected to the next step.`,
        },
      ];

const [warningFlag, setWarningFlag] = useState(false);
const [warningheader, setwarningheader] = useState("");
const [warningtext, setwarningtext] = useState("");
const [UserNotConfirmedException, setUserNotConfirmedException] = useState(false);

      async function signUp(username, password) {
        try {
          const res = await Auth.signUp({
            username,
            password,
            autoSignIn: {
              enabled: false,
            }
          });
          setUserNotConfirmedException(true);      
          setWarningFlag(true);
            setwarningheader("Redirecting to verification page in 5 seconds...");
            setwarningtext("Please check your email.📝 Sometimes the code may land in Junk Mailbox.")
          console.log('sign up response', res);
          setTimeout(() => {
            setWarningFlag(false);
            handleNext();

            
          }, 5000);
        } catch (error) {
          console.log('error signing up:', error);
          setWarningFlag(true);
                  setwarningheader(error.name);
                  setwarningtext(error.message);
        }
      }

      const signIn = (username, password) => {
        
       
                Auth.signIn(username, password).then(response =>{
                  
                    setWarningFlag(true);
                    setwarningheader("Login Successful");
                    setwarningtext("You will be now be redirected to the next step in 10 seconds..")
                    setTimeout(() => {
                        setWarningFlag(false);
                        handleNext();
                        sendSignInEmail("New user or existing user logged into Onboarding Form | Customer Email:"+ response.attributes.email, response.attributes.sub);
                        
                        }, 10000);
                }).catch (err=>{
                    if (err.name === "UserNotConfirmedException"){
                        setUserNotConfirmedException(true);
                        setWarningFlag(true);
                        setwarningheader("User Still Not Confirmed");
                        setwarningtext(err.message);
    
                    } else {
                    console.log(err);
                    setWarningFlag(true);
                    setwarningheader(err.name);
                    setwarningtext(err.message);
                    }
                })
              
      
    
      };
      
      const signupValidationSchema = yup.object({
       
        email: yup
            .string()
            .matches(/\S+@\S+\.\S+/,"Please enter a valid email address"),
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('First name is required'),

    
      });

      const signinValidationSchema = yup.object({
       
      
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('First name is required'),

    
      });

      const signUpForm = useFormik({
        initialValues: { email:'', password:'' },
        validationSchema: signupValidationSchema,
        onSubmit: (values) => {
        //   alert(JSON.stringify(values, null, 2));
            setWarningFlag(false);
            setEmail(values.email);
            setPassword(values.password);
            signUp(values.email,values.password);
            
        },
      });

      const returningUserForm = useFormik({
        initialValues: { email:'', password:'' },
        validationSchema: signupValidationSchema,
        onSubmit: (values) => {
        //   alert(JSON.stringify(values, null, 2));
            setWarningFlag(false);
            setEmail(values.email);
            setPassword(values.password);
            signIn(values.email,values.password);
            
        },
      });

      const signInForm = useFormik({
        initialValues: { password:'' },
        validationSchema: signinValidationSchema,
        onSubmit: (values) => {
        //   alert(JSON.stringify(values, null, 2));
            setWarningFlag(false);
            
            setPassword(values.password);
            signIn(email,values.password);
            
        },
      });

      async function resendConfirmationCode() {
        try {
          await Auth.resendSignUp(email);
          setWarningFlag(true);
          setwarningheader("Code Sent Successfully");
          setwarningtext("Please check your email.📝 Sometimes the code may land in Junk 🍕🍔 Mailbox. Please check there as well.")
        } catch (err) {
          console.log('error resending code: ', err);
          setWarningFlag(true);
          setwarningheader(err.name);
          setwarningtext(err.message)
        }
      }
      async function confirmSignUp(code) {
        try {
          await Auth.confirmSignUp(email, code);
          setUserNotConfirmedException(false);
          window.localStorage.setItem('ONBOARDING_FLOW', "Yes");
          handleNext();
         
        } catch (error) {
          console.log('error confirming sign up', error);
          setWarningFlag(true);
          setwarningheader(error.name);
          setwarningtext(error.message)
        }
      }
    
      const codeValidationSchema = yup.object({
                                   
        code: yup
            .string()
            .min(4,"Code must be 4 digits or longer")
    
      });


      const codeUpForm = useFormik({
        initialValues: { code:''},
        validationSchema: codeValidationSchema,
        onSubmit: (values) => {
        //   alert(JSON.stringify(values, null, 2));
            setWarningFlag(false);
            
            confirmSignUp(values.code);
            
        },
      });

      const handleReturningUser = () => {
        if (!props.user){
        setStep(7);} else {
          setStep(8);
        }
      }

    return ( <div style={{backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
    backgroundRepeat: 'no-repeat', 
    backgroundAttachment:'fixed', 
    backgroundPosition:'center',
    
    height: "100%",
    
    backgroundSize: "cover",
    }}>
        {props.user && <div className="w3-top">
        <div className="w3-bar w3-blue w3-card">
        <div className="w3-button w3-right w3-padding-large" onClick={handleLogout}> Log Out </div>
        </div>
        </div>}
        {!isOnline && <div className="w3-top">
        <div className="w3-bar w3-red w3-card">
        <div className="w3-button w3-right w3-padding-large" > You are offline. Please check your internet connection.</div>
        </div>
        </div>} 

        {step===1&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div className="w3-center" >
            <br />
            <br />
            <img src="SVGLogo.svg" style={{width:'50%'}}></img>
            <Typography variant="h5" color={'primary'} style={{fontWeight: "bold"}}>Welcome to the Karvis Customer Portal</Typography>
            <Typography variant="h6" color={'primary'} >Customer Onboarding Form</Typography>
            <div style={{textAlign:'right'}}>
            </div>
            <hr />
            <br />
            <br />
            <Typography variant="body"  >In a few simple steps you will be onboarded on our digital platform.</Typography>
            <br />
            <br />
            
            <br />
            <Typography variant="h6" color='error' >!! Important Note !!</Typography>
            <Typography variant="body" color={'secondary'} > <b>This is not the customer portal.</b></Typography> <br />
            <Typography variant="body"  >  This app is for first time customer onboarding. <br/> If you have already onboarded in the past,<b>OR</b> If you want to commplete the details,  then</Typography>
            <br />
            <a className="w3-button w3-amber w3-round" href="/">Click here to <b> Login </b> to Karvis Customer Portal</a>
            <br />
            <hr />
            <br />
            <Typography variant="body"  >If its your first time.</Typography>
            <br />
            <Button variant="contained" color="primary" onClick={handleNext}>Continue</Button>
            <br />
            <br />
            <Typography variant="body"  >To continue onboarding from where you left.</Typography>
            <br />
            <Button variant="contained" color="warning" onClick={handleReturningUser}>Click Here</Button>
           </div>
        </Container>}

        {step===2&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div>
            <br />
            <Typography variant="h6" gutterBottom component="div" color={'primary'}> Lets start with some basic details. </Typography>
            <form onSubmit={formik.handleSubmit}>
           
            

                
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="fName"
                    name="fName"
                    label="First Name"
                    value={formik.values.fName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fName && Boolean(formik.errors.fName)}
                    helperText={formik.touched.fName && formik.errors.fName}
                />
                
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="lName"
                    name="lName"
                    label="Last Name"
                    value={formik.values.lName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lName && Boolean(formik.errors.lName)}
                    helperText={formik.touched.lName && formik.errors.lName}
                />
                
                <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="tfn"
                    name="tfn"
                    label="Tax File Number"
                    value={formik.values.tfn}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tfn && Boolean(formik.errors.tfn)}
                    helperText={formik.touched.tfn && formik.errors.tfn}
                />

                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="strAddr"
                    name="strAddr"
                    label="Street Address & Suburb"
                    value={formik.values.strAddr}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.strAddr && Boolean(formik.errors.strAddr)}
                    helperText={formik.touched.strAddr && formik.errors.strAddr}
                />
                
                <Typography sx={{marginTop:"10px"}} variant="caption" >State:</Typography>
                    <Select
                    fullWidth
                    id="state"
                    name="state"
                    label="State"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                >
                    <MenuItem value={""}>State</MenuItem>
                    <MenuItem value={"SA"}>South Australia</MenuItem>
                    <MenuItem value={"VIC"}>Victoria</MenuItem>
                    <MenuItem value={"NSW"}>New South Wales</MenuItem>
                    <MenuItem value={"QLD"}>Queensland</MenuItem>
                    <MenuItem value={"WA"}>Western Australia</MenuItem>
                    <MenuItem value={"TAS"}>Tasmania</MenuItem>
                    <MenuItem value={"NT"}>Northern Territory</MenuItem>
                    <MenuItem value={"ACT"}>Australian Capital Territory</MenuItem>
                </Select>   
                {formik.touched.state && Boolean(formik.errors.state) && <Typography className="w3-margin-left" variant="caption" color="error">{formik.errors.state}</Typography>}
                
                
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="postcode"
                    name="postcode"
                    label="Postcode"
                    value={formik.values.postcode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.postcode && Boolean(formik.errors.postcode)}
                    helperText={formik.touched.postcode && formik.errors.postcode}
                />
                
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Mobile Number"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                />
                
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale ="en">
                <DatePicker 
                sx={{marginTop:"10px"}}
                    fullWidth
                    id="dob"
                    name="dob"
                    label="Date of birth"
                    format ={'DD/MM/YYYY'}
                    value={formik.values.dob}
                    // onChange={formik.handleChange}
                    onChange={value=>formik.setFieldValue('dob', value)}
                    onBlur={formik.handleBlur}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.touched.dob && formik.errors.dob}
                    color="primary"
                />
                </LocalizationProvider> <br />
                {formik.touched.dob && Boolean(formik.errors.dob) && <Typography className="w3-margin-left" variant="caption" color="error">{formik.errors.dob}</Typography>}
                
                <br />
                    <Button color="primary" variant="contained" type="submit" sx={{marginTop:"10px"}}>Next</Button>

                
            </form>
           </div>
        </Container>}

        {step===3&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
        {/* <Login signupflag={true} />             */}

        <div className="w3-center" >
            <br />
            <br />
            <br />
            <br />
            <br />
            <Typography variant="h6"  >The next steps are crucial and need to be followed in the sequence.</Typography>
            <br />
            <br />
            <Typography variant="body"  > PLease complete these steps <b>before you close your browser</b></Typography>
            <br />

            <Typography variant="body2" color='error' >!! Information entered in previous screen will not be saved unless the below steps are completed. </Typography>
            
            
            <br />
            
            <br />
            <hr />
            <br />
            <Typography variant="h6"  > <b>Next Step: Create your account</b></Typography>
            <br />
            <div className="w3-container w3-margin-36" >
            <Stepper activeStep={4} orientation="vertical" >
                {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel>
                    <Typography variant="h6" color="primary" >{step.label}</Typography>
                    </StepLabel>
                    <StepContent>
                    <Typography variant="body2">{step.description}</Typography>
                    </StepContent>
                </Step>
                ))}
            </Stepper>
            <br />
            <Typography variant="body" color={'secondary'} > <b>Please ensure you stay connected to internet.</b></Typography> <br />
            <br />
            <Button variant="contained" color="primary" onClick={handleNext}>Continue</Button>
            </div>
           </div>

        </Container>}

        {step===4&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
        {/* <Login signupflag={true} />             */}

        <div>
            <br /> <br /> <br /> 
            <div className="w3-center">
            <img src="SVGLogo.svg" style={{width:'50%'}}></img>
            </div>
            <br /> <br />   <br />
            
            <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Step-1:</b>Enter credentials to create your account. </Typography>
            <form onSubmit={signUpForm.handleSubmit}>
            {warningFlag &&  <div class="w3-panel w3-pale-green">
                  <p> <b>{warningheader}</b></p>
                    <p>{warningtext}</p>
            </div>}
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={signUpForm.values.email}
                    onChange={signUpForm.handleChange}
                    onBlur={signUpForm.handleBlur}
                    error={signUpForm.touched.email && Boolean(signUpForm.errors.email)}
                    helperText={signUpForm.touched.email && signUpForm.errors.email}
                />
                
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    value={signUpForm.values.password}
                    onChange={signUpForm.handleChange}
                    onBlur={signUpForm.handleBlur}
                    error={signUpForm.touched.password && Boolean(signUpForm.errors.password)}
                    helperText={signUpForm.touched.password && signUpForm.errors.password}
                />
                
                
                <br />
                    <Button color="primary" variant="contained" type="submit" sx={{marginTop:"10px"}}>Next</Button>
  
            </form>
           </div>

        </Container>}
        
        {step===5&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
        {/* <Login signupflag={true} />             */}

        <div>
            <br /> <br /> <br /> 
            <div className="w3-center">
            <img src="SVGLogo.svg" style={{width:'50%'}}></img>
            </div>
            <br /> <br />   <br />
            
            <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Step-2:</b>Verify your email. </Typography>
            <Typography variant="body" >{steps[1].description}</Typography>
            <form onSubmit={codeUpForm.handleSubmit}>
            {warningFlag &&  <div class="w3-panel w3-pale-green">
                  <p> <b>{warningheader}</b></p>
                    <p>{warningtext}</p>
             </div>}
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="code"
                    name="code"
                    label="Verification Code"
                    value={codeUpForm.values.code}
                    onChange={codeUpForm.handleChange}
                    onBlur={codeUpForm.handleBlur}
                    error={codeUpForm.touched.code && Boolean(codeUpForm.errors.code)}
                    helperText={codeUpForm.touched.code && codeUpForm.errors.code}
                />
                
                
                <br />
                    <Button color="primary" variant="contained" type="submit" sx={{marginTop:"10px"}}>Verify</Button>
                    <br />
                    <hr />
                    <Typography variant="body" >If you have not received the code, please click on the button below to resend the code.</Typography>
                    <br />
                    <Button color="secondary" variant="contained"  sx={{marginTop:"10px"}} onClick={resendConfirmationCode}>Resend Code</Button>
  
            </form>
           </div>

        </Container>}
        {step===6&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
        {/* <Login signupflag={true} />             */}

        <div>
            <br /> <br /> <br /> 
            <div className="w3-center">
            <img src="SVGLogo.svg" style={{width:'50%'}}></img>
            </div>
            <br /> <br />   <br />
            
            <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Step-3:</b>Re-Enter your Password to continue </Typography>
            <Typography variant="body" >{steps[2].description}</Typography>
            <form onSubmit={signInForm.handleSubmit}>
            {warningFlag &&  <div class="w3-panel w3-pale-green">
                  <p> <b>{warningheader}</b></p>
                    <p>{warningtext}</p>
            </div>}
                 
                
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    value={signInForm.values.password}
                    onChange={signInForm.handleChange}
                    onBlur={signInForm.handleBlur}
                    error={signInForm.touched.password && Boolean(signInForm.errors.password)}
                    helperText={signInForm.touched.password && signInForm.errors.password}
                />
                
                
                <br />
                    <Button color="primary" variant="contained" type="submit" sx={{marginTop:"10px"}}>Next</Button>
  
            </form>
           </div>

        </Container>}

        {step===7&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
        {/* <Login signupflag={true} />             */}

        <div>
            <br /> <br /> <br /> 
            <div className="w3-center">
            <img src="SVGLogo.svg" style={{width:'50%'}}></img>
            </div>
            <br /> <br />   <br />
            
            <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Returning User:</b> Login again to authenticate and to continue</Typography>
            <form onSubmit={returningUserForm.handleSubmit}>
            {warningFlag &&  <div class="w3-panel w3-pale-green">
                  <p> <b>{warningheader}</b></p>
                    <p>{warningtext}</p>
            </div>}
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={returningUserForm.values.email}
                    onChange={returningUserForm.handleChange}
                    onBlur={returningUserForm.handleBlur}
                    error={returningUserForm.touched.email && Boolean(returningUserForm.errors.email)}
                    helperText={returningUserForm.touched.email && returningUserForm.errors.email}
                />
                
                    <TextField
                    sx={{marginTop:"10px"}}
                    fullWidth
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    value={returningUserForm.values.password}
                    onChange={returningUserForm.handleChange}
                    onBlur={returningUserForm.handleBlur}
                    error={returningUserForm.touched.password && Boolean(returningUserForm.errors.password)}
                    helperText={returningUserForm.touched.password && returningUserForm.errors.password}
                />
                
                
                <br />
                    <Button color="primary" variant="contained" type="submit" sx={{marginTop:"10px"}}>Next</Button>
  
            </form>
           </div>

        </Container>}

        {step===8&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div>
            <br /><br />
            <form onSubmit={onboardingAgentForm.handleSubmit}>
            <br /><br />
              <Typography sx={{marginTop:"10px"}} variant="body" >Please select the Tax Agent who is serving you at KarvisTax:</Typography>
                    <Select
                    fullWidth
                    id="onboardingAgent"
                    name="onboardingAgent"
                    label="onboardingAgent"
                    value={onboardingAgentForm.values.onboardingAgent}
                    onChange={onboardingAgentForm.handleChange}
                    onBlur={onboardingAgentForm.handleBlur}
                    error={onboardingAgentForm.touched.onboardingAgent && Boolean(onboardingAgentForm.errors.onboardingAgent)}
                    helperText={onboardingAgentForm.touched.onboardingAgent && onboardingAgentForm.errors.onboardingAgent}
                >
                
                  {customerfacingemployees && customerfacingemployees.map((emp) => {
                    return <MenuItem key={emp.id} value={emp.email}>{emp.name}</MenuItem>
                    }
                  )}
                    <MenuItem value={"dummy"}>None of the above</MenuItem>
                    
                </Select>   
                {onboardingAgentForm.touched.onboardingAgent && Boolean(onboardingAgentForm.errors.onboardingAgent) && <Typography className="w3-margin-left" variant="caption" color="error">{onboardingAgentForm.errors.onboardingAgent}</Typography>}
                <br />
                <Button color="primary" variant="contained" type="submit" sx={{marginTop:"10px"}}>Next</Button>
            </form>
           
           <br />
        
            <br />
            <br />
           <OnboardingDoneButton />
           </div>
        </Container>}

        {step===9&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div>
            <br /><br />
            <div class="w3-panel w3-pale-green">
            <Typography variant="body2"> <b>You can update Any fields here</b></Typography>
            <Typography variant="body2"> If there is nothing to update, simply click next at the bottom on the screen</Typography>
            </div>
           <PersonalDetails />
           <br />
           <div className="w3-row">
            <div className="w3-half">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handlePrevious}> &larr; Previous Section</Button>
            </div>
            <div className="w3-half w3-right-align">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handleNext}>Next Section &rarr;</Button>    
            </div>
           </div>
            <br />
            <br />
           <OnboardingDoneButton />
           </div>
        </Container>}

        {step===10&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div>
            <br /><br />
            <div class="w3-panel w3-pale-green">
            <Typography variant="body2"> <b>You can Update Any fields here</b></Typography>
            <Typography variant="body2"> If there is nothing to update, simply click next at the bottom on the screen</Typography>
            </div>
            <ElectronicBankingDetails />
           <br />
           <div className="w3-row">
            <div className="w3-half">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handlePrevious}> &larr; Previous Section</Button>
            </div>
            <div className="w3-half w3-right-align">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handleNext}>Next Section &rarr;</Button>    
            </div>
           </div>
            <br />
            <br />
           <OnboardingDoneButton />
           </div>
        </Container>}

        {step===11&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div>
            <br /><br />
            <div class="w3-panel w3-pale-green">
            <Typography variant="body2"> <b>You can Update Any fields here</b></Typography>
            <Typography variant="body2"> If there is nothing to update, simply click next at the bottom on the screen</Typography>
            </div>
            <IncomeTypes />
           <br />
           <div className="w3-row">
            <div className="w3-half">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handlePrevious}> &larr; Previous Section</Button>
            </div>
            <div className="w3-half w3-right-align">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handleNext}>Next Section &rarr;</Button>    
            </div>
           </div>
            <br /><br />
           <OnboardingDoneButton />
           </div>
        </Container>}

        {step===12&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div>
            <br /><br />
            <div class="w3-panel w3-pale-green">
            <Typography variant="body2"> <b>You can Update Any fields here</b></Typography>
            <Typography variant="body2"> If there is nothing to update, simply click next at the bottom on the screen</Typography>
            </div>
            <SpouseDetails />
           <br />
           <div className="w3-row">
            <div className="w3-half">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handlePrevious}> &larr; Previous Section</Button>
            </div>
            <div className="w3-half w3-right-align">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handleNext}>Next Section &rarr;</Button>    
            </div>
           </div>
            <br /><br />
           <OnboardingDoneButton />
           </div>
           
        </Container>}

        {step===13&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div>
            <br /><br />
            <div class="w3-panel w3-pale-green">
            <Typography variant="body2"> <b>You can Update Any fields here</b></Typography>
            <Typography variant="body2"> If there is nothing to update, simply click next at the bottom on the screen</Typography>
            </div>
            <DependentsDetails />
           <br />
           <div className="w3-row">
            <div className="w3-half">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handlePrevious}> &larr; Previous Section</Button>
            </div>
            <div className="w3-half w3-right-align">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handleNext}>Next Section &rarr;</Button>    
            </div>
           </div>
            <br /><br />
           <OnboardingDoneButton />
           </div>
        </Container>}
        {step===14&&<Container className="w3-animate-opacity" maxWidth="md" style={{opacity:"0.9", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", minHeight:"90vh"}}> 
            <div>
            <br /><br />
            <div class="w3-panel w3-pale-green">
            <Typography variant="body2"> <b>You can Update Any fields here</b></Typography>
            <Typography variant="body2"> If there is nothing to update, simply click next at the bottom on the screen</Typography>
            </div>
            <OccupationDetails />
            <InvestmentPropertyDetails />
            <CompanyDetails />
            <TrustDetails />
            <BankInterestDetails />
            <DividendDetails />

           <br /><br />
           <div className="w3-row">
            <div className="w3-half">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handlePrevious}> &larr; Previous Section</Button>
            </div>
            <div className="w3-half w3-right-align">
            <Button color="primary" variant="contained"  sx={{marginTop:"10px"}} onClick={handleFinish}>Finish</Button>    
            </div>
           </div>
            
            
            
           <Typography variant="body">All done !</Typography>
           
           
           <OnboardingDoneButton />
           </div>
        </Container>}
     

    </div> );
}
 
export default NewOnboarding;