/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getKTACustomer = /* GraphQL */ `
  query GetKTACustomer($id: ID!) {
    getKTACustomer(id: $id) {
      id
      email
      phone
      fname
      lname
      tfn
      address
      dob
      description
      slug
      maritalstatuschanged
      bank_bsb
      bank_account
      salaried_flag
      soletrader_flag
      acn_flag
      trust_flag
      investmentproperty_flag
      bankinterest_flag
      dividend_flag
      taxaccounts {
        nextToken
        __typename
      }
      privileges {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      dependants {
        nextToken
        __typename
      }
      occupations {
        nextToken
        __typename
      }
      investmentproperties {
        nextToken
        __typename
      }
      bankinterests {
        nextToken
        __typename
      }
      dividends {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      trusts {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      engagingtaxagent
      spousename
      spousetfn
      spouseincome
      spousedob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listKTACustomers = /* GraphQL */ `
  query ListKTACustomers(
    $filter: ModelKTACustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTACustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTACompany = /* GraphQL */ `
  query GetKTACompany($id: ID!) {
    getKTACompany(id: $id) {
      id
      name
      abn
      acn
      companydescription
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerCompaniesId
      __typename
    }
  }
`;
export const listKTACompanies = /* GraphQL */ `
  query ListKTACompanies(
    $filter: ModelKTACompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTACompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        abn
        acn
        companydescription
        createdAt
        updatedAt
        kTACustomerCompaniesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTANotification = /* GraphQL */ `
  query GetKTANotification($id: ID!) {
    getKTANotification(id: $id) {
      id
      title
      description
      createdby
      createdbyname
      createddate
      status
      duedate
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerNotificationsId
      __typename
    }
  }
`;
export const listKTANotifications = /* GraphQL */ `
  query ListKTANotifications(
    $filter: ModelKTANotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTANotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        createdby
        createdbyname
        createddate
        status
        duedate
        createdAt
        updatedAt
        kTACustomerNotificationsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTATrust = /* GraphQL */ `
  query GetKTATrust($id: ID!) {
    getKTATrust(id: $id) {
      id
      name
      abn
      tfn
      trustee
      trusteeabn
      trusteeaddress
      trusteetfn
      trusteeacn
      trusteeemail
      trusteebankbsb
      trusteebankaccount
      netcapitalgain
      trustdocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerTrustsId
      __typename
    }
  }
`;
export const listKTATrusts = /* GraphQL */ `
  query ListKTATrusts(
    $filter: ModelKTATrustFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTATrusts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        abn
        tfn
        trustee
        trusteeabn
        trusteeaddress
        trusteetfn
        trusteeacn
        trusteeemail
        trusteebankbsb
        trusteebankaccount
        netcapitalgain
        createdAt
        updatedAt
        kTACustomerTrustsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTATrustDocument = /* GraphQL */ `
  query GetKTATrustDocument($id: ID!) {
    getKTATrustDocument(id: $id) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      trust {
        id
        name
        abn
        tfn
        trustee
        trusteeabn
        trusteeaddress
        trusteetfn
        trusteeacn
        trusteeemail
        trusteebankbsb
        trusteebankaccount
        netcapitalgain
        createdAt
        updatedAt
        kTACustomerTrustsId
        __typename
      }
      createdAt
      updatedAt
      kTATrustTrustdocumentsId
      __typename
    }
  }
`;
export const listKTATrustDocuments = /* GraphQL */ `
  query ListKTATrustDocuments(
    $filter: ModelKTATrustDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTATrustDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        category
        s3key
        description
        createdbyname
        createdAt
        updatedAt
        kTATrustTrustdocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTADividend = /* GraphQL */ `
  query GetKTADividend($id: ID!) {
    getKTADividend(id: $id) {
      id
      name
      datepaid
      franked
      unfranked
      frankingcredit
      taxwitheld
      grosspayments
      dividenddocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerDividendsId
      __typename
    }
  }
`;
export const listKTADividends = /* GraphQL */ `
  query ListKTADividends(
    $filter: ModelKTADividendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTADividends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        datepaid
        franked
        unfranked
        frankingcredit
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerDividendsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTADividendDocument = /* GraphQL */ `
  query GetKTADividendDocument($id: ID!) {
    getKTADividendDocument(id: $id) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      dividend {
        id
        name
        datepaid
        franked
        unfranked
        frankingcredit
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerDividendsId
        __typename
      }
      createdAt
      updatedAt
      kTADividendDividenddocumentsId
      __typename
    }
  }
`;
export const listKTADividendDocuments = /* GraphQL */ `
  query ListKTADividendDocuments(
    $filter: ModelKTADividendDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTADividendDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        category
        s3key
        description
        createdbyname
        createdAt
        updatedAt
        kTADividendDividenddocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTABankInterest = /* GraphQL */ `
  query GetKTABankInterest($id: ID!) {
    getKTABankInterest(id: $id) {
      id
      name
      interest
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      yourshare
      bankinterestdocuments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerBankinterestsId
      __typename
    }
  }
`;
export const listKTABankInterests = /* GraphQL */ `
  query ListKTABankInterests(
    $filter: ModelKTABankInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTABankInterests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        interest
        yourshare
        createdAt
        updatedAt
        kTACustomerBankinterestsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTABankInterestDocument = /* GraphQL */ `
  query GetKTABankInterestDocument($id: ID!) {
    getKTABankInterestDocument(id: $id) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      bankinterest {
        id
        name
        interest
        yourshare
        createdAt
        updatedAt
        kTACustomerBankinterestsId
        __typename
      }
      createdAt
      updatedAt
      kTABankInterestBankinterestdocumentsId
      __typename
    }
  }
`;
export const listKTABankInterestDocuments = /* GraphQL */ `
  query ListKTABankInterestDocuments(
    $filter: ModelKTABankInterestDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTABankInterestDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        category
        s3key
        description
        createdbyname
        createdAt
        updatedAt
        kTABankInterestBankinterestdocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAInvestmentProperty = /* GraphQL */ `
  query GetKTAInvestmentProperty($id: ID!) {
    getKTAInvestmentProperty(id: $id) {
      id
      address
      yourshare
      purchaseprice
      purchaseyear
      datefirstrentincome
      weeksrented
      weeksavailableforrent
      loannegotiated
      rentalincome
      advertisingcost
      bodycorporatecost
      borrowingcost
      cleaningcost
      councilrates
      capitalallowances
      gardeningcost
      insurancecost
      interestcost
      landtax
      legalcost
      pestcontrolcost
      propertyagentcost
      repairsandmaintenancecost
      capitalworks
      stationerycost
      travelcost
      watercost
      sundrycost
      bankfees
      depreciationschedulefee
      othercosts
      investmentpropertydocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerInvestmentpropertiesId
      __typename
    }
  }
`;
export const listKTAInvestmentProperties = /* GraphQL */ `
  query ListKTAInvestmentProperties(
    $filter: ModelKTAInvestmentPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAInvestmentProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        address
        yourshare
        purchaseprice
        purchaseyear
        datefirstrentincome
        weeksrented
        weeksavailableforrent
        loannegotiated
        rentalincome
        advertisingcost
        bodycorporatecost
        borrowingcost
        cleaningcost
        councilrates
        capitalallowances
        gardeningcost
        insurancecost
        interestcost
        landtax
        legalcost
        pestcontrolcost
        propertyagentcost
        repairsandmaintenancecost
        capitalworks
        stationerycost
        travelcost
        watercost
        sundrycost
        bankfees
        depreciationschedulefee
        othercosts
        createdAt
        updatedAt
        kTACustomerInvestmentpropertiesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAInvestmentPropertyDocument = /* GraphQL */ `
  query GetKTAInvestmentPropertyDocument($id: ID!) {
    getKTAInvestmentPropertyDocument(id: $id) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      investmentproperty {
        id
        address
        yourshare
        purchaseprice
        purchaseyear
        datefirstrentincome
        weeksrented
        weeksavailableforrent
        loannegotiated
        rentalincome
        advertisingcost
        bodycorporatecost
        borrowingcost
        cleaningcost
        councilrates
        capitalallowances
        gardeningcost
        insurancecost
        interestcost
        landtax
        legalcost
        pestcontrolcost
        propertyagentcost
        repairsandmaintenancecost
        capitalworks
        stationerycost
        travelcost
        watercost
        sundrycost
        bankfees
        depreciationschedulefee
        othercosts
        createdAt
        updatedAt
        kTACustomerInvestmentpropertiesId
        __typename
      }
      createdAt
      updatedAt
      kTAInvestmentPropertyInvestmentpropertydocumentsId
      __typename
    }
  }
`;
export const listKTAInvestmentPropertyDocuments = /* GraphQL */ `
  query ListKTAInvestmentPropertyDocuments(
    $filter: ModelKTAInvestmentPropertyDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAInvestmentPropertyDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        category
        s3key
        description
        createdbyname
        createdAt
        updatedAt
        kTAInvestmentPropertyInvestmentpropertydocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAOccupation = /* GraphQL */ `
  query GetKTAOccupation($id: ID!) {
    getKTAOccupation(id: $id) {
      id
      name
      employer
      taxwitheld
      grosspayments
      occupationdocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerOccupationsId
      __typename
    }
  }
`;
export const listKTAOccupations = /* GraphQL */ `
  query ListKTAOccupations(
    $filter: ModelKTAOccupationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAOccupations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        employer
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerOccupationsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAOccupationDocument = /* GraphQL */ `
  query GetKTAOccupationDocument($id: ID!) {
    getKTAOccupationDocument(id: $id) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      occupation {
        id
        name
        employer
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerOccupationsId
        __typename
      }
      createdAt
      updatedAt
      kTAOccupationOccupationdocumentsId
      __typename
    }
  }
`;
export const listKTAOccupationDocuments = /* GraphQL */ `
  query ListKTAOccupationDocuments(
    $filter: ModelKTAOccupationDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAOccupationDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        category
        s3key
        description
        createdbyname
        createdAt
        updatedAt
        kTAOccupationOccupationdocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTADependant = /* GraphQL */ `
  query GetKTADependant($id: ID!) {
    getKTADependant(id: $id) {
      id
      name
      age
      relation
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerDependantsId
      __typename
    }
  }
`;
export const listKTADependants = /* GraphQL */ `
  query ListKTADependants(
    $filter: ModelKTADependantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTADependants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        age
        relation
        createdAt
        updatedAt
        kTACustomerDependantsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTATaxAccount = /* GraphQL */ `
  query GetKTATaxAccount($id: ID!) {
    getKTATaxAccount(id: $id) {
      id
      systemid
      code
      type
      tfn
      abn
      acn
      name
      fname
      mname
      gender
      dob
      lname
      email
      postaladdress
      residentialaddress
      businessaddress
      phone
      slug
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerTaxaccountsId
      __typename
    }
  }
`;
export const listKTATaxAccounts = /* GraphQL */ `
  query ListKTATaxAccounts(
    $filter: ModelKTATaxAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTATaxAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAEmployee = /* GraphQL */ `
  query GetKTAEmployee($id: ID!) {
    getKTAEmployee(id: $id) {
      id
      name
      email
      description
      slug
      privileges {
        nextToken
        __typename
      }
      workitems {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      trackmaster_create
      trackmaster_lodge
      trackmaster_update
      trackmaster_delete
      view_everyone_privilege
      custdata_view
      custdata_update
      manage_tools
      custaccount_edit
      masteremployee_flag
      customerfacing_flag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listKTAEmployees = /* GraphQL */ `
  query ListKTAEmployees(
    $filter: ModelKTAEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAPrivilege = /* GraphQL */ `
  query GetKTAPrivilege($id: ID!) {
    getKTAPrivilege(id: $id) {
      id
      name
      description
      value
      createdAt
      updatedAt
      kTACustomerPrivilegesId
      kTAEmployeePrivilegesId
      __typename
    }
  }
`;
export const listKTAPrivileges = /* GraphQL */ `
  query ListKTAPrivileges(
    $filter: ModelKTAPrivilegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAPrivileges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        value
        createdAt
        updatedAt
        kTACustomerPrivilegesId
        kTAEmployeePrivilegesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAWorkItem = /* GraphQL */ `
  query GetKTAWorkItem($id: ID!) {
    getKTAWorkItem(id: $id) {
      id
      woid
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      name
      datecreated
      billedamount
      collectedamount
      dueamount
      status {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      taxaccount {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      slug
      interviewdate
      paymentdate
      signdate
      lodgementdate
      remarks {
        nextToken
        __typename
      }
      lodgementtype
      createdAt
      updatedAt
      kTAEmployeeWorkitemsId
      kTAWorkItemStatusId
      kTAWorkItemTaxaccountId
      __typename
    }
  }
`;
export const listKTAWorkItems = /* GraphQL */ `
  query ListKTAWorkItems(
    $filter: ModelKTAWorkItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAWorkItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        woid
        name
        datecreated
        billedamount
        collectedamount
        dueamount
        slug
        interviewdate
        paymentdate
        signdate
        lodgementdate
        lodgementtype
        createdAt
        updatedAt
        workitemtitle
        kTAEmployeeWorkitemsId
        kTAWorkItemStatusId
        kTAWorkItemTaxaccountId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAWorkItemStatus = /* GraphQL */ `
  query GetKTAWorkItemStatus($id: ID!) {
    getKTAWorkItemStatus(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listKTAWorkItemStatuses = /* GraphQL */ `
  query ListKTAWorkItemStatuses(
    $filter: ModelKTAWorkItemStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAWorkItemStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAPaymentRecord = /* GraphQL */ `
  query GetKTAPaymentRecord($id: ID!) {
    getKTAPaymentRecord(id: $id) {
      id
      title
      amount
      priceid
      productid
      paymentlink
      taxaccount {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      status
      paymentdate
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      remarks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerPaymentrecordsId
      kTATaxAccountPaymentrecordsId
      kTAEmployeePaymentrecordsId
      kTAWorkItemPaymentrecordsId
      __typename
    }
  }
`;
export const listKTAPaymentRecords = /* GraphQL */ `
  query ListKTAPaymentRecords(
    $filter: ModelKTAPaymentRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAPaymentRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        amount
        priceid
        productid
        paymentlink
        status
        paymentdate
        createdAt
        updatedAt
        kTACustomerPaymentrecordsId
        kTATaxAccountPaymentrecordsId
        kTAEmployeePaymentrecordsId
        kTAWorkItemPaymentrecordsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAPaymentRemarks = /* GraphQL */ `
  query GetKTAPaymentRemarks($id: ID!) {
    getKTAPaymentRemarks(id: $id) {
      id
      remark
      createdby
      paymentrecord {
        id
        title
        amount
        priceid
        productid
        paymentlink
        status
        paymentdate
        createdAt
        updatedAt
        kTACustomerPaymentrecordsId
        kTATaxAccountPaymentrecordsId
        kTAEmployeePaymentrecordsId
        kTAWorkItemPaymentrecordsId
        __typename
      }
      createdAt
      updatedAt
      kTAPaymentRecordRemarksId
      __typename
    }
  }
`;
export const listKTAPaymentRemarks = /* GraphQL */ `
  query ListKTAPaymentRemarks(
    $filter: ModelKTAPaymentRemarksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAPaymentRemarks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        remark
        createdby
        createdAt
        updatedAt
        kTAPaymentRecordRemarksId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTADocument = /* GraphQL */ `
  query GetKTADocument($id: ID!) {
    getKTADocument(id: $id) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      createdAt
      updatedAt
      kTACustomerDocumentsId
      kTAEmployeeDocumentsId
      __typename
    }
  }
`;
export const listKTADocuments = /* GraphQL */ `
  query ListKTADocuments(
    $filter: ModelKTADocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTADocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        category
        s3key
        description
        createdbyname
        createdAt
        updatedAt
        kTACustomerDocumentsId
        kTAEmployeeDocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTARefData = /* GraphQL */ `
  query GetKTARefData($id: ID!) {
    getKTARefData(id: $id) {
      Type
      values
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listKTARefData = /* GraphQL */ `
  query ListKTARefData(
    $filter: ModelKTARefDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTARefData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Type
        values
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTASequenceNo = /* GraphQL */ `
  query GetKTASequenceNo($id: ID!) {
    getKTASequenceNo(id: $id) {
      id
      seqnumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listKTASequenceNos = /* GraphQL */ `
  query ListKTASequenceNos(
    $filter: ModelKTASequenceNoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTASequenceNos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        seqnumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTAWorkItemRemarks = /* GraphQL */ `
  query GetKTAWorkItemRemarks($id: ID!) {
    getKTAWorkItemRemarks(id: $id) {
      id
      remark
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      datecreated
      workitem {
        id
        woid
        name
        datecreated
        billedamount
        collectedamount
        dueamount
        slug
        interviewdate
        paymentdate
        signdate
        lodgementdate
        lodgementtype
        createdAt
        updatedAt
        kTAEmployeeWorkitemsId
        kTAWorkItemStatusId
        kTAWorkItemTaxaccountId
        __typename
      }
      createdAt
      updatedAt
      kTAWorkItemRemarksId
      kTAWorkItemRemarksCreatedbyId
      __typename
    }
  }
`;
export const listKTAWorkItemRemarks = /* GraphQL */ `
  query ListKTAWorkItemRemarks(
    $filter: ModelKTAWorkItemRemarksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTAWorkItemRemarks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        remark
        datecreated
        createdAt
        updatedAt
        kTAWorkItemRemarksId
        kTAWorkItemRemarksCreatedbyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKTACommonDocuments = /* GraphQL */ `
  query GetKTACommonDocuments($id: ID!) {
    getKTACommonDocuments(id: $id) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listKTACommonDocuments = /* GraphQL */ `
  query ListKTACommonDocuments(
    $filter: ModelKTACommonDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKTACommonDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        category
        s3key
        description
        createdbyname
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
