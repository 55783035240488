import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
    persnoaldatachanged: false, 
    spousedetailschanged: false,
    dependentdetailschanged: false,
    personaldatapending: true,
    bankdatapending: true,
    incometypesdatapending: true,
    occupationdatapending: true,
    propertiesdatapending: true,
    companiesdatapending: true,
    trustsdatapending: true,
    bankinterestdatapending: true,
    dividendsdatapending: true


};

export const detailsChangedFlagsSlice = createSlice({
    name: "dcflags",
    initialState: { value: initialStateValue },
    reducers: {
        updatePersonalDetailFlag: (state, action) => {
            state.value.persnoaldatachanged = action.payload;
        },
        updateSouseDetailFlag: (state, action) => {
            state.value.spousedetailschanged = action.payload;
        },
        updateDependentDetailFlag: (state, action) => {
            state.value.dependentdetailschanged = action.payload;
        },
        completePersonalData: (state, action) => {
            state.value.personaldatapending = action.payload;
        },
        completeBankData: (state, action) => {
            state.value.bankdatapending = action.payload;
        },
        completeIncomeTypesData: (state, action) => {
            state.value.incometypesdatapending = action.payload;
        },
        completeOccupationData: (state, action) => {
            state.value.occupationdatapending = action.payload;
        },
        completePropertiesData: (state, action) => {
            state.value.propertiesdatapending = action.payload;
        },
        completeCompaniesData: (state, action) => {
            state.value.companiesdatapending = action.payload;
        },
        completeTrustsData: (state, action) => {
            state.value.trustsdatapending = action.payload;
        },
        completeBankInterestData: (state, action) => {
            state.value.bankinterestdatapending = action.payload;
        },
        completeDividendsData: (state, action) => {
            state.value.dividendsdatapending = action.payload;
        },




    }
});

export const { 
    updatePersonalDetailFlag,
    updateSouseDetailFlag,
    updateDependentDetailFlag,
    completePersonalData,
    completeBankData,
    completeIncomeTypesData,
    completeOccupationData,
    completePropertiesData,
    completeCompaniesData,
    completeTrustsData,
    completeBankInterestData,
    completeDividendsData
 } = detailsChangedFlagsSlice.actions;
export default detailsChangedFlagsSlice.reducer;