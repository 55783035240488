import { Button } from "@mui/material";
import {  useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { SendEmailFunction } from "../GlobalVariables/SendEmailFunction";
import { useEffect, useState } from "react";

const OnboardingDoneButton = () => {

    const profile = useSelector(state => state.profile.value);
    const history = useHistory();

    const [agentEmail, setAgentEmail] = useState('info@karvistax.com.au');

    useEffect(() => {
        if (profile.engagingtaxagent) {
            setAgentEmail(profile.engagingtaxagent);
        }
        
    }, [profile]);


function sendEmail() {

    var inputs = {
        fromEmail: 'info@karvistax.com.au',
        toEmails: JSON.stringify(['itsupport@karvistax.com.au',agentEmail, 'info@karvistax.com.au']),
        subject: 'Onboarding Form Submitted by: ' + profile.fname,
        textbody: '', // Leave it blank
        title: 'Onboarding Form Submitted by: ' + profile.fname,
        greeting: 'Dear Karvistax Team,',
        body: 'Onboarding Form Submitted by: ' + profile.fname + '. Please check the details in the link below.',
        link: 'https://company.karvistax.com.au/clientsonboardingdata/' + profile.id


    }

    if ( agentEmail !== '' && agentEmail !== null && agentEmail !== undefined && agentEmail !== 'dummy') {
        inputs.toEmails = JSON.stringify(['itsupport@karvistax.com.au',agentEmail, 'info@karvistax.com.au']);
        inputs.subject = 'Onboarding Form Submitted by: ' + profile.fname + " | Agent: " + agentEmail;
        SendEmailFunction(inputs);
    } else {
        inputs.toEmails = JSON.stringify(['itsupport@karvistax.com.au', 'info@karvistax.com.au']);
        inputs.subject = 'Onboarding Form Submitted by: ' + profile.fname + " | Agent: Not Selected";
        SendEmailFunction(inputs);
    }


    history.push("/thankyou");

}

    return ( <div>
        <Button variant="contained" color="secondary" style={{width: "100%"}} onClick={sendEmail}><b>When you are finished updating for Today </b> - Please click to notify us</Button>
        <br />
        <br />
        <div className="w3-center">---</div>
    </div> );
}
 
export default OnboardingDoneButton;