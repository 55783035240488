
import * as React from 'react';
import Card from '@mui/material/Card';
import { CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


const CompanyCard = (props) => {

    const [company, setcompany] = React.useState({name: '', abn: '', acn: ''});
    
React.useEffect(() => {
    setcompany(props.company);
}, [props.company])

    const handleDelete =() =>{
        props.callBackFunction(company)
    }

    return ( <div>

<Card className= 'w3-border' sx={{ minWidth: 275 }}>
<CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        }
        title={company.name}
        subheader={'ABN : '+company.abn+' ACN : '+company.acn}
      />
       
    </Card>

    </div> );
}
 
export default CompanyCard;