import * as React from 'react';
import Card from '@mui/material/Card';
import { Button, CardActions, CardContent, CardHeader, Grid, IconButton, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { API, graphqlOperation } from 'aws-amplify';
import { updateKTAInvestmentProperty } from '../../graphql/mutations';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableViewIcon from '@mui/icons-material/TableView';
import { updateProfileInvestmentProperty } from '../Features/profile';
import { useDispatch, useSelector } from 'react-redux';

const InvestmentPropertyCard = (props) => {

    const [expand, setExpand] = React.useState(false);
    const [detailsChanged, setDetailsChanged] = React.useState(false);
    const [IPpurchaseyear, setPurchaseyear] = React.useState(props.property.purchaseyear);
    const [IPpurchaseprice, setPurchaseprice] = React.useState(props.property.purchaseprice);
    const [IPdatefirstrentincome, setDatefirstrentincome] = React.useState(props.property.datefirstrentincome);
    const [IPweeksavailableforrent, setWeeksavailableforrent] = React.useState(props.property.weeksavailableforrent);
    const [IPweeksrented, setWeeksrented] = React.useState(props.property.weeksrented);
    const [IPloannegotiated, setLoannegotiated] = React.useState(props.property.loannegotiated);
    const [IPrentalincome, setRentalincome] = React.useState(props.property.rentalincome);
    const [IPadvertisingcost, setAdvertisingcost] = React.useState(props.property.advertisingcost);
    const [IPpropertyagentcost, setPropertyagentcost] = React.useState(props.property.propertyagentcost);
    const [IPinterestcost, setInterestcost] = React.useState(props.property.interestcost);
    const [IPbankfees, setBankfees] = React.useState(props.property.bankfees);
    const [IPborrowingcost, setBorrowingcost] = React.useState(props.property.borrowingcost);
    const [IPinsurancecost, setInsurancecost] = React.useState(props.property.insurancecost);
    const [IPlandtax, setLandtax] = React.useState(props.property.landtax);
    const [IPcouncilrates, setCouncilrates] = React.useState(props.property.councilrates);
    const [IPbodycorporatecost, setBodycorporatecost] = React.useState(props.property.bodycorporatecost);
    const [IPgardeningcost, setGardeningcost] = React.useState(props.property.gardeningcost);
    const [IPcleaningcost, setCleaningcost] = React.useState(props.property.cleaningcost);
    const [IPpestcontrolcost, setPestcontrolcost] = React.useState(props.property.pestcontrolcost);
    const [IPrepairsandmaintenancecost, setRepairsandmaintenancecost] = React.useState(props.property.repairsandmaintenancecost);
    const [IPstationerycost, setStationerycost] = React.useState(props.property.stationerycost);
    const [IPsundrycost, setSundrycost] = React.useState(props.property.sundrycost);
    const [IPtravelcost, setTravelcost] = React.useState(props.property.travelcost) ;
    const [IPwatercost, setWatercost] = React.useState(props.property.watercost);
    const [IPcapitalallowances, setCapitalallowances] = React.useState(props.property.capitalallowances);
    const [IPcapitalworks, setCapitalworks] = React.useState(props.property.capitalworks);
    const [IPdepreciationschedulefee, setDepreciationschedulefee] = React.useState(props.property.depreciationschedulefee);
    const [IPlegalcost, setLegalcost] = React.useState(props.property.legalcost);
    const [IPothercosts, setOthercosts] = React.useState(props.property.othercosts);
    const [IPyourshare, setYourshare] = React.useState(props.property.yourshare);

    const dispatch = useDispatch();
   
    const handleDelete =() =>{
        props.callBackFunction(props.property.id)
    }
    const handleUpdate = () => {
        var inutVariables = {
            id: props.property.id,
            yourshare: IPyourshare,
            advertisingcost: IPadvertisingcost,
            bankfees: IPbankfees,
            bodycorporatecost: IPbodycorporatecost,
            borrowingcost: IPborrowingcost,
            capitalallowances: IPcapitalallowances,
            capitalworks: IPcapitalworks,
            cleaningcost: IPcleaningcost,
            councilrates: IPcouncilrates,
            datefirstrentincome: IPdatefirstrentincome,
            depreciationschedulefee: IPdepreciationschedulefee,
            gardeningcost: IPgardeningcost,
            insurancecost: IPinsurancecost,
            interestcost: IPinterestcost,
            landtax: IPlandtax,
            legalcost: IPlegalcost,
            loannegotiated: IPloannegotiated,
            othercosts: IPothercosts,
            pestcontrolcost: IPpestcontrolcost,
            propertyagentcost: IPpropertyagentcost,
            purchaseprice: IPpurchaseprice,
            purchaseyear: IPpurchaseyear,
            rentalincome: IPrentalincome,
            repairsandmaintenancecost: IPrepairsandmaintenancecost,
            stationerycost: IPstationerycost,
            sundrycost: IPsundrycost,
            travelcost: IPtravelcost,
            watercost: IPwatercost,
            weeksavailableforrent: IPweeksavailableforrent,
            weeksrented: IPweeksrented,
        };
       
        API.graphql(graphqlOperation(updateKTAInvestmentProperty, {input: inutVariables})).then(res => {
            console.log(res);
            setDetailsChanged(false);
            dispatch(updateProfileInvestmentProperty(inutVariables));

        })
        .catch(err => {
            console.log(err);
        }
        )

      }

      const [NetIncome, setNetIncome] = React.useState(0);
      React.useEffect(() => {
        setNetIncome(IPrentalincome - (changeToNumber(IPadvertisingcost) + changeToNumber(IPpropertyagentcost) + changeToNumber(IPinterestcost) + changeToNumber(IPbankfees) + changeToNumber(IPborrowingcost) + changeToNumber(IPinsurancecost) + changeToNumber(IPlandtax) + changeToNumber(IPcouncilrates) + changeToNumber(IPbodycorporatecost) + changeToNumber(IPgardeningcost) + changeToNumber(IPcleaningcost) + changeToNumber(IPpestcontrolcost) + changeToNumber(IPrepairsandmaintenancecost) + changeToNumber(IPstationerycost) + changeToNumber(IPsundrycost) + changeToNumber(IPtravelcost) + changeToNumber(IPwatercost) + changeToNumber(IPcapitalallowances) + changeToNumber(IPcapitalworks) + changeToNumber(IPdepreciationschedulefee) + changeToNumber(IPlegalcost) + changeToNumber(IPothercosts)));
      }, [IPpurchaseyear, IPpurchaseprice, IPdatefirstrentincome, IPweeksavailableforrent, IPweeksrented, IPloannegotiated, IPrentalincome, IPadvertisingcost, IPpropertyagentcost, IPinterestcost, IPbankfees, IPborrowingcost, IPinsurancecost, IPlandtax, IPcouncilrates, IPbodycorporatecost, IPgardeningcost, IPcleaningcost, IPpestcontrolcost, IPrepairsandmaintenancecost, IPstationerycost, IPsundrycost, IPtravelcost, IPwatercost, IPcapitalallowances, IPcapitalworks, IPdepreciationschedulefee, IPlegalcost, IPothercosts, IPyourshare]);

      function handleExpand(){    
        setExpand(!expand);
        }

       const changeToNumber = (value) => {   
            let newValue = value;
            if (value === undefined || value === null || value === '') {    
                return 0;
            } else {
                newValue = value.toString().replace(/[^0-9]/g, '');
                return Number(newValue);
            }
        }

   

        // Function to change to number below 100
        const changeToNumberbelowHundered = (value) => {
            let newValue = value;
            if (value === undefined || value === null || value === '') {
                return 0;
            } else {
                newValue = value.toString().replace(/[^0-9]/g, '');
                if (Number(newValue) > 100) {
                    newValue = 100;
                }
                return Number(newValue);
            }
        }


        function downloadFile() {
            const fileUrl = process.env.PUBLIC_URL + '/RentalChecklist.zip'; 
          
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          
    function handleDetailsChanged(){
        setDetailsChanged(true);

    }

return ( <div>

<Card className= 'w3-border' sx={{ minWidth: 275 }}>
<CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleDelete}>
            <DeleteIcon color='error'/>
          </IconButton>
        }
        title={props.property.address}
        subheader={ <> <Typography variant="button" gutterBottom component="div" color={'info'}> <b>Your Share: {IPyourshare}%</b> </Typography>
        <Typography variant="body1" gutterBottom component="div" color={'info'}> <b>Income: $ {IPrentalincome}</b> </Typography>
    <Typography variant="body1" gutterBottom component="div" color={'info'}> <b>Total Expenses: $ {changeToNumber(IPadvertisingcost) + changeToNumber(IPpropertyagentcost) + changeToNumber(IPinterestcost) + changeToNumber(IPbankfees) + changeToNumber(IPborrowingcost) + changeToNumber(IPinsurancecost) + changeToNumber(IPlandtax) + changeToNumber(IPcouncilrates) + changeToNumber(IPbodycorporatecost) + changeToNumber(IPgardeningcost) + changeToNumber(IPcleaningcost) + changeToNumber(IPpestcontrolcost) + changeToNumber(IPrepairsandmaintenancecost) + changeToNumber(IPstationerycost) + changeToNumber(IPsundrycost) + changeToNumber(IPtravelcost) + changeToNumber(IPwatercost) + changeToNumber(IPcapitalallowances) + changeToNumber(IPcapitalworks) + changeToNumber(IPdepreciationschedulefee) + changeToNumber(IPlegalcost) + changeToNumber(IPothercosts)}</b> </Typography>
    <Typography variant="body1" gutterBottom component="div" color={NetIncome<0?'error':'success'} > <b>{NetIncome<0?'Net Loss: $ '+NetIncome:'Net Profit $ '+NetIncome}</b> </Typography>
    </>
    }
            
      />
<CardContent>
    {expand? <div>
    {expand&&<IconButton onClick={handleExpand}><div><ExpandLessIcon/> <Typography variant={'button'} color={'secondary'}>-------- Show Less</Typography></div></IconButton>}
    
    
<Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={12}>
    
    <Typography variant="body1" gutterBottom component="div" color={'note'}> <b>Note:</b> If the below information is not handy, you can complete it later.</Typography>
        <Typography variant="body2" gutterBottom component="div" color={'note'}> You can dowload the <b>Rental Claim Checklist</b> and <b>Rental XLS</b> to work offline. </Typography>
        
        <IconButton onClick={downloadFile} color='primary'><TableViewIcon/><Typography variant={'button'} color={'primary'}> Download Rental Excel to work offline</Typography></IconButton>
        <IconButton onClick={downloadFile} color='primary'><PictureAsPdfIcon/><Typography variant={'button'} color={'primary'}> Download Rental Checklist to work offline</Typography></IconButton>
    </Grid>

<Grid item xs={12} sm={12} md={4} lg={4}>
{detailsChanged?<Button variant="contained" color='secondary' onClick={handleUpdate}>Save This Section</Button>:<></>}
        <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Property Details:</b> </Typography>
        <TextField onClick={handleDetailsChanged} label="Acquisition Year" fullWidth margin='dense' value={IPpurchaseyear}  onChange={(e)=> setPurchaseyear( e.target.value)} /> 
        <TextField onClick={handleDetailsChanged} label="Your Share ( In % age )" fullWidth margin='dense' value={IPyourshare} onChange={(e)=> setYourshare( changeToNumberbelowHundered( e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Purchase Price" fullWidth margin='dense' value={IPpurchaseprice}  onChange={(e)=> setPurchaseprice( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Approx date when property earnt first income" fullWidth margin='dense' value={IPdatefirstrentincome}  onChange={(e)=> setDatefirstrentincome( e.target.value)} />
        <TextField onClick={handleDetailsChanged} label="Weeks it was available for rent this year" fullWidth margin='dense' value={IPweeksavailableforrent}  onChange={(e)=> setWeeksavailableforrent( e.target.value)} />
        <TextField onClick={handleDetailsChanged} label="Weeks property was rented this year" fullWidth margin='dense' value={IPweeksrented}  onChange={(e)=> setWeeksrented( e.target.value)} />
        <TextField onClick={handleDetailsChanged} label="Loan Negotiated this year" fullWidth margin='dense' value={IPloannegotiated}  onChange={(e)=> setLoannegotiated( changeToNumber(e.target.value))} />
</Grid>
<Grid item xs={12} sm={12} md={4} lg={4}>

<Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Income Details:</b> </Typography>
<Typography variant="button" gutterBottom component="div" color={'info'}> <b>Please enter whole number to the nearest dollar without $ sign</b> </Typography>
        <TextField onClick={handleDetailsChanged} label="Gross Rental Income" fullWidth margin='dense' value={IPrentalincome}  onChange={(e)=> setRentalincome( changeToNumber(e.target.value))} />

</Grid>

<Grid item xs={12} sm={12} md={6} lg={6}>
{detailsChanged?<Button variant="contained" color='secondary' onClick={handleUpdate}>Save This Section</Button>:<></>}
<Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Expense Details:</b> </Typography>
<Typography variant="button" gutterBottom component="div" color={'info'}> <b>Please enter whole number to the nearest dollar without $ sign</b> </Typography>
        <TextField onClick={handleDetailsChanged} label="Advertising Cost" fullWidth margin='dense' value={IPadvertisingcost}  onChange={(e)=> setAdvertisingcost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Property Agent Cost" fullWidth margin='dense' value={IPpropertyagentcost}  onChange={(e)=> setPropertyagentcost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Interest cost" fullWidth margin='dense' value={IPinterestcost}  onChange={(e)=> setInterestcost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Bank Fees" fullWidth margin='dense' value={IPbankfees}  onChange={(e)=> setBankfees( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Borrowing cost" fullWidth margin='dense' value={IPborrowingcost}  onChange={(e)=> setBorrowingcost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Insurance cost" fullWidth margin='dense' value={IPinsurancecost}  onChange={(e)=> setInsurancecost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Land tax cost" fullWidth margin='dense' value={IPlandtax}  onChange={(e)=> setLandtax( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Council rates" fullWidth margin='dense' value={IPcouncilrates}  onChange={(e)=> setCouncilrates( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Body corporate cost" fullWidth margin='dense' value={IPbodycorporatecost}  onChange={(e)=> setBodycorporatecost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Gardening cost" fullWidth margin='dense' value={IPgardeningcost}  onChange={(e)=> setGardeningcost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Cleaning cost" fullWidth margin='dense' value={IPcleaningcost}  onChange={(e)=> setCleaningcost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Pest control cost" fullWidth margin='dense' value={IPpestcontrolcost}  onChange={(e)=> setPestcontrolcost( changeToNumber(e.target.value))} />
</Grid>
<Grid item xs={12} sm={12} md={6} lg={6}>

    <br /> <br /> <br /> <br /> 

        <TextField onClick={handleDetailsChanged} label="Repairs and Maintenance cost" fullWidth margin='dense' value={IPrepairsandmaintenancecost}  onChange={(e)=> setRepairsandmaintenancecost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Stationary cost" fullWidth margin='dense' value={IPstationerycost}  onChange={(e)=> setStationerycost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Sundry cost" fullWidth margin='dense' value={IPsundrycost}  onChange={(e)=> setSundrycost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Travel cost" fullWidth margin='dense' value={IPtravelcost}  onChange={(e)=> setTravelcost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Water cost" fullWidth margin='dense' value={IPwatercost}  onChange={(e)=> setWatercost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Capital Allowance cost" fullWidth margin='dense' value={IPcapitalallowances}  onChange={(e)=> setCapitalallowances( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Capital Works" fullWidth margin='dense' value={IPcapitalworks}  onChange={(e)=> setCapitalworks( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Depreciation Schedule Fees" fullWidth margin='dense' value={IPdepreciationschedulefee}  onChange={(e)=> setDepreciationschedulefee( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Legal cost" fullWidth margin='dense' value={IPlegalcost}  onChange={(e)=> setLegalcost( changeToNumber(e.target.value))} />
        <TextField onClick={handleDetailsChanged} label="Orher cost" fullWidth margin='dense' value={IPothercosts}  onChange={(e)=> setOthercosts( changeToNumber(e.target.value))} />
</Grid>

</Grid>
</div>:<></>}
</CardContent>
<CardActions>
<IconButton onClick={handleExpand}>{!expand?<div><ExpandMoreIcon/><Typography variant={'button'}>-------- VIEW / EDIT TAX RELATED INFORMATION</Typography></div>:<div><ExpandLessIcon/> <Typography variant={'button'}>-------- Show Less</Typography></div>}</IconButton>
<IconButton onClick={downloadFile} color='primary'><DownloadForOfflineIcon/><Typography variant={'button'} color={'primary'}>------ Download Rental Excel to work offline</Typography></IconButton>
</CardActions>
</Card>

    </div>    );
}
 
export default InvestmentPropertyCard;