import { API, graphql, graphqlOperation } from 'aws-amplify';
import { createKTADividend, deleteKTADividend} from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { addProfileDividend, removeProfileDividend } from '../Features/profile';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import {  Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import WorkIcon from '@mui/icons-material/Work';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DividendCard from './DividendCard';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const DividendDetails = () => {
    
    
    const profile = useSelector(state => state.profile.value);
    const dispatch = useDispatch();
    // Modal related 
    const [open, setOpen] = React.useState(false);
    // Data collectted in modal
   const [dividends,setDividends] = React.useState([{name: ''}]);
    const [name, setName] = React.useState("");
    
    useEffect(() => {
        if (profile){
            if (profile.dividends){
              setDividends(profile.dividends.items);
            }
        }
    }, [profile]);

    // Modal Functions
    const handleOpen = () => {
        setOpen(true);
        setName("");
        
        
    }

    const handleCreate = () => {
        setOpen(false);
        var newDividends = {
            name: name,
            kTACustomerDividendsId: profile.id,
        };
        API.graphql(graphqlOperation(createKTADividend, {input: newDividends})).then(res => {
            console.log(res.data.createKTADividend);
            dispatch(addProfileDividend(res.data.createKTADividend));
        }).catch(e => {
            console.warn(e);
        }
        );
        
    }

    const handleModalLooseFocus = () => {
        setOpen(false);
    }
    

        const delteItem = (item) =>{
            
          API.graphql(graphqlOperation(deleteKTADividend, {input: {id: item.id}})).then(res => {
              console.log(res);
              dispatch(removeProfileDividend(item.id));
          }).catch(e => {
              console.warn(e);
          });
            
        }

    return ( <div>
      {JSON.parse(profile.dividend_flag)?
    <div className='w3-container'>
      <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Dividends earned from shares:</b> </Typography>
       {/* {props.state.incomesources.salary ? <div className="w3-padding">  */}
       <div className="w3-padding"> 
    <div className="w3-container"> 
    <Button variant="contained" endIcon={<RequestQuoteIcon />} onClick={handleOpen}> Add share that paid dividend </Button>
    <br />  <br />
    </div>   
        {   dividends.map(dividend => (
            <DividendCard key={dividend.id} dividend={dividend} callBackFunction={delteItem}/>
        ))}
            <br />
        
        
    
      <Modal
        open={open}
        onClose={handleModalLooseFocus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2"> 
            Add the Dividends
          </Typography>
          <TextField
          label="Name of share that paid the dividend"
          fullWidth
          margin='dense'
          value={name}
          onChange={(e)=> setName( e.target.value)}
        />
        
        <br />
        <br />
        <Typography variant='body2' color='secondary'> We will be asking you to email us the dividend statements for tax return calculations. </Typography>
        <br />
        <Button variant="contained" onClick={handleCreate}>Submit</Button>
        </Box>
      </Modal>


    </div>
    </div>   :<></>}
    </div>
    );
}
 
export default DividendDetails;

