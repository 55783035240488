import { API, graphql, graphqlOperation } from 'aws-amplify';
import { createKTABankInterest, deleteKTABankInterest} from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { addProfileBankInterest, removeProfileBankInterest } from '../Features/profile';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import {  Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import WorkIcon from '@mui/icons-material/Work';

import BankInterestCard from './BankInterestCard';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const BankInterestDetails = () => {
    
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile.value);
    
    
    // Modal related 
    const [open, setOpen] = React.useState(false);
    // Data collectted in modal
   const [banks,setBanks] = React.useState([{name: '', interest: '', yourshare: ''}]);
    const [name, setName] = React.useState("");
    const [interest, setInterest] = React.useState([]);
    const [yourshare, setYourshare] = React.useState([]);
    
    
    useEffect(() => {
        if (profile){
            if (profile.bankinterests){
              setBanks(profile.bankinterests.items);
              
            }
        }
    }, [profile]);

    // Modal Functions
    const handleOpen = () => {
        setOpen(true);
        setName("");
        setInterest("");
        setYourshare("");
        
    }

    const handleCreate = () => {
        setOpen(false);
        var newBankInterest = {
            name: name,
            interest: interest,
            yourshare: yourshare,
            kTACustomerBankinterestsId: profile.id,
        };
        API.graphql(graphqlOperation(createKTABankInterest, {input: newBankInterest})).then(res => {
            console.log(res.data.createKTABankInterest);
            dispatch(addProfileBankInterest(res.data.createKTABankInterest));
            
        }).catch(e => {
            console.warn(e);
        }
        );
        
    }

    const handleModalLooseFocus = () => {
        setOpen(false);
    }
    

        const delteItem = (item) =>{
            
          API.graphql(graphqlOperation(deleteKTABankInterest, {input: {id: item.id}})).then(res => {
              console.log(res);
              dispatch(removeProfileBankInterest(item.id));
          }).catch(e => {
              console.warn(e);
          });
            
        }

    return ( <div>
    {JSON.parse(profile.bankinterest_flag)?<div className='w3-container'>
      <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Bank Interest I have earned:</b> </Typography>
       {/* {props.state.incomesources.salary ? <div className="w3-padding">  */}
       <div className="w3-padding"> 
    <div className="w3-container"> 
    <Button variant="contained" endIcon={<CurrencyExchangeIcon />} onClick={handleOpen}> Add bank Interest </Button>
    <br />  <br />
    </div>   
        {   banks.map(bank => (
            <BankInterestCard key={bank.id} bank={bank} callBackFunction={delteItem}/>
        ))}
            <br />
        
        
    
      <Modal
        open={open}
        onClose={handleModalLooseFocus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2"> 
            Add Bank Interest
          </Typography>
          <TextField
          label="Bank Name"
          fullWidth
          margin='dense'
          value={name}
          onChange={(e)=> setName( e.target.value)}
        />
        <TextField
          label="ABN"
          fullWidth
          margin='dense'
          value={interest}
          onChange={(e)=> setInterest( e.target.value)}
        />
        <TextField
          label="ABN"
          fullWidth
          margin='dense'
          value={yourshare}
          onChange={(e)=> setYourshare( e.target.value)}
        />
        <br />
        <br />
        <Typography variant='body2' color='secondary'> We may be asking you to email us the bank statements for tax return calculations. </Typography>
        <br />

        <Button variant="contained" onClick={handleCreate}>Submit</Button>
        </Box>
      </Modal>


    </div>
    </div>:<></>}
    </div>
    );
}
 
export default BankInterestDetails;

