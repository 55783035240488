export const EmailTemplate = (obj) => { 



    return (
    `<body style="margin: 0 !important; padding: 0 !important;">
    <table bgcolor="#f5f5f5" style="background-color: #f5f5f5;" border="0" cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td align="center" style="padding: 0px 15px;">
          <table bgcolor="#fff" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;" class="wrapper">
            <tr>
              <td height="35" width="100%" align="center">
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" style="padding: 0;" class="logo">
                <h1> Karvis Accountats </h1>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr>
        <td align="center" style="padding: 0px 15px 30px 15px;" class="section-padding">
          <table bgcolor="#fff" border="0" cellpadding="0" cellspacing="0" width="600" class="responsive-table">
            <tr>
              <td>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td align="center" valign="top" width="600" style="padding-left:40px;padding-right:40px;padding-top:30px">
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">      
                        <tr>
                          <td align="center" style="font-size: 36px; font-family: Helvetica, Arial, sans-serif; color: #333333;" class="padding-copy">
                           ${obj.title}
                          </td>
                        </tr>
                        <tr>
                          <td align="left" style="padding: 20px 0 0 0; font-size: 16px; line-height: 1.5; font-family: Helvetica, Arial, sans-serif; color: #666666;" class="padding-copy">
                            ${obj.greeting},
                            <br/>
                            ${obj.body}
                            <br/>
                            <div > ${obj.link} </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" style="padding: 20px 0 0 0; font-size: 16px; line-height: 1.5; font-family: Helvetica, Arial, sans-serif; color: #666666;" class="padding-copy">
                      We are happy to assist you further for any queries you might have.<br/>
                      You can contact us on:  https://karvistax.com.au
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>

    </table>
  </body>
`)}