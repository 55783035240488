import { Container, Grid, IconButton, Paper, Typography } from "@mui/material";
import '../SearchableTable.css'
import TableViewIcon from '@mui/icons-material/TableView';

const Forms = () => {

    function myFunction() {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }       
        }
      }

    function downloadFile(filename) {
    const fileUrl = process.env.PUBLIC_URL + '/'+filename; 
    
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    }

    return ( <div className="w3-light-grey">
    <Container maxWidth="xl" >
        <br />
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'400px' }}>
                <Typography variant="h6" gutterBottom component="div" color={'primary'}> Downloadable Reference Documents and Customer Forms </Typography>
                    
                <input type="text" id="myInput" onKeyUp={myFunction} placeholder="Search for documents.." title="Type in a name"/>
             

                    <table id="myTable">
                    <tr className="header">
                        <th style={{width:'60%'}}>Document Name </th>
                        <th style={{width:'40%'}}>Attachments</th>
                    </tr>
                    <tr>
                        <td>Individual Income Tax Checklist</td>
                        <td>
                        <IconButton onClick={()=>downloadFile('Checklist.pdf')} color='primary'><TableViewIcon/><Typography variant={'button'} color={'primary'}> Download File</Typography></IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td>Company Order Form / Company Set Up Form </td>
                        <td>
                        <IconButton onClick={()=>downloadFile('CompanySetUp.docx')} color='primary'><TableViewIcon/><Typography variant={'button'} color={'primary'}> Download File</Typography></IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td>Employee Checklist Excel</td>
                        <td>
                        <IconButton onClick={()=>downloadFile('EmployeeSheetExcel.csv')} color='primary'><TableViewIcon/><Typography variant={'button'} color={'primary'}> Download File</Typography></IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td>Rental Claim Checklist</td>
                        
                        <td><IconButton onClick={()=>downloadFile('RentalClaimChecklist.pdf')} color='primary'><TableViewIcon/><Typography variant={'button'} color={'primary'}> Download File</Typography></IconButton></td>
                    </tr>
                    <tr>
                        <td>Rental Sheet Excel</td>
                        <td><IconButton onClick={()=>downloadFile('RentalSheetExcel.xlsx')} color='primary'><TableViewIcon/><Typography variant={'button'} color={'primary'}> Download File</Typography></IconButton></td>
                    </tr>
                    <tr>
                        <td>Trust Set Up Form</td>
                        <td><IconButton onClick={()=>downloadFile('TrustSetup.docx')} color='primary'><TableViewIcon/><Typography variant={'button'} color={'primary'}> Download File</Typography></IconButton></td>
                    </tr>
                    <tr>
                        <td>Uber Taxi Template</td>  
                        <td><IconButton onClick={()=>downloadFile('UberTaxiTemplate.xlsx')} color='primary'><TableViewIcon/><Typography variant={'button'} color={'primary'}> Download File</Typography></IconButton></td>
                    </tr>
                    </table>
                </Paper>
            </Grid>
            
            </Grid>
        </Container>
        <br />
    </div> );
}
 
export default Forms;