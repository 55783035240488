import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;
export const usersSlice = createSlice({
    name: "user",
    initialState: { value: initialStateValue },
    reducers: {
        login: (state, action) => {
            state.value = action.payload;
        }

    }
});

export const { login } = usersSlice.actions;
export default usersSlice.reducer;