export const validateEmail = (value) => {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  export const validatePhone = (value) => {
    return String(value)
      .toLowerCase()
      .match(
        /^0\d{9}$/
      );
  };
 
  export const validateDollar = (value) => {
    return String(value)
      .toLowerCase()
      .match(
        /^\d+(?:\.\d{1,2})?$/
      );
  };


  
  export const validateTFN =(tfn) => {
    // Remove spaces and convert to an array of digits
    if (!tfn||tfn.length===0) {
        // No TFN provided
        return false;
    }
    const tfnDigits = tfn.replace(/\s/g, "").split("").map(Number);
    if (tfnDigits.length !== 8 && tfnDigits.length !== 9) {
        // Invalid length
        return false;
    }

   // Calculate the weighted sum of the TFN digits
   const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];
   const checkDigit = parseInt(tfn.charAt(8), 10);
   let sum = 0;
   for (let i = 0; i < 8; i++) {
     sum += parseInt(tfn.charAt(i), 10) * weights[i];
   }
 
   // Check if the check digit matches the calculated sum modulo 11
   return sum % 11 === checkDigit;
}