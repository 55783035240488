import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import userReducer from  './Components/Features/user';
import useProfile from './Components/Features/profile';
import useEmployee from './Components/Features/employees';
import useWorkItenms from './Components/Features/workitems';
import dcflagsReducer from './Components/Features/detailschangedflags';
import useTempDetails from './Components/Features/temporarydata';

const store = configureStore({
  reducer: {
    user: userReducer,
    profile: useProfile,
    dcflags: dcflagsReducer,
    employees: useEmployee,
    workitems: useWorkItenms,
    tempdetails: useTempDetails
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
);

