import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {fname: " ", salaried_flag: false, soletrader_flag: false, acn_flag: false, trust_flag: false, investmentproperty_flag: false, bankinterest_flag: false, dividend_flag: false,
occupations: {items: []}, dependants: {items: []}, taxaccounts: {items: []}, privileges: {items: []}, documents: {items: []}, paymentrecords: {items: []}, investmentproperties: {items: []}, bankinterests: {items: []}, dividends: {items: []}, companies: {items: []}, trusts: {items: []}, bank_bsb: "", bank_account: ""};

export const profileSlice = createSlice({
    name: "profile",
    initialState: { value: initialStateValue },
    reducers: {
        refreshProfile: (state, action) => {
            state.value = action.payload;
        },
        addDocument: (state, action) => {
            state.value.documents.items.push(action.payload);
         },
         removeDocument : (state, action) => {
            state.value.documents.items = state.value.documents.items.filter(item => item.id !== action.payload)
         },
        updatePersonalFields: (state, action) => {
           state.value = {...state.value, ...action.payload}
        },
        updateProfileFields: (state, action) => {
            state.value = {...state.value, ...action.payload}
         },
         addProfileDependent: (state, action) => {
            state.value.dependants.items.push(action.payload)
         },
         removeProfileDependent: (state, action) => {
            state.value.dependants.items = state.value.dependants.items.filter(item => item.id !== action.payload)      
         },
         addProfileOccupation: (state, action) => {
            state.value.occupations.items.push(action.payload)
         },
        removeProfileOccupation: (state, action) => {
            state.value.occupations.items = state.value.occupations.items.filter(item => item.id !== action.payload)
         },
         addProfileProperty: (state, action) => {
            state.value.investmentproperties.items.push(action.payload)
         },
         updateProfileInvestmentProperty: (state, action) => {
            state.value.investmentproperties.items = state.value.investmentproperties.items.map(item => item.id === action.payload.id ? action.payload : item)
        },

        removeProfileProperty: (state, action) => {
            state.value.investmentproperties.items = state.value.investmentproperties.items.filter(item => item.id !== action.payload)
         },
        addProfileBankInterest: (state, action) => {
        state.value.bankinterests.items.push(action.payload)
        },
        removeProfileBankInterest: (state, action) => {

        state.value.bankinterests.items = state.value.bankinterests.items.filter(item => item.id !== action.payload)
        },
        addProfileDividend: (state, action) => {
        state.value.dividends.items.push(action.payload)
        } ,
        removeProfileDividend: (state, action) => {
        state.value.dividends.items = state.value.dividends.items.filter(item => item.id !== action.payload)
        } ,
        addProfileCompany: (state, action) => {
        state.value.companies.items.push(action.payload)
        } ,
        removeProfileCompany: (state, action) => {
        state.value.companies.items = state.value.companies.items.filter(item => item.id !== action.payload)
        }  ,
        addProfileTrust: (state, action) => {
        state.value.trusts.items.push(action.payload)
        } ,
        removeProfileTrust: (state, action) => {
        state.value.trusts.items = state.value.trusts.items.filter(item => item.id !== action.payload)
        }
        

    }
});

export const { refreshProfile,
    updatePersonalFields,
     updateProfileFields, 
     addProfileDependent, 
     removeProfileDependent,
      addProfileOccupation, 
      removeProfileOccupation,
        addProfileProperty,
        updateProfileInvestmentProperty,
        removeProfileProperty,
        addProfileBankInterest,
        removeProfileBankInterest,
        addProfileDividend,
        removeProfileDividend,
        addProfileCompany,
        removeProfileCompany,
        addProfileTrust,
        removeProfileTrust,
         addDocument,
         removeDocument
    
    } = profileSlice.actions;
export default profileSlice.reducer;