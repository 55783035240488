import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import  { validatePhone, validateTFN} from '../../GlobalVariables/GlobalValidator';
import { Autocomplete, Button, Grid, MenuItem, Select, Typography } from '@mui/material';

import { API, graphqlOperation } from 'aws-amplify';
import { updateKTACustomer } from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import {updatePersonalDetailFlag} from '../Features/detailschangedflags';
import { updatePersonalFields } from '../Features/profile';
import WarningModal from '../WarningModal';



const PersonalDetails = () => {
// The new code is below this line 
    const employees = useSelector(state => state.employees.value);
    const [customerfacingemployees, setCustomerFacingEmployees] = useState([]);
    const profile = useSelector(state => state.profile.value);
    const dcflags = useSelector(state => state.dcflags.value);
    const [detailsChanged, setDetailsChanged] = useState(false);
    const dispatch = useDispatch();
    const [errPhone, setErrPhone] = useState(false);
    const [errPhoneText, setErrPhoneText] = useState("");
    const [errFlag, setErrFlag] = useState(false);
    const [errFname, setErrFname] = useState(false);
    const [errFnameText, setErrFnameText] = useState("");
    const [errLname, setErrLname] = useState(false);
    const [errLnameText, setErrLnameText] = useState("");
    const [errTfn, setErrTfn] = useState(false);
    const [errTfnText, setErrTfnText] = useState("");
    const [errDob, setErrDob] = useState(false);
    const [errDobText, setErrDobText] = useState("");
    const [errAddress, setErrAddress] = useState(false);
    const [errAddressText, setErrAddressText] = useState("");
    const [errTaxAgent, setErrTaxAgent] = useState(false);
    const [errTaxAgentText, setErrTaxAgentText] = useState("");
  const [warningflag, setWarningFlag] = useState(false);
  const [warningheader, setwarningheader] = useState("");
  const [warningtext, setwarningtext] = useState("");
  const [straddressline1, setstraddressline1] = useState("");
  const [ erroraddressline1, seterroraddressline1] = useState(false);
  const [strPostCode, setstrPostCode] = useState("");
  const [ errorpostcode, seterrorpostcode] = useState(false);
  const [strState, setstrState] = useState("dummy");
  const [ errState, seterrState] = useState(false);
const [errStateFlag, seterrStateFlag] = useState(false);

    
    
    //Fields required for form 
    const [strAddress, setStrAddress] = useState("")
    const [options, setOptions] = useState([{FULL_ADDRESS: 'Test', _id: '0AI'}]);
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [phone, setPhone] = useState("")
    const [tfn, setTfn] = useState("")
    const [dob, setDob] = useState("")
    const [taxAgent, setTaxAgent] = useState("dummy")
    const [email, setEmail] = useState("")


     useEffect(() => {

      // split strig delumited by | and set the values
      

      if (profile && profile.address){
        let addressarray = profile.address.split("|");
        setstraddressline1(addressarray[0].trim());
        setstrState(addressarray[1].trim());
        setstrPostCode(addressarray[2].trim());
        setStrAddress(profile.address);
      }

      

      if (profile) {
        setFname(profile.fname);
        setLname(profile.lname);
        setEmail(profile.email);
        setPhone(profile.phone);
        setTfn(profile.tfn);
        setDob(profile.dob);
        
        setTaxAgent(profile.engagingtaxagent);
        
      }
     }, [profile]);

     useEffect(() => {
      if (employees) {
        setCustomerFacingEmployees( employees.filter(emp => emp.customerfacing_flag === true));
      }
    }, [employees]);
     
    const handlesetFname = (value) => {
      setDetailsChanged(true);
        setFname(value);
        dispatch(updatePersonalDetailFlag(true));
        if (value === ""){
          setErrFlag(true);
          setErrFname(true);
          setErrFnameText("Required*");
        }else {
          setErrFname(false);
          setErrFnameText("");
        }
    }
    const handlesetLname = (value) => {
      setDetailsChanged(true);
        setLname(value);
        dispatch(updatePersonalDetailFlag(true));
        if (value === ""){
          setErrFlag(true);
          setErrFname(true);
          setErrFnameText("Required*");
        }else {
          setErrFname(false);
          setErrFnameText("");
        }
    }
    
    const handlesetPhone = (value) => {
      setDetailsChanged(true);
        setPhone(value);
        dispatch(updatePersonalDetailFlag(true)); 
        if (!validatePhone (value)){
          setErrFlag(true);
          setErrPhone(true);
          setErrPhoneText("10 digit starting with 0");
    
          }else { 
          setErrPhone(false);
          setErrPhoneText("");
          }
    }
    const handlesetTfn = (value) => {
      setDetailsChanged(true);
        setTfn(value);
        dispatch(updatePersonalDetailFlag(true));
        if (!validateTFN (value)){
          setErrFlag(true);
          setErrTfn(true);
          setErrTfnText("Required*");
        }else {
          setErrTfn(false);
          setErrTfnText("");
        }
  
        
    }
    const handlesetDob = (value) => {
      setDetailsChanged(true);
        setDob(value);
        dispatch(updatePersonalDetailFlag(true));
    }
    // const handlesetStrAddress = (value) => {
    //   setDetailsChanged(true);
    //     setStrAddress(value);
    //     dispatch(updatePersonalDetailFlag(true));
    //     if (value === ""){
    //       setErrFlag(true);
    //       setErrAddress(true);
    //       setErrAddressText("Required*");
    //     }else {
    //       setErrAddress(false);
    //       setErrAddressText("");
    //     }
  
        
    // }
    const handlesetTaxAgent = (value) => {
      setDetailsChanged(true);
        setTaxAgent(value);
        dispatch(updatePersonalDetailFlag(true));
        if (value === "dummy"){
          setErrFlag(true);
          setErrTaxAgent(true);
          setErrTaxAgentText("Required*");
        }else {
          setErrTaxAgent(false);
          setErrTaxAgentText("");
        }
    }


      // This is for AutoComplete of address
    // useEffect(() => {
      
    //   if (!strAddress) {
    //     setOptions([]);
    //   } else if (strAddress.length%3 === 0 && strAddress.length >= 3) {
    //   let active = true;
    //     console.log(strAddress);
      
    //     const fetchAddresses = async () => {
    //     // You should replace this URL with your own API endpoint
    //     const response = await fetch(`https://api.addresser.com.au/getautocomplete/all?search_word=${strAddress}`,{
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'x-api-key': `AsFZfH0oSW5KLkrTpLeNK2uq9H1gz6ky716vwJ0B`,
    //         'Accept': 'application/json',
    //       },
    //       method: 'GET',

          
    //     });
    //     const addresses = await response.json();
  
    //     if (active) {
    //       console.log(addresses);
    //       if(addresses.errorCode){
    //         setOptions([{FULL_ADDRESS: 'No Address Found', _id: '0AI'}]);
    //       }else {
    //       setOptions(addresses);
    //       }
    //     }
    //   };
  
    //   fetchAddresses();
  
    //   return () => {
    //     active = false;
    //   };
    // }
    // }, [strAddress]);





  function handleSave() { 

    if (strState === "dummy"){
      seterrStateFlag(true);
      seterrState("Required*");
    }else {
      seterrStateFlag(false);
      seterrState("");
    


      if (!errPhone && !errTfn && !errAddress && !errTaxAgent && !errFname && !errLname){
        let personaldata = {
          id: profile.id,
          fname: fname,
          lname: lname,
          phone: phone,
          tfn: tfn,
          dob: dob,
          address: strAddress,
          engagingtaxagent: taxAgent,
        }
        API.graphql(graphqlOperation(updateKTACustomer, {input: personaldata})).then(res => {
          console.log("Customer Data", res);
          if (res.data.updateKTACustomer){
            console.log(res.data.updateKTACustomer);
            dispatch(updatePersonalFields(personaldata));
            dispatch(updatePersonalDetailFlag(false));
            setDetailsChanged(false);
            
          } else{
            setWarningFlag(true);
            setwarningheader("Error");
            setwarningtext("There was an error updating your details. Please try again later.");
          }
        }).catch(e => {
          console.warn(e);
          setWarningFlag(true);
          setwarningheader("Error Whoops");
          setwarningtext("There was an error updating your details. Please try again later.");
        });

      }else {
        setWarningFlag(true);
        setwarningheader("Validation Errors");
        setwarningtext("Please complete all required field appropriately.");
      }
    }
    
  }

  function resetwarningflag() {
    setWarningFlag(false);
  }

function handleAddressLine1(value) {
  setDetailsChanged(true);
      

setstraddressline1(value);
setStrAddress(value + " | " + strState + " | " + strPostCode);

}

function handlePostCode(value) {

  setDetailsChanged(true);
      
  // accept only numbers
  if (isNaN(value) || value.length > 4) {
    return;
  }
  else {
  
    setstrPostCode(value);
    setStrAddress(straddressline1 + " | " + strState + " | " + value);
  }
}

function handleStateChange(value) {
  setDetailsChanged(true);
      
    setstrState(value);
    setStrAddress(straddressline1 + " | " + value + " | " + strPostCode);
}


    return ( <div className="w3-padding w3-container"> <br />
         {detailsChanged?<Button variant="contained" color='secondary' onClick={handleSave}>Save This Section</Button>:<></>}
         <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>My Details</b> </Typography>
          <Typography variant="body2" gutterBottom component="div" color={'note'}> The Karvistax consultant who onboarded you. </Typography>
         <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={taxAgent}
            label="No"
            fullWidth
            error={errTaxAgent}
            // onBlur={handleSave}
            helperText={errTaxAgentText}
            onChange={(e)=> handlesetTaxAgent(e.target.value)}
          ><MenuItem value={"dummy"}>None of the above</MenuItem>
          {customerfacingemployees && customerfacingemployees.map((emp) => {
            return <MenuItem key={emp.id} value={emp.email}>{emp.name}</MenuItem>
        }
        )}
        </Select>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
        
        <TextField
        id='fname'
        // onBlur={handleSave}
          error={errFname}
          label="First Name"
          fullWidth
          margin='normal'
          required
          helperText={"Required*"}
          value={fname}
          onChange={(e)=> handlesetFname(e.target.value)}
          
        />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          error={errLname}
          label="Last Name"
          // onBlur={handleSave}
          fullWidth
          margin='normal'
          required
          helperText={"Required*"}
          value={lname}
          onChange={(e)=> handlesetLname(e.target.value)}
        />
        </Grid>
        </Grid>
        <TextField
          helperText={"Required*"}
          label="Email"
          variant='filled'
          value={email}
          fullWidth
          margin='dense'
          InputProps={{
            readOnly: true,
          }}
          
        />
        <TextField
          error={errTfn}
          id="tfn"
          // onBlur={handleSave}
          label={"Tax File Number"}
          value={tfn}
          onChange={(e)=> handlesetTfn(e.target.value)}
          helperText={"Please Enter a Valid TFN*"}
          fullWidth
          margin='dense'
        />
        {/* <div className="w3-row">
         
        <TextField
          error={errAddress}
          id="tfn"
          helperText={errAddressText}
          label="Address"
          variant='filled'
          value={strAddress}
          fullWidth
          margin='dense'
          InputProps={{
            readOnly: true,
          }}
          color='primary'
          
        />
          <div >
          
          <Autocomplete
         filterOptions={(x) => x}
         options={options}
         autoComplete
        //  onBlur={handleSave}
         includeInputInList
         filterSelectedOptions
         noOptionsText="No locations"
        getOptionLabel={(option) => option.FULL_ADDRESS}
        onInputChange={(event, newInputValue) => {
          handlesetStrAddress(newInputValue);
        }}
        renderInput={(params) =>  <TextField {...params} label=" Update Address"  />}
        />
        </div>
         
        </div> */}
        <TextField
          error={erroraddressline1}
          id="address1"
          label="Street Address and Suburb"
          value={straddressline1}
          onChange={(e)=> handleAddressLine1(e.target.value)}
          helperText={"Required*"}
          fullWidth
          // onBlur={handleSave}
          margin='normal'
          
        />

        <Select
            labelId="lblState"
            id="lblState"
            value={strState}
            label="No"
            error={errStateFlag}
            // onBlur={handleSave}
            helperText={errState}
            onChange={(e)=> handleStateChange(e.target.value)}
          >
          <MenuItem value={"dummy"}>State</MenuItem>
          <MenuItem value={"SA"}>South Australia</MenuItem>
          <MenuItem value={"VIC"}>Victoria</MenuItem>
          <MenuItem value={"NSW"}>New South Wales</MenuItem>
          <MenuItem value={"QLD"}>Queensland</MenuItem>
          <MenuItem value={"WA"}>Western Australia</MenuItem>
          <MenuItem value={"TAS"}>Tasmania</MenuItem>
          <MenuItem value={"NT"}>Northern Territory</MenuItem>
          <MenuItem value={"ACT"}>Australian Capital Territory</MenuItem>


          
        </Select>
        <br />

        <TextField
          error={errorpostcode}
          id="postcode"
          label="Postcode"
          value={strPostCode}
          onChange={(e)=> handlePostCode(e.target.value)}
          helperText={"Required*"}
          
          // onBlur={handleSave}
          margin='normal'
          
        />
        
        <TextField
          error={errPhone}
          id="mobile"
          label="Mobile No."
          value={phone}
          onChange={(e)=> handlesetPhone(e.target.value)}
          helperText={"Required*"}
          fullWidth
          // onBlur={handleSave}
          margin='normal'
          
        />
        
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale ="en">
       <DatePicker 
        error={false}
          id="dob"
          label="Date of birth"
          helperText={"Required*"}
          format ={'DD/MM/YYYY'}
          margin='normal'
          // onBlur={handleSave}
          value={dob?dayjs(dob):""}
          onChange={(e)=> handlesetDob(e)}
       />
       </LocalizationProvider>
       <br />
       <br />
       {detailsChanged?<Button variant="contained" color='secondary' onClick={handleSave}>Save This Section</Button>:<></>}
       <WarningModal warningflag={warningflag} warningheader={warningheader} warningtext={warningtext} resetwarningflag={resetwarningflag} />
    </div> );
};
 
export default PersonalDetails;