import * as React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';


import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import WarningModal from '../Components/WarningModal';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        Karvis Accountants
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login(props) {
  
   const [warningflag, setWarningFlag] = React.useState(false);
  const [warningheader, setwarningheader] = React.useState("");
  const [warningtext, setwarningtext] = React.useState("");
  function resetwarningflag(){
      setWarningFlag(false);
      setwarningheader("");
      setwarningtext("");

  }
  
  const [UserNotConfirmedException, setUserNotConfirmedException] = React.useState(false);
  const [confirmationcode, setconfirmationcode] = React.useState("");
  const [username, setUserName] = React.useState("");
  
  
  const [signupFlag, setSignupFlag] = React.useState(false);
  const handleNewUserSignup = () => {
    setSignupFlag(true);
    
  }

  React.useEffect(() => {
    if (props.signupflag) {
      setSignupFlag(true);
      
      
    }
  }, [props]);
  
  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(username);
      setWarningFlag(true);
      setwarningheader("Code Sent Successfully");
      setwarningtext("Please check your email.📝 Sometimes the code may land in Junk 🍕🍔 Mailbox. Please check there as well.")
    } catch (err) {
      console.log('error resending code: ', err);
      setWarningFlag(true);
      setwarningheader(err.name);
      setwarningtext(err.message)
    }
  }
  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(username, confirmationcode);
      setUserNotConfirmedException(false);
      setSignupFlag(false);
     
    } catch (error) {
      console.log('error confirming sign up', error);
      setWarningFlag(true);
      setwarningheader(error.name);
      setwarningtext(error.message)
    }
  }

  async function signUp(username, password) {
    try {
      const res = await Auth.signUp({
        username,
        password,
        autoSignIn: {
          enabled: false,
        }
      });
      setUserNotConfirmedException(true);      
      setUserName(username);
      console.log('sign up response', res);
    } catch (error) {
      console.log('error signing up:', error);
      setWarningFlag(true);
              setwarningheader(error.name);
              setwarningtext(error.message);
    }
  }


    const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
   
   
    
        if ((/\S+@\S+\.\S+/.test(data.get('email')))&&data.get('password')!==""){

        if (!signupFlag){
            Auth.signIn(data.get('email'),data.get('password')).then(response =>{
              

            }).catch (err=>{
                if (err.name === "UserNotConfirmedException"){
                    setUserNotConfirmedException(true);
                    setUserName(data.get('email'));

                } else {
                console.log(err);
                setWarningFlag(true);
                setwarningheader(err.name);
                setwarningtext(err.message);
                }
            })
          } else {
          
          
            signUp(data.get('email'),data.get('password'));
          

          }

      }else {
          setWarningFlag(true);
          setwarningheader("Please enter a valid Email and Password")
      }
  

  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          {!signupFlag?<img alt='logo' src='SVGLogo.svg' className='w3-image' style={{height:'150px'}}/>:<img alt='logo'  src='PurpleLogo.svg' className='w3-image' style={{height:'150px'}}/>}
          {signupFlag?<Typography component="h1" variant="h5" color={'secondary'}>
            New User Sign Up
          </Typography>:<Typography component="h1" variant="h5" >
            Existing user Sign In
            </Typography>}
          <Box component="form" onSubmit={handleSubmit}  noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              
            />
            {!UserNotConfirmedException? <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />: <TextField
              margin="normal"
              required
              fullWidth
              name="code"
              label="Code"
              id="code"
              value={confirmationcode}
              onChange={(e=> setconfirmationcode(e.target.value))}
            />}
            
           <br />

            {!UserNotConfirmedException? <div> {!signupFlag?<Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Existing User Sign In
            </Button>:<Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color='secondary'
            >
              New User Sign Up
            </Button>}</div>:
            <div>
            <Button
              onClick={confirmSignUp}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Confirm Code
            </Button>
            <Typography>Did not recieve the email ?</Typography>
            <Button
              onClick={resendConfirmationCode}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Resend Code
            </Button></div>}

            <Grid container>
            { !UserNotConfirmedException? <div className='w3-row'>
              
              
                {!signupFlag?<Link href="/forgotpassword" variant="button">
                  Forgot password?
                </Link>:<></>}
              
              <br /> <br />
                {!signupFlag?<Link onClick={handleNewUserSignup} variant="button" color={'primary'}>
                  New User? Sign Up
                </Link>:<Link onClick={()=>setSignupFlag(false)} variant="button" color={'primary'}>
                  Existing User? Sign In
                </Link>}

              
               </div> : <></>}
              
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <WarningModal warningflag={warningflag} warningheader={warningheader} warningtext={warningtext} resetwarningflag={resetwarningflag} />
    </ThemeProvider>
  );
}