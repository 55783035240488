
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './Pages/Home';
import ResponsiveAppBar from './Components/ResponsiveAppBar';
import Forms from './Pages/Forms';
import Payments from './Pages/Payments';
import TaxAccounts from './Pages/TaxAccounts';
import PersonalProfile from './Pages/PersonalProfile';
import StickyFooter from './Components/StickeyFooter';
import Login from './Pages/Login';
import awsConfig from './aws-exports';
import {API, Amplify, Auth, Hub, graphqlOperation} from 'aws-amplify';
import { listKTAEmployees } from './graphql/queries';
import { getKTACustomer } from './Components/CustomGraphQL/customqueries';
import * as subscriptions from './graphql/subscriptions';
import { useEffect, useState } from 'react';


import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { login } from './Components/Features/user';
import { refreshProfile } from './Components/Features/profile';
import { refreshEmployees } from './Components/Features/employees';


import WarningModal from './Components/WarningModal';

import SpinnerModal from './Components/SpinnerModal';
import AccountSettings from './Pages/AccountSettings';
import ForgotPassword from './Pages/ForgotPassword';
import PersonalDetails from './Components/OnboardingForms/PersonalDetails';
import SpouseDetails from './Components/OnboardingForms/SpouseDetails';
import DependentsDetails from './Components/OnboardingForms/DependentDetails';
import GeneralOnboarding from './Pages/GeneralOnboarding';

import { createKTACustomer } from './graphql/mutations';
import ResponseModal from './Components/ResponseModal';
import Documents from './Pages/Documents';
import ThankYou from './Pages/ThankYou';
import NewOnboarding from './Pages/NewOnboarding';
import ConfirmationModal from './Components/ConfirmationModal';

Amplify.configure(awsConfig);

function App() {

  
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.value);
  const tempdetails = useSelector(state => state.tempdetails.value);
  const [tempid, setTempid] = useState("");
  const [tememail, setTempemail] = useState("");
  const[templogin, setTemplogin] = useState();
   // code for warning modal
   const [warningflag, setWarningFlag] = useState(false);
   const [warningheader, setwarningheader] = useState("");
   const [warningtext, setwarningtext] = useState("");
   const [confirmFlag, setConfirmFlag] = useState(false);
    const [confirmHeader, setconfirmheader] = useState("");
    const [confirmText, setconfirmtext] = useState("");

   function resetwarningflag(){
       setWarningFlag(false);
       setwarningheader("");
       setwarningtext("");
 
   }



   // code for response modal
   const [responseflag, setResponseFlag] = useState(false);
   const [responseheader, setresponseheader] = useState("");
   const [responsetext, setresponsetext] = useState("");
   function resetresopnseflag(flag){
    
       setResponseFlag(false);
       setresponseheader("");
       setresponsetext("");
       setConfirmFlag(false);
        setconfirmheader("");
        setconfirmtext("");
    if (!flag){
       signOut();
    } else {
     console.log("here2",tempid);
     API.graphql(graphqlOperation(createKTACustomer, {input: {id: tempid, email: tememail, 
      fname: tempdetails.fName, salaried_flag: false, soletrader_flag: false, acn_flag: false, trust_flag: false, investmentproperty_flag: false, bankinterest_flag: false, dividend_flag: false,
      lname: tempdetails.lName, address: tempdetails.strAddr + " | "  + tempdetails.state + " | " + tempdetails.postcode, phone: tempdetails.phone,
      dob: tempdetails.dob, tfn: tempdetails.tfn
    }})).then(res => {
       API.graphql(graphqlOperation(getKTACustomer, {id: tempid})).then(res => {
         dispatch(refreshProfile(res.data.getKTACustomer));
         dispatch(login(templogin));
            window.localStorage.setItem('COGNITO_USER', JSON.stringify( templogin))
            window.localStorage.removeItem('ONBOARDING_FLOW');
            setSpin(false);
       }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
         console.warn(e);
       });
       }).catch(e => {
             console.warn(e);
       });
    }
 
   }

  useEffect(() => {
    setSpin(true);
    Hub.listen("auth", (event) =>{
      // console.log("Listener",event);
      if (event.payload.event==="signIn"){
        setTempid(event.payload.data.username);
        setTempemail(event.payload.data.attributes.email);
        setTemplogin(event.payload.data);
        console.log("here1",event.payload.data);
        console.log(tempid);
        API.graphql(graphqlOperation(getKTACustomer, {id: event.payload.data.username})).then(res => {
          // console.log("Customer Data", res);
          if (res.data.getKTACustomer){
            console.log(res.data.getKTACustomer);
            dispatch(refreshProfile(res.data.getKTACustomer));
            dispatch(login(event.payload.data));
            window.localStorage.setItem('COGNITO_USER', JSON.stringify( event.payload.data))
            setSpin(false);
          } else{
            
            if (window.localStorage.getItem('ONBOARDING_FLOW')!=="Yes"){
             setResponseFlag(true);
             setresponseheader("🔺Is this your first time logging in?");
             setresponsetext(" Looks like this is first time you have logged in with this Email ID. If yes 👍🏼 then Dont Worry 🙂 and continue.  But, If you already had a Karvis portal Account 🛑 please contact Karvis to get your correct Account Email ID.");
            } else {
              
              setConfirmFlag(true);
              setconfirmheader("Great! Your account will be created now");
              setconfirmtext("Please click OK to continue.");


              
              
            }
            // setWarningFlag(true);
            // setwarningheader("The user does not have a customer account.");
            // setwarningtext("Please contact Karvis to get your account setup.");
            // signOut();
            // setSpin(false);

          }
        }).catch(e => {
          console.error("Failed in App.js FIRST Auth Listner");
          console.warn(e);
          console.warn(e.errors[0].message);
          setSpin(false);
        });

        listAllKTAEmployees();
        
      }else if (event.payload.event==="signOut"){
        dispatch(login(null));
        window.localStorage.removeItem('COGNITO_USER');
        setSpin(false);
      }  
      else if (event.payload.event==="autoSignIn"){
       
      }
      else {
        // console.log("Auth Listner:", event);
        setSpin(false);
      }
    })
    
    dispatch(login(JSON.parse(window.localStorage.getItem('COGNITO_USER'))));
    if (JSON.parse(window.localStorage.getItem('COGNITO_USER'))){
      API.graphql(graphqlOperation(getKTACustomer, {id: JSON.parse(window.localStorage.getItem('COGNITO_USER')).username})).then(res => {
        
        if (res.data.getKTACustomer){
        
          dispatch(refreshProfile(res.data.getKTACustomer));
          listAllKTAEmployees();
          setSpin(false);
        } else{

          setSpin(false);

        }
      }).catch(e => {
        console.warn(e);
        setSpin(false);
      });
    } else {
      setSpin(false);
    }
    
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Perform logout logic here, like sending a logout request to the server.
      // Example: callLogoutApi();
      
      // Some browsers might require a return value to display a confirmation dialog.
      window.localStorage.removeItem('COGNITO_USER');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    // add subscriptions here
      // Subscribe to Update of Customers
// const sub1 = API.graphql(
//   graphqlOperation(subscriptions.onUpdateKTACustomer)
// ).subscribe({
//   next: ({ provider, value }) => {
//     if (value.data.onUpdateKTACustomer.id === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username ){
//      API.graphql(graphqlOperation(getKTACustomer, {id: JSON.parse(window.localStorage.getItem('COGNITO_USER')).username})).then(res => {
//     dispatch(refreshProfile(res.data.getKTACustomer))
//     }).catch(e => {
//       console.warn("Profile NOT Refreshed : Failed in App.js Subscription for update customer");
//       console.warn(e);
      
//     });
//     } 
//   },
  
//   error: (error) => console.warn(error)
// });
  
// const sub2 = API.graphql(
//   graphqlOperation(subscriptions.onCreateKTADependant)
// ).subscribe({
//   next: ({ provider, value }) => {
// if (value.data.onCreateKTADependant.kTACustomerDependantsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onCreateKTADependant.kTACustomerDependantsId})).then(res => {
    
      
//         // console.log(res.data.getKTACustomer);
//         dispatch(refreshProfile(res.data.getKTACustomer));
    
//     }).catch(e => {
//       console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
      
//     });
//   }

//     }
//     ,
//   error: (error) => console.warn(error)
// });
  
// const sub3 = API.graphql(
//   graphqlOperation(subscriptions.onUpdateKTADependant)
// ).subscribe({
//   next: ({ provider, value }) => {
//     if (value.data.onUpdateKTADependant.kTACustomerDependantsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onUpdateKTADependant.kTACustomerDependantsId})).then(res => {
    
      
//         // console.log(res.data.getKTACustomer);
//         dispatch(refreshProfile(res.data.getKTACustomer));
    
//     }).catch(e => {
//       console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
      
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });
  

// const sub4 = API.graphql(
//   graphqlOperation(subscriptions.onDeleteKTADependant)
// ).subscribe({
//   next: ({ provider, value }) => {
//     if (value.data.onDeleteKTADependant.kTACustomerDependantsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onDeleteKTADependant.kTACustomerDependantsId})).then(res => {
    
      
//         // console.log(res.data.getKTACustomer);
//         dispatch(refreshProfile(res.data.getKTACustomer));
    
//     }).catch(e => {
//       console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
      
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });
  
// const sub5 = API.graphql(
//   graphqlOperation(subscriptions.onDeleteKTAOccupation)
// ).subscribe({
//   next: ({ provider, value }) => {
//     if (value.data.onDeleteKTAOccupation.kTACustomerOccupationsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onDeleteKTAOccupation.kTACustomerOccupationsId})).then(res => {
    
      
//         // console.log(res.data.getKTACustomer);
//         dispatch(refreshProfile(res.data.getKTACustomer));
    
//     }).catch(e => {
//       console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
      
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });
  
// const sub6 = API.graphql(
//   graphqlOperation(subscriptions.onCreateKTAOccupation)
// ).subscribe({
//   next: ({ provider, value }) => {
//     if (value.data.onCreateKTAOccupation.kTACustomerOccupationsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onCreateKTAOccupation.kTACustomerOccupationsId})).then(res => {
    
      
//         // console.log(res.data.getKTACustomer);
//         dispatch(refreshProfile(res.data.getKTACustomer));
    
//     }).catch(e => {
//       console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
      
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub7 = API.graphql(
//   graphqlOperation(subscriptions.onCreateKTAInvestmentProperty)
// ).subscribe({
//   next: ({ provider, value }) => {
//     if (value.data.onCreateKTAInvestmentProperty.kTACustomerInvestmentpropertiesId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onCreateKTAInvestmentProperty.kTACustomerInvestmentpropertiesId})).then(res => {
    
      
//         // console.log(res.data.getKTACustomer);
//         dispatch(refreshProfile(res.data.getKTACustomer));
    
//     }).catch(e => {
//       console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
      
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub8 = API.graphql(
//   graphqlOperation(subscriptions.onUpdateKTAInvestmentProperty)
// ).subscribe({
//   next: ({ provider, value }) => {
//     if (value.data.onUpdateKTAInvestmentProperty.kTACustomerInvestmentpropertiesId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onUpdateKTAInvestmentProperty.kTACustomerInvestmentpropertiesId})).then(res => {
    
      
//         // console.log(res.data.getKTACustomer);
//         dispatch(refreshProfile(res.data.getKTACustomer));
    
//     }).catch(e => {
//       console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
      
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub9 = API.graphql(  graphqlOperation(subscriptions.onDeleteKTAInvestmentProperty)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onDeleteKTAInvestmentProperty.kTACustomerInvestmentpropertiesId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onDeleteKTAInvestmentProperty.kTACustomerInvestmentpropertiesId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });


// const sub10 = API.graphql(  graphqlOperation(subscriptions.onCreateKTACompany)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onCreateKTACompany.kTACustomerCompaniesId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onCreateKTACompany.kTACustomerCompaniesId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub11 = API.graphql(  graphqlOperation(subscriptions.onDeleteKTACompany)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onDeleteKTACompany.kTACustomerCompaniesId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onDeleteKTACompany.kTACustomerCompaniesId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub12 = API.graphql(  graphqlOperation(subscriptions.onCreateKTATrust)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onCreateKTATrust.kTACustomerTrustsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onCreateKTATrust.kTACustomerTrustsId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub13 = API.graphql(  graphqlOperation(subscriptions.onDeleteKTATrust)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onDeleteKTATrust.kTACustomerTrustsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onDeleteKTATrust.kTACustomerTrustsId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub14 = API.graphql(  graphqlOperation(subscriptions.onCreateKTABankInterest)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onCreateKTABankInterest.kTACustomerBankinterestsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onCreateKTABankInterest.kTACustomerBankinterestsId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub15 = API.graphql(  graphqlOperation(subscriptions.onDeleteKTABankInterest)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onDeleteKTABankInterest.kTACustomerBankinterestsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onDeleteKTABankInterest.kTACustomerBankinterestsId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub16 = API.graphql(  graphqlOperation(subscriptions.onCreateKTADividend)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onCreateKTADividend.kTACustomerDividendsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onCreateKTADividend.kTACustomerDividendsId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

// const sub17 = API.graphql(  graphqlOperation(subscriptions.onDeleteKTADividend)
// ).subscribe({  next: ({ provider, value }) => {
//   if(value.data.onDeleteKTADividend.kTACustomerDividendsId === JSON.parse(window.localStorage.getItem('COGNITO_USER')).username){
//     API.graphql(graphqlOperation(getKTACustomer, {id: value.data.onDeleteKTADividend.kTACustomerDividendsId})).then(res => {
//       dispatch(refreshProfile(res.data.getKTACustomer));
//     }).catch(e => {  console.warn("Profile NOT Refreshed : Failed in App.js Subscription for create dependent");
//       console.warn(e);
//     });
//   }
//     }
//     ,
//   error: (error) => console.warn(error)
// });

return () => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
  // sub1.unsubscribe();
  // sub2.unsubscribe();
  // sub3.unsubscribe();
  // sub4.unsubscribe();
  // sub5.unsubscribe();
  // sub6.unsubscribe();
  // sub7.unsubscribe();
  // sub8.unsubscribe();
  // sub9.unsubscribe();
  // sub10.unsubscribe();
  // sub11.unsubscribe();
  // sub12.unsubscribe();
  // sub13.unsubscribe();
  // sub14.unsubscribe();
  // sub15.unsubscribe();
  // sub16.unsubscribe();
  // sub17.unsubscribe();

};
// return () => sub2.unsubscribe();
// return () => sub3.unsubscribe();
// return () => sub4.unsubscribe();
// return () => sub5.unsubscribe();
// return () => sub6.unsubscribe();
// return () => sub7.unsubscribe();
// return () => sub8.unsubscribe();
// return () => sub9.unsubscribe();
// return () => sub10.unsubscribe();
// return () => sub11.unsubscribe();


// return () => sub12.unsubscribe();
// return () => sub13.unsubscribe();
// return () => sub14.unsubscribe();
// return () => sub15.unsubscribe();
// return () => sub16.unsubscribe();
// return () => sub17.unsubscribe();


  }, []);

  function signOut () {
    Auth.signOut().then(res =>{
      
    }).catch(e=> console.warn(e))
    
  }


    //listKTAEmployees untill NextToken is null
    //dispatch(refreshEmployees(res.data.listKTAEmployees.items));
    const listAllKTAEmployees = async () => {
      let nextToken = null;
      let allItems = [];
    
      try {
        do {
          const response = await API.graphql(graphqlOperation(listKTAEmployees, { nextToken }));
          const { items, nextToken: newNextToken } = response.data.listKTAEmployees;
    
          allItems = allItems.concat(items);
          nextToken = newNextToken;
        } while (nextToken !== null);
    
        dispatch(refreshEmployees(allItems));
        
      } catch (error) {
        console.warn(error);
      }
    };




 


  
  // code for spinner modal
  const [spin, setSpin] = useState(false);
  
  return (
    <div>
      <Router>  
        
        <Switch>
          <Route exact path="/login">      <div> <ResponsiveAppBar signout={signOut}/>        <Login/>     </div>      </Route>
          <Route exact path="/forgotpassword">  <div> <ResponsiveAppBar signout={signOut}/>            <ForgotPassword/>     </div>      </Route>

          <Route path="/home" exact > {user? <div> <ResponsiveAppBar signout={signOut}/> <Home/> </div>: <Login/>} </Route>
          <Route path="/forms" exact > {user?<div> <ResponsiveAppBar signout={signOut}/>  <Forms/> </div>: <Login/>}</Route>
          <Route path="/payments" exact > {user? <div> <ResponsiveAppBar signout={signOut}/> <Payments/></div>: <Login/>} </Route>
          <Route path="/taxaccounts" exact > {user?<div> <ResponsiveAppBar signout={signOut}/>  <TaxAccounts/></div>: <Login/>} </Route>
          <Route path="/documents" exact > {user?<div> <ResponsiveAppBar signout={signOut}/>  <Documents/></div>: <Login/>} </Route>
          <Route path="/profile" exact > {user? <div> <ResponsiveAppBar signout={signOut}/> <PersonalProfile/> </div>: <Login/>}</Route>
          <Route path="/account-settings" exact > {user? <div> <ResponsiveAppBar signout={signOut}/> <AccountSettings/> </div>: <Login/>}</Route>
          <Route path="/thankyou" exact > {user? <div>  <ThankYou/> </div>: <Login/>}</Route>
          <Route path="/personalprofile" exact > {user? 
                    <div>
                          <PersonalDetails/>
                          <SpouseDetails/>
                          <DependentsDetails/>
                    </div>: <Login/>}
            </Route>
            {/* <Route path="/onboard" exact > {user? <div>
              
              <GeneralOnboarding signout={signOut}/>
              </div> : <Login/>}
            </Route> */}
            <Route path="/onboard" exact > { <div>
              
              <NewOnboarding user={user} signout={signOut}/>
              </div> }
            </Route>


          <Route path="/" exact > {user? <div> <ResponsiveAppBar signout={signOut} /> <Home/> </div>: <Login/>} </Route>

          
        </Switch>
        <StickyFooter/>
      </Router>
      <WarningModal warningflag={warningflag} warningheader={warningheader} warningtext={warningtext} resetwarningflag={resetwarningflag} />
      <ResponseModal warningflag={responseflag} warningheader={responseheader} warningtext={responsetext} resetwarningflag={resetresopnseflag} />
      <ConfirmationModal  warningflag={confirmFlag} warningheader={confirmHeader} warningtext={confirmText} resetwarningflag={resetresopnseflag} />
      <SpinnerModal spin={spin}/>
    </div>
  );
}

export default App;
