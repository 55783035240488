import React from 'react';
// import { makeStyles } from '@mui/material';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,

} from '@mui/material';
import { Auth } from 'aws-amplify';



const AccountSettings = () => {
//   const classes = useStyles();

function changePassword(){

    setSuccessMessage("");
    setErrorMessage("");


    Auth.currentAuthenticatedUser()  
    .then((user) => {
     Auth.changePassword(user, 'oldPassword', 'newPassword')
     .then(() => {
        setSuccessMessage("Password Changed Successfully")
    })
    .catch((err) => {
        setErrorMessage(err.message)
    })
    })
  .catch((err) => setErrorMessage(err.message));
}

const [oldPassword, setoldPassword] = React.useState("");
const [newPassword, setnewPassword] = React.useState("");
const [successMessage, setSuccessMessage] = React.useState("");
const [errorMessage, setErrorMessage] = React.useState("");

  return (
    <Container maxWidth="sm" >
        <br />
      <Typography variant="h5" gutterBottom>
        Account Settings
      </Typography>
      <Typography variant="h6" gutterBottom>
        Change Password
      </Typography>
      {successMessage? <div className="w3-panel w3-pale-green">
        <p>{successMessage}</p>
      </div>:<></>}
      {errorMessage? <div className="w3-panel w3-pale-red">
        <p>{errorMessage}</p>
      </div>:<></> }
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField label="Old Password" 
            type='password'
            fullWidth 
            value={oldPassword} 
            onChange={(e)=>setoldPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="New Password" 
            fullWidth
            type='password'
            value={newPassword}
            onChange={(e)=>setnewPassword(e.target.value)}
             />
          </Grid>
         
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={changePassword}
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AccountSettings;
