import { API, graphql, graphqlOperation } from 'aws-amplify';
import { createKTADependant,updateKTACustomer,updateKTADependant, deleteKTADependant } from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import React from 'react';
import { FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material';
import DpendentCard from './DependentCard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import { getKTACustomer, getKTADependant, listKTACustomers } from '../../graphql/queries';
import { updateDependentDetailFlag } from '../Features/detailschangedflags';
import { useEffect } from 'react';
import WarningModal from '../WarningModal';
import { gblRefreshProfile, useRefreshProfile } from '../../GlobalVariables/GlobalFunctions';
import { addProfileDependent, removeProfileDependent } from '../Features/profile';
import PersonAddIcon from '@mui/icons-material/PersonAdd';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const DependentsDetails = () => {
    
var dependentslist = [];
const dispatch = useDispatch();
const profile = useSelector(state => state.profile.value);
const dcflags = useSelector(state => state.dcflags.value);
const [dependents, setDependents] = React.useState([]);
const [warningflag, setWarningFlag] = React.useState(false);
const [warningheader, setwarningheader] = React.useState("");
const [warningtext, setwarningtext] = React.useState("");

// Modal related 
const [open, setOpen] = React.useState(false);
// Data collectted in modal
const [depName, setdepName] = React.useState("");
const [depRelation, setdepRelation] = React.useState("");
const [depAge, setdepAge] = React.useState("");
// Modal Functions
const handleOpen = () => {
    setOpen(true);
    setdepAge("");
    setdepName("");
    setdepRelation("");
}

function resetwarningflag(){

    setWarningFlag(false);
    setwarningheader("");
    setwarningtext("");
}

useEffect(() => {
    if (profile.id){
      setDependents(profile.dependants.items);
    }
  }, [profile]);



const handleCreate = () => {
    setOpen(false);
      var newDependant = {
          relation: "Relationship "+depRelation,
          name: depName,
          age: depAge+" years old",
          kTACustomerDependantsId: profile.id

      };
    

      API.graphql(graphqlOperation(createKTADependant, {input: newDependant})).then(res => {
        console.log("Dependant Created", res);
        dispatch(addProfileDependent(res.data.createKTADependant));
        
      
      })
      .catch(e => {
        console.warn(e);
        setWarningFlag(true);
        setwarningheader("Error Creating Dependent");
        setwarningtext("Please contact Karvis to get Support.");

      }
      );


}
const handleModalLooseFocus = () => {
    setOpen(false);
}
  

    const delteItem = (itemid) =>{
        
        console.log("Delete Item", itemid);
        API.graphql(graphqlOperation(deleteKTADependant, {input: {id: itemid}})).then(res => {
            console.log("Dependant Deleted", res);
            dispatch(removeProfileDependent(itemid));
            
            
        });
        
    }
  
    return ( <div className="w3-padding w3-container"> 
    <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>My Dependents:</b> </Typography>
    <div className="w3-container"> 
    <Button variant="contained" endIcon={<PersonAddIcon />} onClick={handleOpen}>  CLICK TO ADD DEPENDENTS </Button>
    <br />
    <br />
    </div>   
        {  dependents&& dependents.map(dependent => (
            <DpendentCard key={dependent.id} id={dependent.id} relationship={dependent.relationship} depname={dependent.name} age={dependent.age} callBackFunction={delteItem}/>
            
        ))}

        <hr />
        
    
      <Modal
        open={open}
        onClose={handleModalLooseFocus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Dependent
          </Typography>
          <TextField
          label="Dependent Name"
          fullWidth
          margin='dense'
          value={depName}
          onChange={(e)=> setdepName( e.target.value)}
        />
       <FormControl fullWidth margin='normal'>
       <Typography variant='caption'>Age</Typography>
       <Select
           labelId="demo-simple-select-label"
           id="demo-simple-select"
           value={depAge}
           label="AGE"
           onChange={(e)=> setdepAge(e.target.value)}
       >
           <MenuItem value={"1-13"}>Below 13 years</MenuItem>
           <MenuItem value={"14-18"}>14 to 18 years</MenuItem>
           <MenuItem value={"18-25"}>18-25 years</MenuItem>
           <MenuItem value={"25-50"}>25 to 50 years</MenuItem>
           <MenuItem value={"50 above"}>Above 50 years</MenuItem>
       </Select>
       </FormControl>

      <TextField
          label="Dependent Relation"
          fullWidth
          margin='dense'
          value={depRelation}
          onChange={(e)=> setdepRelation( e.target.value)}
        />
        <Button variant="contained" onClick={handleCreate}>Create</Button>
        </Box>
      </Modal>
<WarningModal warningflag={warningflag} warningheader={warningheader} warningtext={warningtext} resetwarningflag={resetwarningflag} />

    </div> );
};
 
export default DependentsDetails;

