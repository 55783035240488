import { API, graphqlOperation } from 'aws-amplify';
import { updateKTACustomer } from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import {updateProfileFields} from '../Features/profile';

import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from 'react';




const IncomeTypes = () => {
    
    const profile = useSelector(state => state.profile.value);
    const dcflags = useSelector(state => state.dcflags.value);
    const dispatch = useDispatch();

    const [validationError, setValidationError] = useState(false);
    const [salaried_flag, setsalaried_flag] = useState("");
    const [soletrader_flag, setsoletrader_flag] = useState("");
    const [acn_flag, setacn_flag] = useState("");
    const [trust_flag, settrust_flag] = useState("");
    const [investmentproperty_flag, setinvestmentproperty_flag] = useState("");
    const [bankinterest_flag, setbankinterest_flag] = useState("");
    const [dividends_flag, setdividends_flag] = useState("");

    useEffect(() => {
        if (profile){
            setsalaried_flag(profile.salaried_flag?profile.salaried_flag:false);
            setsoletrader_flag(profile.soletrader_flag?profile.soletrader_flag:false);
            setacn_flag(profile.acn_flag?profile.acn_flag:"");
            settrust_flag(profile.trust_flag?profile.trust_flag:"");
            setinvestmentproperty_flag(profile.investmentproperty_flag?profile.investmentproperty_flag:false);
            setbankinterest_flag(profile.bankinterest_flag?profile.bankinterest_flag:false);
            setdividends_flag(profile.dividend_flag?profile.dividend_flag:false);
        }   
    }, [profile]);
            
    function handleSave(){
      let  inputdata = {
            id: profile.id,
            salaried_flag: salaried_flag,
            soletrader_flag: soletrader_flag,
            acn_flag: acn_flag,
            trust_flag: trust_flag,
            investmentproperty_flag: investmentproperty_flag,
            bankinterest_flag: bankinterest_flag,
            dividend_flag: dividends_flag
        }
        API.graphql(graphqlOperation(updateKTACustomer, {input: inputdata})).then(res => {
            console.log(res.data.updateKTACustomer);
        }).catch(e => {
            console.warn(e);
        });
    }

   function handleChangeSalariedFlag(e){
        let  inputdata = {
            id: profile.id,
            salaried_flag: e.target.value,
            soletrader_flag: soletrader_flag,
            acn_flag: acn_flag,
            trust_flag: trust_flag,
            investmentproperty_flag: investmentproperty_flag,
            bankinterest_flag: bankinterest_flag,
            dividend_flag: dividends_flag
        }
        API.graphql(graphqlOperation(updateKTACustomer, {input: inputdata})).then(res => {
            setsalaried_flag(e.target.value);
            dispatch(updateProfileFields({salaried_flag: e.target.value}));
        }).catch(e => {
            console.warn(e);
        });
        
    }

   function handleChangeSoleTraderFlag(e){
    let  inputdata = {
        id: profile.id,
        salaried_flag: salaried_flag,
        soletrader_flag: e.target.value,
        acn_flag: acn_flag,
        trust_flag: trust_flag,
        investmentproperty_flag: investmentproperty_flag,
        bankinterest_flag: bankinterest_flag,
        dividend_flag: dividends_flag
    }
    API.graphql(graphqlOperation(updateKTACustomer, {input: inputdata})).then(res => {
        setsoletrader_flag(e.target.value);
        dispatch(updateProfileFields({soletrader_flag: e.target.value}));
    }).catch(e => {
        console.warn(e);
    });
        
        
    }

  function  handleChangeACNFlag(e){
    let  inputdata = {
        id: profile.id,
        salaried_flag: salaried_flag,
        soletrader_flag: soletrader_flag,
        acn_flag: e.target.value,
        trust_flag: trust_flag,
        investmentproperty_flag: investmentproperty_flag,
        bankinterest_flag: bankinterest_flag,
        dividend_flag: dividends_flag
    }
    API.graphql(graphqlOperation(updateKTACustomer, {input: inputdata})).then(res => {
        setacn_flag(e.target.value);
        dispatch(updateProfileFields({acn_flag: e.target.value}));
    }).catch(e => {
        console.warn(e);
    });
        
       
    }

  function  handleChangeTrustFlag(e){
    let  inputdata = {
        id: profile.id,
        salaried_flag: salaried_flag,
        soletrader_flag: soletrader_flag,
        acn_flag: acn_flag,
        trust_flag: e.target.value,
        investmentproperty_flag: investmentproperty_flag,
        bankinterest_flag: bankinterest_flag,
        dividend_flag: dividends_flag
    }
    API.graphql(graphqlOperation(updateKTACustomer, {input: inputdata})).then(res => {
        settrust_flag(e.target.value);
        dispatch(updateProfileFields({trust_flag: e.target.value}));
    }).catch(e => {
        console.warn(e);
    });
       
        
    }

  function  handleChangeInvestmentPropertyFlag(e){
    let  inputdata = {
        id: profile.id,
        salaried_flag: salaried_flag,
        soletrader_flag: soletrader_flag,
        acn_flag: acn_flag,
        trust_flag: trust_flag,
        investmentproperty_flag: e.target.value,
        bankinterest_flag: bankinterest_flag,
        dividend_flag: dividends_flag
    }
    API.graphql(graphqlOperation(updateKTACustomer, {input: inputdata})).then(res => {
        setinvestmentproperty_flag(e.target.value);
        dispatch(updateProfileFields({investmentproperty_flag: e.target.value}));
    }).catch(e => {
        console.warn(e);
    });
        
        
    }

  function  handleChangeBankInterestFlag(e){
    let  inputdata = {
        id: profile.id,
        salaried_flag: salaried_flag,
        soletrader_flag: soletrader_flag,
        acn_flag: acn_flag,
        trust_flag: trust_flag,
        investmentproperty_flag: investmentproperty_flag,
        bankinterest_flag: e.target.value,
        dividend_flag: dividends_flag
    }
    API.graphql(graphqlOperation(updateKTACustomer, {input: inputdata})).then(res => {
        setbankinterest_flag(e.target.value);
        dispatch(updateProfileFields({bankinterest_flag: e.target.value}));
    }).catch(e => {
        console.warn(e);
    });
        
        
    }

   function handleChangeDividendsFlag(e){
    let  inputdata = {
        id: profile.id,
        salaried_flag: salaried_flag,
        soletrader_flag: soletrader_flag,
        acn_flag: acn_flag,
        trust_flag: trust_flag,
        investmentproperty_flag: investmentproperty_flag,
        bankinterest_flag: bankinterest_flag,
        dividend_flag: e.target.value
    }
    API.graphql(graphqlOperation(updateKTACustomer, {input: inputdata})).then(res => {
        setdividends_flag(e.target.value);
        dispatch(updateProfileFields({dividend_flag: e.target.value}));
    }).catch(e => {
        console.warn(e);
    });
        
        
    }




    return ( <div className='w3-container'>
        {/* {detailsChanged?<Button variant="contained" color='secondary' onClick={handleSave}>Save This Section</Button>:<></>} */}
            <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>My Sources of Income:</b> </Typography>
            <FormControl fullWidth margin='normal'>
                
                <Typography variant="body-1" id="">Are you Salaried Employee?</Typography>
                <Select
                       value={salaried_flag}
                       onChange={handleChangeSalariedFlag}
                    //    onBlur={handleSave}
                    //    onChange={(e)=> props.dispatch({type: 'SALARYSTATUS_CHANGED', value: e.target.value})}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>

                </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                <Typography variant="body-1"  id="">Are you Sole Trader?</Typography>
                <Select
                        value={soletrader_flag}
                        onChange={handleChangeSoleTraderFlag}
                        // onBlur={handleSave}
                    //    onChange={(e)=> props.dispatch({type: 'SOLETRADER_CHANGED', value: e.target.value})}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                <Typography variant="body-1"  id="">Do you run business as ACN ?</Typography>
                <Select
                        value={acn_flag}
                        onChange={handleChangeACNFlag}
                        // onBlur={handleSave}
                    //    onChange={(e)=> props.dispatch({type: 'ACN_CHANGED', value: e.target.value})}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                <Typography variant="body-1"  id="">Do you have a trust?</Typography>
                <Select
                       value={trust_flag}
                       onChange={handleChangeTrustFlag}
                    //    onBlur={handleSave}
                    //    onChange={(e)=> props.dispatch({type: 'TRUST_CHANGED', value: e.target.value})}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                <Typography variant="body-1"  id="">Do you have Investment Property?</Typography>
                <Select
                       value={investmentproperty_flag}
                       onChange={handleChangeInvestmentPropertyFlag}
                    //    onBlur={handleSave}
                    //    onChange={(e)=> props.dispatch({type: 'INVPROP_CHANGED', value: e.target.value})}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                <Typography variant="body-1"  id="">Do you earn interest from bank?</Typography>
                <Select
                       value={bankinterest_flag}
                       onChange={handleChangeBankInterestFlag}
                    //    onBlur={handleSave}
                    //    onChange={(e)=> props.dispatch({type: 'BANKINT_CHANGED', value: e.target.value})}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                <Typography variant="body-1"  id="">Do you get dividends from shares?</Typography>
                <Select
                        value={dividends_flag}
                        onChange={handleChangeDividendsFlag}
                        // onBlur={handleSave}
                    //    onChange={(e)=> props.dispatch({type: 'DIVIDENDS_CHANGED', value: e.target.value})}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
                </FormControl>
                {/* {detailsChanged?<Button variant="contained" color='secondary' onClick={handleSave}>Save This Section</Button>:<></>} */}
    </div> );
}
 
export default IncomeTypes;