import { API, graphqlOperation } from 'aws-amplify';
import { updateKTACustomer } from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';

import {updatePersonalFields} from '../Features/profile';

import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';



const ElectronicBankingDetails = (props) => {

const dispatch = useDispatch();
const profile = useSelector(state => state.profile.value);
const dcflags = useSelector(state => state.dcflags.value);

const [validationError, setValidationError] = useState(false);
const [bsb, setbsb] = useState("");
const [bankaccount, setbankaccount] = useState("");

const [detailsChanged, setDetailsChanged] = useState(false);
useEffect(() => {
  if (profile){
    setbsb(profile.bank_bsb?profile.bank_bsb:"");
    setbankaccount(profile.bank_account?profile.bank_account:"");
  }
}, [profile]);


function handleChangeBSB(e){
  setDetailsChanged(true);
  setbsb(e.target.value);
}

function handleChangeAccount(e){
  setDetailsChanged(true);
  setbankaccount(e.target.value);
}




function handleSave(){  

  if (( isNaN(bsb) || bsb.length!==6)||( isNaN(bankaccount))){
    setValidationError(true);
    
  } else{
    setValidationError(false);
    API.graphql(graphqlOperation(updateKTACustomer, {input: {id: profile.id, bank_bsb: bsb, bank_account: bankaccount}})).then(res => {
      
      dispatch(updatePersonalFields({bank_bsb: bsb, bank_account: bankaccount}));
      setDetailsChanged(false);
      
    }).catch(e => {
      console.warn(e);
   
    });
  }
}

    return ( <div className="w3-padding w3-container"> <br />
         
         <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>Banking details where your tax will be deposited</b> </Typography>
        <TextField
          error={false}
          id="bsb"
          label="BSB"
          helperText={""}
          fullWidth
          margin='dense'
          value={bsb}
          onChange={handleChangeBSB}
          // onBlur={handleSave}
        />
        <TextField
          error={false}
          id="bankaccount"
          label="Bank Account"
          helperText={""}
          fullWidth
          margin='dense'
          value={bankaccount}
          onChange={handleChangeAccount}
          // onBlur={handleSave}
        />
        {validationError&&<Typography  variant='button' color={'error'} >Please enter valid BSB and Account No.</Typography>}
        {detailsChanged?<Button variant="contained" color='secondary' onClick={handleSave}>Save This Section</Button>:<></>}
    </div> );
};
 
export default ElectronicBankingDetails;
