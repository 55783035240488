import { API, graphql, graphqlOperation } from 'aws-amplify';
import { createKTACompany, deleteKTACompany} from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { addProfileCompany, removeProfileCompany } from '../Features/profile';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import {  Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ReusableCard from './ReusableCard';
import WorkIcon from '@mui/icons-material/Work';
import OccupationCard from './Occupationcard';
import CompanyCard from './CompanyCard';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const CompanyDetails = () => {
    
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile.value);
    
    // Modal related 
    const [open, setOpen] = React.useState(false);
    // Data collectted in modal
    const [companies, setCompaniesList] = React.useState([{name: '', abn: '', acn: ''}]);
    const [cName, setcName] = React.useState("");
    const [cAcn, setACN] = React.useState([]);
    const [cABN, setABN] = React.useState([]);

    useEffect(() => {
        if (profile){
            if (profile.companies){
              setCompaniesList(profile.companies.items);
            }
        }
    }, [profile]);

    // Modal Functions
    const handleOpen = () => {
        setOpen(true);
        setcName("");
        setACN("");
        setABN("");
        
    }

    const handleCreate = () => {
        setOpen(false);
        var newCompany = {
            name: cName,
            acn: cAcn,
            abn: cABN,
            kTACustomerCompaniesId: profile.id,
        };
        API.graphql(graphqlOperation(createKTACompany, {input: newCompany})).then(res => {
            console.log(res.data.createKTACompany);
            dispatch(addProfileCompany(res.data.createKTACompany));
        }).catch(e => {
            console.warn(e);
        }
        );
        
    }

    const handleModalLooseFocus = () => {
        setOpen(false);
    }
    

        const delteItem = (item) =>{
            
          API.graphql(graphqlOperation(deleteKTACompany, {input: {id: item.id}})).then(res => {
              
              dispatch(removeProfileCompany(item.id));
          }).catch(e => {
              console.warn(e);

          });
            
        }

    return ( <div>
      {JSON.parse(profile.acn_flag)?
    <div className='w3-container'>
      <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>My Company Details:</b> </Typography>
       {/* {props.state.incomesources.salary ? <div className="w3-padding">  */}
       <div className="w3-padding"> 
    <div className="w3-container"> 
    <Button variant="contained" endIcon={<WorkIcon />} onClick={handleOpen}> Click to Add Company</Button>
    <br />  <br />
    </div>   
        {   companies.map(company => (
            <CompanyCard key={company.id} company={company} callBackFunction={delteItem}/>
        ))}
            <br />
        
        
    
      <Modal
        open={open}
        onClose={handleModalLooseFocus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2"> 
            Add Company
          </Typography>
          <TextField
          label="Company Name"
          fullWidth
          margin='dense'
          value={cName}
          onChange={(e)=> setcName( e.target.value)}
        />
        <TextField
          label="ABN"
          fullWidth
          margin='dense'
          value={cABN}
          onChange={(e)=> setABN( e.target.value)}
        />
        <TextField
          label="ACN"
          fullWidth
          margin='dense'
          value={cAcn}
          onChange={(e)=> setACN( e.target.value)}
        />
         <br />
        <br />
        <Typography variant='body2' color='secondary'> We may be asking you to email us some documents for tax return calculations. </Typography>
        <br />
        <Button variant="contained" onClick={handleCreate}>Submit</Button>
        </Box>
      </Modal>


    </div>
    </div> :<></>}
    </div>
    );
}
 
export default CompanyDetails;

