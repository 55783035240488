
import * as React from 'react';
import Card from '@mui/material/Card';
import { CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


const OccupationCard = (props) => {

    const [occupation, setOccupation] = React.useState(props.occupation);
    


    const handleDelete =() =>{
        props.callBackFunction(occupation)
    }

    return ( <div>

<Card className= 'w3-border' sx={{ minWidth: 275 }}>
<CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        }
        title={props.occupation.name}
        subheader={props.occupation.employer}
      />
       <CardContent>
        
        <Typography variant="button"  > Tax Witheld: {props.occupation.taxwitheld}</Typography>
        <Typography variant="button"  > Gross Payments: {props.occupation.grosspayment}</Typography>
        <Typography variant="button"  > Taxable Income: {props.occupation.taxableincome}</Typography>
        
      </CardContent>
    </Card>

    </div> );
}
 
export default OccupationCard;