import { API, graphql, graphqlOperation } from 'aws-amplify';
import { createKTAInvestmentProperty,deleteKTAInvestmentProperty,updateKTAInvestmentProperty } from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import {  Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ReusableCard from './ReusableCard';
import HouseIcon from '@mui/icons-material/House';
import InvestmentPropertyCard from './InvestmentPropertyCard';

import { addProfileProperty, removeProfileProperty } from '../Features/profile';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const InvestmentPropertyDetails = () => {
    const profile = useSelector(state => state.profile.value);
    const dispatch = useDispatch();
    
    const [invPropList, setInvPropList] = React.useState([]);
    const [IPaddress, setAddress] = React.useState("");

    useEffect(() => {
        if (profile.investmentproperties){
            setInvPropList(profile.investmentproperties.items);
        }
    }, [profile.investmentproperties])

    
    // Modal related 
    const [open, setOpen] = React.useState(false);
    
    // Modal Functions
    const handleOpen = () => {
        setOpen(true);
        //!change here
        setAddress("");
    }

    
    const handleCreate = () => {
        setOpen(false);
        //!change Here
        var newIP = {
            address: IPaddress,
            kTACustomerInvestmentpropertiesId: profile.id,
        }
        API.graphql(graphqlOperation(createKTAInvestmentProperty, {input: newIP})).then(res => {
            console.log(res);
            dispatch(addProfileProperty(res.data.createKTAInvestmentProperty));
        }).catch(e => {
            console.warn(e);
        })
        
    }
    const handleModalLooseFocus = () => {
        setOpen(false);
    }
    

    const delteItem = (item) =>{
        
        API.graphql(graphqlOperation(deleteKTAInvestmentProperty, {input: {id: item}})).then(res => {
            
            dispatch(removeProfileProperty(item));
            
        }).catch(e => {
            console.warn(e);
        })
        
    }

    

    return ( 
        <div>
    {JSON.parse(profile.investmentproperty_flag)?
    <div className='w3-container'>
                    
        <div className="w3-padding"> 
    <div className="w3-container"> 
    
    </div>   
    <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>My Investment Property Details:</b> </Typography>
    <Button variant="contained" endIcon={<HouseIcon />} onClick={handleOpen}>  CLICK TO ADD Property </Button>
    <br /> <br />
        {   invPropList.map(property => (   
            <InvestmentPropertyCard key={property.id} property={property} callBackFunction={delteItem}/>
        ))}
            <br />

        
      
      <Modal
        open={open}
        onClose={handleModalLooseFocus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2"> 
            Add Investment Property
          </Typography>
          <TextField
          label="Property Address"
          fullWidth
          margin='dense'
          value={IPaddress}
          onChange={(e)=> setAddress( e.target.value)}
        /> 
         <br />
       
        <Button variant="contained" onClick={handleCreate}>Submit</Button>
        </Box>
      </Modal>


    </div>
    </div>    :<></>}
    </div>
    );
}

 
export default InvestmentPropertyDetails;