import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;
export const workitemSlice = createSlice({
    name: "workitems",
    initialState: { value: initialStateValue },
    reducers: {
        refreshworkitems: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { refreshworkitems } = workitemSlice.actions;
export default workitemSlice.reducer;