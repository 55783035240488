import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import React from "react";

const StickyFooter = () => {
    const [value, setValue] = React.useState(0);
    return ( <div>
        {/* <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}> */}
        
        <BottomNavigation
        
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Privacy Policy"   />
          <BottomNavigationAction label="Terms and Conditons" />
          <BottomNavigationAction label="Contact"  />
        </BottomNavigation>
      {/* </Paper> */}
    </div> );
}
 
export default StickyFooter;