import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;
export const tempDetailsSlice = createSlice({
    name: "tempDetails",
    initialState: { value: initialStateValue },
    reducers: {
        saveTempDetails: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { saveTempDetails } = tempDetailsSlice.actions;
export default tempDetailsSlice.reducer;