/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createKTACustomer = /* GraphQL */ `
  mutation CreateKTACustomer(
    $input: CreateKTACustomerInput!
    $condition: ModelKTACustomerConditionInput
  ) {
    createKTACustomer(input: $input, condition: $condition) {
      id
      email
      phone
      fname
      lname
      tfn
      address
      dob
      description
      slug
      maritalstatuschanged
      bank_bsb
      bank_account
      salaried_flag
      soletrader_flag
      acn_flag
      trust_flag
      investmentproperty_flag
      bankinterest_flag
      dividend_flag
      taxaccounts {
        nextToken
        __typename
      }
      privileges {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      dependants {
        nextToken
        __typename
      }
      occupations {
        nextToken
        __typename
      }
      investmentproperties {
        nextToken
        __typename
      }
      bankinterests {
        nextToken
        __typename
      }
      dividends {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      trusts {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      engagingtaxagent
      spousename
      spousetfn
      spouseincome
      spousedob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKTACustomer = /* GraphQL */ `
  mutation UpdateKTACustomer(
    $input: UpdateKTACustomerInput!
    $condition: ModelKTACustomerConditionInput
  ) {
    updateKTACustomer(input: $input, condition: $condition) {
      id
      email
      phone
      fname
      lname
      tfn
      address
      dob
      description
      slug
      maritalstatuschanged
      bank_bsb
      bank_account
      salaried_flag
      soletrader_flag
      acn_flag
      trust_flag
      investmentproperty_flag
      bankinterest_flag
      dividend_flag
      taxaccounts {
        nextToken
        __typename
      }
      privileges {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      dependants {
        nextToken
        __typename
      }
      occupations {
        nextToken
        __typename
      }
      investmentproperties {
        nextToken
        __typename
      }
      bankinterests {
        nextToken
        __typename
      }
      dividends {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      trusts {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      engagingtaxagent
      spousename
      spousetfn
      spouseincome
      spousedob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKTACustomer = /* GraphQL */ `
  mutation DeleteKTACustomer(
    $input: DeleteKTACustomerInput!
    $condition: ModelKTACustomerConditionInput
  ) {
    deleteKTACustomer(input: $input, condition: $condition) {
      id
      email
      phone
      fname
      lname
      tfn
      address
      dob
      description
      slug
      maritalstatuschanged
      bank_bsb
      bank_account
      salaried_flag
      soletrader_flag
      acn_flag
      trust_flag
      investmentproperty_flag
      bankinterest_flag
      dividend_flag
      taxaccounts {
        nextToken
        __typename
      }
      privileges {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      dependants {
        nextToken
        __typename
      }
      occupations {
        nextToken
        __typename
      }
      investmentproperties {
        nextToken
        __typename
      }
      bankinterests {
        nextToken
        __typename
      }
      dividends {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      trusts {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      engagingtaxagent
      spousename
      spousetfn
      spouseincome
      spousedob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKTACompany = /* GraphQL */ `
  mutation CreateKTACompany(
    $input: CreateKTACompanyInput!
    $condition: ModelKTACompanyConditionInput
  ) {
    createKTACompany(input: $input, condition: $condition) {
      id
      name
      abn
      acn
      companydescription
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerCompaniesId
      __typename
    }
  }
`;
export const updateKTACompany = /* GraphQL */ `
  mutation UpdateKTACompany(
    $input: UpdateKTACompanyInput!
    $condition: ModelKTACompanyConditionInput
  ) {
    updateKTACompany(input: $input, condition: $condition) {
      id
      name
      abn
      acn
      companydescription
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerCompaniesId
      __typename
    }
  }
`;
export const deleteKTACompany = /* GraphQL */ `
  mutation DeleteKTACompany(
    $input: DeleteKTACompanyInput!
    $condition: ModelKTACompanyConditionInput
  ) {
    deleteKTACompany(input: $input, condition: $condition) {
      id
      name
      abn
      acn
      companydescription
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerCompaniesId
      __typename
    }
  }
`;
export const createKTANotification = /* GraphQL */ `
  mutation CreateKTANotification(
    $input: CreateKTANotificationInput!
    $condition: ModelKTANotificationConditionInput
  ) {
    createKTANotification(input: $input, condition: $condition) {
      id
      title
      description
      createdby
      createdbyname
      createddate
      status
      duedate
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerNotificationsId
      __typename
    }
  }
`;
export const updateKTANotification = /* GraphQL */ `
  mutation UpdateKTANotification(
    $input: UpdateKTANotificationInput!
    $condition: ModelKTANotificationConditionInput
  ) {
    updateKTANotification(input: $input, condition: $condition) {
      id
      title
      description
      createdby
      createdbyname
      createddate
      status
      duedate
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerNotificationsId
      __typename
    }
  }
`;
export const deleteKTANotification = /* GraphQL */ `
  mutation DeleteKTANotification(
    $input: DeleteKTANotificationInput!
    $condition: ModelKTANotificationConditionInput
  ) {
    deleteKTANotification(input: $input, condition: $condition) {
      id
      title
      description
      createdby
      createdbyname
      createddate
      status
      duedate
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerNotificationsId
      __typename
    }
  }
`;
export const createKTATrust = /* GraphQL */ `
  mutation CreateKTATrust(
    $input: CreateKTATrustInput!
    $condition: ModelKTATrustConditionInput
  ) {
    createKTATrust(input: $input, condition: $condition) {
      id
      name
      abn
      tfn
      trustee
      trusteeabn
      trusteeaddress
      trusteetfn
      trusteeacn
      trusteeemail
      trusteebankbsb
      trusteebankaccount
      netcapitalgain
      trustdocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerTrustsId
      __typename
    }
  }
`;
export const updateKTATrust = /* GraphQL */ `
  mutation UpdateKTATrust(
    $input: UpdateKTATrustInput!
    $condition: ModelKTATrustConditionInput
  ) {
    updateKTATrust(input: $input, condition: $condition) {
      id
      name
      abn
      tfn
      trustee
      trusteeabn
      trusteeaddress
      trusteetfn
      trusteeacn
      trusteeemail
      trusteebankbsb
      trusteebankaccount
      netcapitalgain
      trustdocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerTrustsId
      __typename
    }
  }
`;
export const deleteKTATrust = /* GraphQL */ `
  mutation DeleteKTATrust(
    $input: DeleteKTATrustInput!
    $condition: ModelKTATrustConditionInput
  ) {
    deleteKTATrust(input: $input, condition: $condition) {
      id
      name
      abn
      tfn
      trustee
      trusteeabn
      trusteeaddress
      trusteetfn
      trusteeacn
      trusteeemail
      trusteebankbsb
      trusteebankaccount
      netcapitalgain
      trustdocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerTrustsId
      __typename
    }
  }
`;
export const createKTATrustDocument = /* GraphQL */ `
  mutation CreateKTATrustDocument(
    $input: CreateKTATrustDocumentInput!
    $condition: ModelKTATrustDocumentConditionInput
  ) {
    createKTATrustDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      trust {
        id
        name
        abn
        tfn
        trustee
        trusteeabn
        trusteeaddress
        trusteetfn
        trusteeacn
        trusteeemail
        trusteebankbsb
        trusteebankaccount
        netcapitalgain
        createdAt
        updatedAt
        kTACustomerTrustsId
        __typename
      }
      createdAt
      updatedAt
      kTATrustTrustdocumentsId
      __typename
    }
  }
`;
export const updateKTATrustDocument = /* GraphQL */ `
  mutation UpdateKTATrustDocument(
    $input: UpdateKTATrustDocumentInput!
    $condition: ModelKTATrustDocumentConditionInput
  ) {
    updateKTATrustDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      trust {
        id
        name
        abn
        tfn
        trustee
        trusteeabn
        trusteeaddress
        trusteetfn
        trusteeacn
        trusteeemail
        trusteebankbsb
        trusteebankaccount
        netcapitalgain
        createdAt
        updatedAt
        kTACustomerTrustsId
        __typename
      }
      createdAt
      updatedAt
      kTATrustTrustdocumentsId
      __typename
    }
  }
`;
export const deleteKTATrustDocument = /* GraphQL */ `
  mutation DeleteKTATrustDocument(
    $input: DeleteKTATrustDocumentInput!
    $condition: ModelKTATrustDocumentConditionInput
  ) {
    deleteKTATrustDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      trust {
        id
        name
        abn
        tfn
        trustee
        trusteeabn
        trusteeaddress
        trusteetfn
        trusteeacn
        trusteeemail
        trusteebankbsb
        trusteebankaccount
        netcapitalgain
        createdAt
        updatedAt
        kTACustomerTrustsId
        __typename
      }
      createdAt
      updatedAt
      kTATrustTrustdocumentsId
      __typename
    }
  }
`;
export const createKTADividend = /* GraphQL */ `
  mutation CreateKTADividend(
    $input: CreateKTADividendInput!
    $condition: ModelKTADividendConditionInput
  ) {
    createKTADividend(input: $input, condition: $condition) {
      id
      name
      datepaid
      franked
      unfranked
      frankingcredit
      taxwitheld
      grosspayments
      dividenddocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerDividendsId
      __typename
    }
  }
`;
export const updateKTADividend = /* GraphQL */ `
  mutation UpdateKTADividend(
    $input: UpdateKTADividendInput!
    $condition: ModelKTADividendConditionInput
  ) {
    updateKTADividend(input: $input, condition: $condition) {
      id
      name
      datepaid
      franked
      unfranked
      frankingcredit
      taxwitheld
      grosspayments
      dividenddocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerDividendsId
      __typename
    }
  }
`;
export const deleteKTADividend = /* GraphQL */ `
  mutation DeleteKTADividend(
    $input: DeleteKTADividendInput!
    $condition: ModelKTADividendConditionInput
  ) {
    deleteKTADividend(input: $input, condition: $condition) {
      id
      name
      datepaid
      franked
      unfranked
      frankingcredit
      taxwitheld
      grosspayments
      dividenddocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerDividendsId
      __typename
    }
  }
`;
export const createKTADividendDocument = /* GraphQL */ `
  mutation CreateKTADividendDocument(
    $input: CreateKTADividendDocumentInput!
    $condition: ModelKTADividendDocumentConditionInput
  ) {
    createKTADividendDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      dividend {
        id
        name
        datepaid
        franked
        unfranked
        frankingcredit
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerDividendsId
        __typename
      }
      createdAt
      updatedAt
      kTADividendDividenddocumentsId
      __typename
    }
  }
`;
export const updateKTADividendDocument = /* GraphQL */ `
  mutation UpdateKTADividendDocument(
    $input: UpdateKTADividendDocumentInput!
    $condition: ModelKTADividendDocumentConditionInput
  ) {
    updateKTADividendDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      dividend {
        id
        name
        datepaid
        franked
        unfranked
        frankingcredit
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerDividendsId
        __typename
      }
      createdAt
      updatedAt
      kTADividendDividenddocumentsId
      __typename
    }
  }
`;
export const deleteKTADividendDocument = /* GraphQL */ `
  mutation DeleteKTADividendDocument(
    $input: DeleteKTADividendDocumentInput!
    $condition: ModelKTADividendDocumentConditionInput
  ) {
    deleteKTADividendDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      dividend {
        id
        name
        datepaid
        franked
        unfranked
        frankingcredit
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerDividendsId
        __typename
      }
      createdAt
      updatedAt
      kTADividendDividenddocumentsId
      __typename
    }
  }
`;
export const createKTABankInterest = /* GraphQL */ `
  mutation CreateKTABankInterest(
    $input: CreateKTABankInterestInput!
    $condition: ModelKTABankInterestConditionInput
  ) {
    createKTABankInterest(input: $input, condition: $condition) {
      id
      name
      interest
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      yourshare
      bankinterestdocuments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerBankinterestsId
      __typename
    }
  }
`;
export const updateKTABankInterest = /* GraphQL */ `
  mutation UpdateKTABankInterest(
    $input: UpdateKTABankInterestInput!
    $condition: ModelKTABankInterestConditionInput
  ) {
    updateKTABankInterest(input: $input, condition: $condition) {
      id
      name
      interest
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      yourshare
      bankinterestdocuments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerBankinterestsId
      __typename
    }
  }
`;
export const deleteKTABankInterest = /* GraphQL */ `
  mutation DeleteKTABankInterest(
    $input: DeleteKTABankInterestInput!
    $condition: ModelKTABankInterestConditionInput
  ) {
    deleteKTABankInterest(input: $input, condition: $condition) {
      id
      name
      interest
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      yourshare
      bankinterestdocuments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerBankinterestsId
      __typename
    }
  }
`;
export const createKTABankInterestDocument = /* GraphQL */ `
  mutation CreateKTABankInterestDocument(
    $input: CreateKTABankInterestDocumentInput!
    $condition: ModelKTABankInterestDocumentConditionInput
  ) {
    createKTABankInterestDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      bankinterest {
        id
        name
        interest
        yourshare
        createdAt
        updatedAt
        kTACustomerBankinterestsId
        __typename
      }
      createdAt
      updatedAt
      kTABankInterestBankinterestdocumentsId
      __typename
    }
  }
`;
export const updateKTABankInterestDocument = /* GraphQL */ `
  mutation UpdateKTABankInterestDocument(
    $input: UpdateKTABankInterestDocumentInput!
    $condition: ModelKTABankInterestDocumentConditionInput
  ) {
    updateKTABankInterestDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      bankinterest {
        id
        name
        interest
        yourshare
        createdAt
        updatedAt
        kTACustomerBankinterestsId
        __typename
      }
      createdAt
      updatedAt
      kTABankInterestBankinterestdocumentsId
      __typename
    }
  }
`;
export const deleteKTABankInterestDocument = /* GraphQL */ `
  mutation DeleteKTABankInterestDocument(
    $input: DeleteKTABankInterestDocumentInput!
    $condition: ModelKTABankInterestDocumentConditionInput
  ) {
    deleteKTABankInterestDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      bankinterest {
        id
        name
        interest
        yourshare
        createdAt
        updatedAt
        kTACustomerBankinterestsId
        __typename
      }
      createdAt
      updatedAt
      kTABankInterestBankinterestdocumentsId
      __typename
    }
  }
`;
export const createKTAInvestmentProperty = /* GraphQL */ `
  mutation CreateKTAInvestmentProperty(
    $input: CreateKTAInvestmentPropertyInput!
    $condition: ModelKTAInvestmentPropertyConditionInput
  ) {
    createKTAInvestmentProperty(input: $input, condition: $condition) {
      id
      address
      yourshare
      purchaseprice
      purchaseyear
      datefirstrentincome
      weeksrented
      weeksavailableforrent
      loannegotiated
      rentalincome
      advertisingcost
      bodycorporatecost
      borrowingcost
      cleaningcost
      councilrates
      capitalallowances
      gardeningcost
      insurancecost
      interestcost
      landtax
      legalcost
      pestcontrolcost
      propertyagentcost
      repairsandmaintenancecost
      capitalworks
      stationerycost
      travelcost
      watercost
      sundrycost
      bankfees
      depreciationschedulefee
      othercosts
      investmentpropertydocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerInvestmentpropertiesId
      __typename
    }
  }
`;
export const updateKTAInvestmentProperty = /* GraphQL */ `
  mutation UpdateKTAInvestmentProperty(
    $input: UpdateKTAInvestmentPropertyInput!
    $condition: ModelKTAInvestmentPropertyConditionInput
  ) {
    updateKTAInvestmentProperty(input: $input, condition: $condition) {
      id
      address
      yourshare
      purchaseprice
      purchaseyear
      datefirstrentincome
      weeksrented
      weeksavailableforrent
      loannegotiated
      rentalincome
      advertisingcost
      bodycorporatecost
      borrowingcost
      cleaningcost
      councilrates
      capitalallowances
      gardeningcost
      insurancecost
      interestcost
      landtax
      legalcost
      pestcontrolcost
      propertyagentcost
      repairsandmaintenancecost
      capitalworks
      stationerycost
      travelcost
      watercost
      sundrycost
      bankfees
      depreciationschedulefee
      othercosts
      investmentpropertydocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerInvestmentpropertiesId
      __typename
    }
  }
`;
export const deleteKTAInvestmentProperty = /* GraphQL */ `
  mutation DeleteKTAInvestmentProperty(
    $input: DeleteKTAInvestmentPropertyInput!
    $condition: ModelKTAInvestmentPropertyConditionInput
  ) {
    deleteKTAInvestmentProperty(input: $input, condition: $condition) {
      id
      address
      yourshare
      purchaseprice
      purchaseyear
      datefirstrentincome
      weeksrented
      weeksavailableforrent
      loannegotiated
      rentalincome
      advertisingcost
      bodycorporatecost
      borrowingcost
      cleaningcost
      councilrates
      capitalallowances
      gardeningcost
      insurancecost
      interestcost
      landtax
      legalcost
      pestcontrolcost
      propertyagentcost
      repairsandmaintenancecost
      capitalworks
      stationerycost
      travelcost
      watercost
      sundrycost
      bankfees
      depreciationschedulefee
      othercosts
      investmentpropertydocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerInvestmentpropertiesId
      __typename
    }
  }
`;
export const createKTAInvestmentPropertyDocument = /* GraphQL */ `
  mutation CreateKTAInvestmentPropertyDocument(
    $input: CreateKTAInvestmentPropertyDocumentInput!
    $condition: ModelKTAInvestmentPropertyDocumentConditionInput
  ) {
    createKTAInvestmentPropertyDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      investmentproperty {
        id
        address
        yourshare
        purchaseprice
        purchaseyear
        datefirstrentincome
        weeksrented
        weeksavailableforrent
        loannegotiated
        rentalincome
        advertisingcost
        bodycorporatecost
        borrowingcost
        cleaningcost
        councilrates
        capitalallowances
        gardeningcost
        insurancecost
        interestcost
        landtax
        legalcost
        pestcontrolcost
        propertyagentcost
        repairsandmaintenancecost
        capitalworks
        stationerycost
        travelcost
        watercost
        sundrycost
        bankfees
        depreciationschedulefee
        othercosts
        createdAt
        updatedAt
        kTACustomerInvestmentpropertiesId
        __typename
      }
      createdAt
      updatedAt
      kTAInvestmentPropertyInvestmentpropertydocumentsId
      __typename
    }
  }
`;
export const updateKTAInvestmentPropertyDocument = /* GraphQL */ `
  mutation UpdateKTAInvestmentPropertyDocument(
    $input: UpdateKTAInvestmentPropertyDocumentInput!
    $condition: ModelKTAInvestmentPropertyDocumentConditionInput
  ) {
    updateKTAInvestmentPropertyDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      investmentproperty {
        id
        address
        yourshare
        purchaseprice
        purchaseyear
        datefirstrentincome
        weeksrented
        weeksavailableforrent
        loannegotiated
        rentalincome
        advertisingcost
        bodycorporatecost
        borrowingcost
        cleaningcost
        councilrates
        capitalallowances
        gardeningcost
        insurancecost
        interestcost
        landtax
        legalcost
        pestcontrolcost
        propertyagentcost
        repairsandmaintenancecost
        capitalworks
        stationerycost
        travelcost
        watercost
        sundrycost
        bankfees
        depreciationschedulefee
        othercosts
        createdAt
        updatedAt
        kTACustomerInvestmentpropertiesId
        __typename
      }
      createdAt
      updatedAt
      kTAInvestmentPropertyInvestmentpropertydocumentsId
      __typename
    }
  }
`;
export const deleteKTAInvestmentPropertyDocument = /* GraphQL */ `
  mutation DeleteKTAInvestmentPropertyDocument(
    $input: DeleteKTAInvestmentPropertyDocumentInput!
    $condition: ModelKTAInvestmentPropertyDocumentConditionInput
  ) {
    deleteKTAInvestmentPropertyDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      investmentproperty {
        id
        address
        yourshare
        purchaseprice
        purchaseyear
        datefirstrentincome
        weeksrented
        weeksavailableforrent
        loannegotiated
        rentalincome
        advertisingcost
        bodycorporatecost
        borrowingcost
        cleaningcost
        councilrates
        capitalallowances
        gardeningcost
        insurancecost
        interestcost
        landtax
        legalcost
        pestcontrolcost
        propertyagentcost
        repairsandmaintenancecost
        capitalworks
        stationerycost
        travelcost
        watercost
        sundrycost
        bankfees
        depreciationschedulefee
        othercosts
        createdAt
        updatedAt
        kTACustomerInvestmentpropertiesId
        __typename
      }
      createdAt
      updatedAt
      kTAInvestmentPropertyInvestmentpropertydocumentsId
      __typename
    }
  }
`;
export const createKTAOccupation = /* GraphQL */ `
  mutation CreateKTAOccupation(
    $input: CreateKTAOccupationInput!
    $condition: ModelKTAOccupationConditionInput
  ) {
    createKTAOccupation(input: $input, condition: $condition) {
      id
      name
      employer
      taxwitheld
      grosspayments
      occupationdocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerOccupationsId
      __typename
    }
  }
`;
export const updateKTAOccupation = /* GraphQL */ `
  mutation UpdateKTAOccupation(
    $input: UpdateKTAOccupationInput!
    $condition: ModelKTAOccupationConditionInput
  ) {
    updateKTAOccupation(input: $input, condition: $condition) {
      id
      name
      employer
      taxwitheld
      grosspayments
      occupationdocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerOccupationsId
      __typename
    }
  }
`;
export const deleteKTAOccupation = /* GraphQL */ `
  mutation DeleteKTAOccupation(
    $input: DeleteKTAOccupationInput!
    $condition: ModelKTAOccupationConditionInput
  ) {
    deleteKTAOccupation(input: $input, condition: $condition) {
      id
      name
      employer
      taxwitheld
      grosspayments
      occupationdocuments {
        nextToken
        __typename
      }
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerOccupationsId
      __typename
    }
  }
`;
export const createKTAOccupationDocument = /* GraphQL */ `
  mutation CreateKTAOccupationDocument(
    $input: CreateKTAOccupationDocumentInput!
    $condition: ModelKTAOccupationDocumentConditionInput
  ) {
    createKTAOccupationDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      occupation {
        id
        name
        employer
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerOccupationsId
        __typename
      }
      createdAt
      updatedAt
      kTAOccupationOccupationdocumentsId
      __typename
    }
  }
`;
export const updateKTAOccupationDocument = /* GraphQL */ `
  mutation UpdateKTAOccupationDocument(
    $input: UpdateKTAOccupationDocumentInput!
    $condition: ModelKTAOccupationDocumentConditionInput
  ) {
    updateKTAOccupationDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      occupation {
        id
        name
        employer
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerOccupationsId
        __typename
      }
      createdAt
      updatedAt
      kTAOccupationOccupationdocumentsId
      __typename
    }
  }
`;
export const deleteKTAOccupationDocument = /* GraphQL */ `
  mutation DeleteKTAOccupationDocument(
    $input: DeleteKTAOccupationDocumentInput!
    $condition: ModelKTAOccupationDocumentConditionInput
  ) {
    deleteKTAOccupationDocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      occupation {
        id
        name
        employer
        taxwitheld
        grosspayments
        createdAt
        updatedAt
        kTACustomerOccupationsId
        __typename
      }
      createdAt
      updatedAt
      kTAOccupationOccupationdocumentsId
      __typename
    }
  }
`;
export const createKTADependant = /* GraphQL */ `
  mutation CreateKTADependant(
    $input: CreateKTADependantInput!
    $condition: ModelKTADependantConditionInput
  ) {
    createKTADependant(input: $input, condition: $condition) {
      id
      name
      age
      relation
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerDependantsId
      __typename
    }
  }
`;
export const updateKTADependant = /* GraphQL */ `
  mutation UpdateKTADependant(
    $input: UpdateKTADependantInput!
    $condition: ModelKTADependantConditionInput
  ) {
    updateKTADependant(input: $input, condition: $condition) {
      id
      name
      age
      relation
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerDependantsId
      __typename
    }
  }
`;
export const deleteKTADependant = /* GraphQL */ `
  mutation DeleteKTADependant(
    $input: DeleteKTADependantInput!
    $condition: ModelKTADependantConditionInput
  ) {
    deleteKTADependant(input: $input, condition: $condition) {
      id
      name
      age
      relation
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      kTACustomerDependantsId
      __typename
    }
  }
`;
export const createKTATaxAccount = /* GraphQL */ `
  mutation CreateKTATaxAccount(
    $input: CreateKTATaxAccountInput!
    $condition: ModelKTATaxAccountConditionInput
  ) {
    createKTATaxAccount(input: $input, condition: $condition) {
      id
      systemid
      code
      type
      tfn
      abn
      acn
      name
      fname
      mname
      gender
      dob
      lname
      email
      postaladdress
      residentialaddress
      businessaddress
      phone
      slug
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerTaxaccountsId
      __typename
    }
  }
`;
export const updateKTATaxAccount = /* GraphQL */ `
  mutation UpdateKTATaxAccount(
    $input: UpdateKTATaxAccountInput!
    $condition: ModelKTATaxAccountConditionInput
  ) {
    updateKTATaxAccount(input: $input, condition: $condition) {
      id
      systemid
      code
      type
      tfn
      abn
      acn
      name
      fname
      mname
      gender
      dob
      lname
      email
      postaladdress
      residentialaddress
      businessaddress
      phone
      slug
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerTaxaccountsId
      __typename
    }
  }
`;
export const deleteKTATaxAccount = /* GraphQL */ `
  mutation DeleteKTATaxAccount(
    $input: DeleteKTATaxAccountInput!
    $condition: ModelKTATaxAccountConditionInput
  ) {
    deleteKTATaxAccount(input: $input, condition: $condition) {
      id
      systemid
      code
      type
      tfn
      abn
      acn
      name
      fname
      mname
      gender
      dob
      lname
      email
      postaladdress
      residentialaddress
      businessaddress
      phone
      slug
      customer {
        id
        email
        phone
        fname
        lname
        tfn
        address
        dob
        description
        slug
        maritalstatuschanged
        bank_bsb
        bank_account
        salaried_flag
        soletrader_flag
        acn_flag
        trust_flag
        investmentproperty_flag
        bankinterest_flag
        dividend_flag
        engagingtaxagent
        spousename
        spousetfn
        spouseincome
        spousedob
        createdAt
        updatedAt
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerTaxaccountsId
      __typename
    }
  }
`;
export const createKTAEmployee = /* GraphQL */ `
  mutation CreateKTAEmployee(
    $input: CreateKTAEmployeeInput!
    $condition: ModelKTAEmployeeConditionInput
  ) {
    createKTAEmployee(input: $input, condition: $condition) {
      id
      name
      email
      description
      slug
      privileges {
        nextToken
        __typename
      }
      workitems {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      trackmaster_create
      trackmaster_lodge
      trackmaster_update
      trackmaster_delete
      view_everyone_privilege
      custdata_view
      custdata_update
      manage_tools
      custaccount_edit
      masteremployee_flag
      customerfacing_flag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKTAEmployee = /* GraphQL */ `
  mutation UpdateKTAEmployee(
    $input: UpdateKTAEmployeeInput!
    $condition: ModelKTAEmployeeConditionInput
  ) {
    updateKTAEmployee(input: $input, condition: $condition) {
      id
      name
      email
      description
      slug
      privileges {
        nextToken
        __typename
      }
      workitems {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      trackmaster_create
      trackmaster_lodge
      trackmaster_update
      trackmaster_delete
      view_everyone_privilege
      custdata_view
      custdata_update
      manage_tools
      custaccount_edit
      masteremployee_flag
      customerfacing_flag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKTAEmployee = /* GraphQL */ `
  mutation DeleteKTAEmployee(
    $input: DeleteKTAEmployeeInput!
    $condition: ModelKTAEmployeeConditionInput
  ) {
    deleteKTAEmployee(input: $input, condition: $condition) {
      id
      name
      email
      description
      slug
      privileges {
        nextToken
        __typename
      }
      workitems {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      trackmaster_create
      trackmaster_lodge
      trackmaster_update
      trackmaster_delete
      view_everyone_privilege
      custdata_view
      custdata_update
      manage_tools
      custaccount_edit
      masteremployee_flag
      customerfacing_flag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKTAPrivilege = /* GraphQL */ `
  mutation CreateKTAPrivilege(
    $input: CreateKTAPrivilegeInput!
    $condition: ModelKTAPrivilegeConditionInput
  ) {
    createKTAPrivilege(input: $input, condition: $condition) {
      id
      name
      description
      value
      createdAt
      updatedAt
      kTACustomerPrivilegesId
      kTAEmployeePrivilegesId
      __typename
    }
  }
`;
export const updateKTAPrivilege = /* GraphQL */ `
  mutation UpdateKTAPrivilege(
    $input: UpdateKTAPrivilegeInput!
    $condition: ModelKTAPrivilegeConditionInput
  ) {
    updateKTAPrivilege(input: $input, condition: $condition) {
      id
      name
      description
      value
      createdAt
      updatedAt
      kTACustomerPrivilegesId
      kTAEmployeePrivilegesId
      __typename
    }
  }
`;
export const deleteKTAPrivilege = /* GraphQL */ `
  mutation DeleteKTAPrivilege(
    $input: DeleteKTAPrivilegeInput!
    $condition: ModelKTAPrivilegeConditionInput
  ) {
    deleteKTAPrivilege(input: $input, condition: $condition) {
      id
      name
      description
      value
      createdAt
      updatedAt
      kTACustomerPrivilegesId
      kTAEmployeePrivilegesId
      __typename
    }
  }
`;
export const createKTAWorkItem = /* GraphQL */ `
  mutation CreateKTAWorkItem(
    $input: CreateKTAWorkItemInput!
    $condition: ModelKTAWorkItemConditionInput
  ) {
    createKTAWorkItem(input: $input, condition: $condition) {
      id
      woid
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      name
      datecreated
      billedamount
      collectedamount
      dueamount
      status {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      taxaccount {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      slug
      interviewdate
      paymentdate
      signdate
      lodgementdate
      remarks {
        nextToken
        __typename
      }
      lodgementtype
      createdAt
      updatedAt
      kTAEmployeeWorkitemsId
      kTAWorkItemStatusId
      kTAWorkItemTaxaccountId
      __typename
    }
  }
`;
export const updateKTAWorkItem = /* GraphQL */ `
  mutation UpdateKTAWorkItem(
    $input: UpdateKTAWorkItemInput!
    $condition: ModelKTAWorkItemConditionInput
  ) {
    updateKTAWorkItem(input: $input, condition: $condition) {
      id
      woid
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      name
      datecreated
      billedamount
      collectedamount
      dueamount
      status {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      taxaccount {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      slug
      interviewdate
      paymentdate
      signdate
      lodgementdate
      remarks {
        nextToken
        __typename
      }
      lodgementtype
      createdAt
      updatedAt
      kTAEmployeeWorkitemsId
      kTAWorkItemStatusId
      kTAWorkItemTaxaccountId
      __typename
    }
  }
`;
export const deleteKTAWorkItem = /* GraphQL */ `
  mutation DeleteKTAWorkItem(
    $input: DeleteKTAWorkItemInput!
    $condition: ModelKTAWorkItemConditionInput
  ) {
    deleteKTAWorkItem(input: $input, condition: $condition) {
      id
      woid
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      name
      datecreated
      billedamount
      collectedamount
      dueamount
      status {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      taxaccount {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      slug
      interviewdate
      paymentdate
      signdate
      lodgementdate
      remarks {
        nextToken
        __typename
      }
      lodgementtype
      createdAt
      updatedAt
      kTAEmployeeWorkitemsId
      kTAWorkItemStatusId
      kTAWorkItemTaxaccountId
      __typename
    }
  }
`;
export const createKTAWorkItemStatus = /* GraphQL */ `
  mutation CreateKTAWorkItemStatus(
    $input: CreateKTAWorkItemStatusInput!
    $condition: ModelKTAWorkItemStatusConditionInput
  ) {
    createKTAWorkItemStatus(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKTAWorkItemStatus = /* GraphQL */ `
  mutation UpdateKTAWorkItemStatus(
    $input: UpdateKTAWorkItemStatusInput!
    $condition: ModelKTAWorkItemStatusConditionInput
  ) {
    updateKTAWorkItemStatus(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKTAWorkItemStatus = /* GraphQL */ `
  mutation DeleteKTAWorkItemStatus(
    $input: DeleteKTAWorkItemStatusInput!
    $condition: ModelKTAWorkItemStatusConditionInput
  ) {
    deleteKTAWorkItemStatus(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKTAPaymentRecord = /* GraphQL */ `
  mutation CreateKTAPaymentRecord(
    $input: CreateKTAPaymentRecordInput!
    $condition: ModelKTAPaymentRecordConditionInput
  ) {
    createKTAPaymentRecord(input: $input, condition: $condition) {
      id
      title
      amount
      priceid
      productid
      paymentlink
      taxaccount {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      status
      paymentdate
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      remarks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerPaymentrecordsId
      kTATaxAccountPaymentrecordsId
      kTAEmployeePaymentrecordsId
      kTAWorkItemPaymentrecordsId
      __typename
    }
  }
`;
export const updateKTAPaymentRecord = /* GraphQL */ `
  mutation UpdateKTAPaymentRecord(
    $input: UpdateKTAPaymentRecordInput!
    $condition: ModelKTAPaymentRecordConditionInput
  ) {
    updateKTAPaymentRecord(input: $input, condition: $condition) {
      id
      title
      amount
      priceid
      productid
      paymentlink
      taxaccount {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      status
      paymentdate
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      remarks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerPaymentrecordsId
      kTATaxAccountPaymentrecordsId
      kTAEmployeePaymentrecordsId
      kTAWorkItemPaymentrecordsId
      __typename
    }
  }
`;
export const deleteKTAPaymentRecord = /* GraphQL */ `
  mutation DeleteKTAPaymentRecord(
    $input: DeleteKTAPaymentRecordInput!
    $condition: ModelKTAPaymentRecordConditionInput
  ) {
    deleteKTAPaymentRecord(input: $input, condition: $condition) {
      id
      title
      amount
      priceid
      productid
      paymentlink
      taxaccount {
        id
        systemid
        code
        type
        tfn
        abn
        acn
        name
        fname
        mname
        gender
        dob
        lname
        email
        postaladdress
        residentialaddress
        businessaddress
        phone
        slug
        createdAt
        updatedAt
        kTACustomerTaxaccountsId
        __typename
      }
      status
      paymentdate
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      remarks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      kTACustomerPaymentrecordsId
      kTATaxAccountPaymentrecordsId
      kTAEmployeePaymentrecordsId
      kTAWorkItemPaymentrecordsId
      __typename
    }
  }
`;
export const createKTAPaymentRemarks = /* GraphQL */ `
  mutation CreateKTAPaymentRemarks(
    $input: CreateKTAPaymentRemarksInput!
    $condition: ModelKTAPaymentRemarksConditionInput
  ) {
    createKTAPaymentRemarks(input: $input, condition: $condition) {
      id
      remark
      createdby
      paymentrecord {
        id
        title
        amount
        priceid
        productid
        paymentlink
        status
        paymentdate
        createdAt
        updatedAt
        kTACustomerPaymentrecordsId
        kTATaxAccountPaymentrecordsId
        kTAEmployeePaymentrecordsId
        kTAWorkItemPaymentrecordsId
        __typename
      }
      createdAt
      updatedAt
      kTAPaymentRecordRemarksId
      __typename
    }
  }
`;
export const updateKTAPaymentRemarks = /* GraphQL */ `
  mutation UpdateKTAPaymentRemarks(
    $input: UpdateKTAPaymentRemarksInput!
    $condition: ModelKTAPaymentRemarksConditionInput
  ) {
    updateKTAPaymentRemarks(input: $input, condition: $condition) {
      id
      remark
      createdby
      paymentrecord {
        id
        title
        amount
        priceid
        productid
        paymentlink
        status
        paymentdate
        createdAt
        updatedAt
        kTACustomerPaymentrecordsId
        kTATaxAccountPaymentrecordsId
        kTAEmployeePaymentrecordsId
        kTAWorkItemPaymentrecordsId
        __typename
      }
      createdAt
      updatedAt
      kTAPaymentRecordRemarksId
      __typename
    }
  }
`;
export const deleteKTAPaymentRemarks = /* GraphQL */ `
  mutation DeleteKTAPaymentRemarks(
    $input: DeleteKTAPaymentRemarksInput!
    $condition: ModelKTAPaymentRemarksConditionInput
  ) {
    deleteKTAPaymentRemarks(input: $input, condition: $condition) {
      id
      remark
      createdby
      paymentrecord {
        id
        title
        amount
        priceid
        productid
        paymentlink
        status
        paymentdate
        createdAt
        updatedAt
        kTACustomerPaymentrecordsId
        kTATaxAccountPaymentrecordsId
        kTAEmployeePaymentrecordsId
        kTAWorkItemPaymentrecordsId
        __typename
      }
      createdAt
      updatedAt
      kTAPaymentRecordRemarksId
      __typename
    }
  }
`;
export const createKTADocument = /* GraphQL */ `
  mutation CreateKTADocument(
    $input: CreateKTADocumentInput!
    $condition: ModelKTADocumentConditionInput
  ) {
    createKTADocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      createdAt
      updatedAt
      kTACustomerDocumentsId
      kTAEmployeeDocumentsId
      __typename
    }
  }
`;
export const updateKTADocument = /* GraphQL */ `
  mutation UpdateKTADocument(
    $input: UpdateKTADocumentInput!
    $condition: ModelKTADocumentConditionInput
  ) {
    updateKTADocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      createdAt
      updatedAt
      kTACustomerDocumentsId
      kTAEmployeeDocumentsId
      __typename
    }
  }
`;
export const deleteKTADocument = /* GraphQL */ `
  mutation DeleteKTADocument(
    $input: DeleteKTADocumentInput!
    $condition: ModelKTADocumentConditionInput
  ) {
    deleteKTADocument(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      createdAt
      updatedAt
      kTACustomerDocumentsId
      kTAEmployeeDocumentsId
      __typename
    }
  }
`;
export const createKTARefData = /* GraphQL */ `
  mutation CreateKTARefData(
    $input: CreateKTARefDataInput!
    $condition: ModelKTARefDataConditionInput
  ) {
    createKTARefData(input: $input, condition: $condition) {
      Type
      values
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKTARefData = /* GraphQL */ `
  mutation UpdateKTARefData(
    $input: UpdateKTARefDataInput!
    $condition: ModelKTARefDataConditionInput
  ) {
    updateKTARefData(input: $input, condition: $condition) {
      Type
      values
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKTARefData = /* GraphQL */ `
  mutation DeleteKTARefData(
    $input: DeleteKTARefDataInput!
    $condition: ModelKTARefDataConditionInput
  ) {
    deleteKTARefData(input: $input, condition: $condition) {
      Type
      values
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKTASequenceNo = /* GraphQL */ `
  mutation CreateKTASequenceNo(
    $input: CreateKTASequenceNoInput!
    $condition: ModelKTASequenceNoConditionInput
  ) {
    createKTASequenceNo(input: $input, condition: $condition) {
      id
      seqnumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKTASequenceNo = /* GraphQL */ `
  mutation UpdateKTASequenceNo(
    $input: UpdateKTASequenceNoInput!
    $condition: ModelKTASequenceNoConditionInput
  ) {
    updateKTASequenceNo(input: $input, condition: $condition) {
      id
      seqnumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKTASequenceNo = /* GraphQL */ `
  mutation DeleteKTASequenceNo(
    $input: DeleteKTASequenceNoInput!
    $condition: ModelKTASequenceNoConditionInput
  ) {
    deleteKTASequenceNo(input: $input, condition: $condition) {
      id
      seqnumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKTAWorkItemRemarks = /* GraphQL */ `
  mutation CreateKTAWorkItemRemarks(
    $input: CreateKTAWorkItemRemarksInput!
    $condition: ModelKTAWorkItemRemarksConditionInput
  ) {
    createKTAWorkItemRemarks(input: $input, condition: $condition) {
      id
      remark
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      datecreated
      workitem {
        id
        woid
        name
        datecreated
        billedamount
        collectedamount
        dueamount
        slug
        interviewdate
        paymentdate
        signdate
        lodgementdate
        lodgementtype
        createdAt
        updatedAt
        kTAEmployeeWorkitemsId
        kTAWorkItemStatusId
        kTAWorkItemTaxaccountId
        __typename
      }
      createdAt
      updatedAt
      kTAWorkItemRemarksId
      kTAWorkItemRemarksCreatedbyId
      __typename
    }
  }
`;
export const updateKTAWorkItemRemarks = /* GraphQL */ `
  mutation UpdateKTAWorkItemRemarks(
    $input: UpdateKTAWorkItemRemarksInput!
    $condition: ModelKTAWorkItemRemarksConditionInput
  ) {
    updateKTAWorkItemRemarks(input: $input, condition: $condition) {
      id
      remark
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      datecreated
      workitem {
        id
        woid
        name
        datecreated
        billedamount
        collectedamount
        dueamount
        slug
        interviewdate
        paymentdate
        signdate
        lodgementdate
        lodgementtype
        createdAt
        updatedAt
        kTAEmployeeWorkitemsId
        kTAWorkItemStatusId
        kTAWorkItemTaxaccountId
        __typename
      }
      createdAt
      updatedAt
      kTAWorkItemRemarksId
      kTAWorkItemRemarksCreatedbyId
      __typename
    }
  }
`;
export const deleteKTAWorkItemRemarks = /* GraphQL */ `
  mutation DeleteKTAWorkItemRemarks(
    $input: DeleteKTAWorkItemRemarksInput!
    $condition: ModelKTAWorkItemRemarksConditionInput
  ) {
    deleteKTAWorkItemRemarks(input: $input, condition: $condition) {
      id
      remark
      createdby {
        id
        name
        email
        description
        slug
        trackmaster_create
        trackmaster_lodge
        trackmaster_update
        trackmaster_delete
        view_everyone_privilege
        custdata_view
        custdata_update
        manage_tools
        custaccount_edit
        masteremployee_flag
        customerfacing_flag
        createdAt
        updatedAt
        __typename
      }
      datecreated
      workitem {
        id
        woid
        name
        datecreated
        billedamount
        collectedamount
        dueamount
        slug
        interviewdate
        paymentdate
        signdate
        lodgementdate
        lodgementtype
        createdAt
        updatedAt
        kTAEmployeeWorkitemsId
        kTAWorkItemStatusId
        kTAWorkItemTaxaccountId
        __typename
      }
      createdAt
      updatedAt
      kTAWorkItemRemarksId
      kTAWorkItemRemarksCreatedbyId
      __typename
    }
  }
`;
export const createKTACommonDocuments = /* GraphQL */ `
  mutation CreateKTACommonDocuments(
    $input: CreateKTACommonDocumentsInput!
    $condition: ModelKTACommonDocumentsConditionInput
  ) {
    createKTACommonDocuments(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKTACommonDocuments = /* GraphQL */ `
  mutation UpdateKTACommonDocuments(
    $input: UpdateKTACommonDocumentsInput!
    $condition: ModelKTACommonDocumentsConditionInput
  ) {
    updateKTACommonDocuments(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKTACommonDocuments = /* GraphQL */ `
  mutation DeleteKTACommonDocuments(
    $input: DeleteKTACommonDocumentsInput!
    $condition: ModelKTACommonDocumentsConditionInput
  ) {
    deleteKTACommonDocuments(input: $input, condition: $condition) {
      id
      name
      icon
      category
      s3key
      description
      createdbyname
      createdAt
      updatedAt
      __typename
    }
  }
`;
