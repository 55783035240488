import { Box, Container, CssBaseline, Link, Typography } from "@mui/material";

const ThankYou = () => {
    return ( <div style={{backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
    backgroundRepeat: 'no-repeat', 
    backgroundAttachment:'fixed', 
    backgroundPosition:'center',
    
    height: "100vh",
    
    backgroundSize: "cover",
    }}>
        <Container maxWidth="md" style={{backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "20px", height:"100%"}}>
        
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img alt='logo' src='SVGLogo.svg' className='w3-image' style={{height:'150px'}}/>
          <br />
          <br />
          <br />
          <br />
          <Typography component="h3" variant="h3" color={'primary'}>
            Thank You
          </Typography>
          <Typography  variant="h6"  >
            Thanks for filling the form. We will get back to you soon. If you want to make any updates to your profile, you can log in to your account via our website.
            </Typography>

            <Link href="https://karvistax.com.au" variant="button">KarvisTax.com.au</Link>
            <img src="karvisportal.gif" style={{width:'90%'}}></img>
       
        </Box>
        

            
        </Container>

    </div> );
}
 
export default ThankYou;