import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useHistory } from "react-router-dom";

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Margin } from '@mui/icons-material';
import { Stack } from '@mui/material';

import { useSelector } from 'react-redux';




function ResponsiveAppBar(props) {
    const [menueItems,setMenueItems] = React.useState([
        {title:'My Home', path:'/', active:'primary'}, 
        {title:'My Profile', path:'/profile', active:'primary'},
        {title:'Forms', path:'/forms', active:'primary'}, 
        {title:'Payments', path:'/payments', active:'primary'},
        // {title:'Tax Accounts', path:'/taxaccounts', active:'primary'},
        {title:'Documents', path:'/documents', active:'primary'},
        
    ]);
    
    const settings = ['Settings', 'Logout'];

    const user = useSelector(state => state.user.value);
    const profile = useSelector(state => state.profile.value);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (menueItem) => {
    setAnchorElNav(null);
    history.push(menueItem.path);
    menueItems.map((item) => {
        item.active = 'primary';
    });
    var newMenueItem = {...menueItem, active:'secondary'};
    console.log(newMenueItem);
    var newMenueItems = [];
    menueItems.map((item) => {
        if(item.title === menueItem.title){
            newMenueItems.push(newMenueItem);
        }else{
            newMenueItems.push(item);
        }
    });
    setMenueItems(newMenueItems);
    // setMenueItems([...menueItems, ...newMenueItem]);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
    if (setting === 'Logout') {
      props.signout();
    } else if (setting === 'Settings') {
        history.push('/account-settings');
    }

  };

  return (
    <div>
   { user? <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Avatar sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} src="logo192.png" />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'inherit',
              fontWeight: 600,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Karvis Customer Portal
          </Typography>
        
        {/* Menue for he small size */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {menueItems.map((menueItem) => (
                <MenuItem key={menueItem.title} onClick={ () =>handleCloseNavMenu(menueItem)}>
                  <Typography textAlign="center" >{menueItem.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'inherit',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Karvis
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Stack direction="row" spacing={1}>
            <Typography variant="h6" noWrap component="a" sx={{ display: { xs: 'none', md: 'flex' }, color: 'white', textDecoration: 'none' }}> {profile&&profile.fname}</Typography>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <Avatar bg > */}
                
                <ManageAccountsIcon  sx={{ color: 'white', fontSize:'30px'}}/>
                {/* </Avatar> */}
              </IconButton>
            </Tooltip>
            </Stack>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() =>handleCloseUserMenu(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
       
      </Container>
      <div>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, bgcolor:"white", alignItems: 'center',justifyContent:"center" }}>
        {menueItems.map((menueItem) => (
              <Button
              
              color={menueItem.active}
                key={menueItem.title}
                onClick={() =>handleCloseNavMenu(menueItem)}
                sx={{ bgcolor:'', my: 2, color: 'secondary', display: 'block', fontSize: '1.0rem', fontWeight: 300, textTransform: 'capitalize', marginRight: '2rem', '&:hover': { color: 'primary.main' },  }}
              >
                {menueItem.title}
                
              </Button>
              
            ))}
            </Box>
            </div>
    </AppBar>:<></>}</div>
  );
}
export default ResponsiveAppBar;