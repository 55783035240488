/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "karviscustomerbasicinfo",
            "endpoint": "https://639eeyijof.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "ktaexpressapis",
            "endpoint": "https://88jlclrzji.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://lkt5mdajprdc3cdscddmoatsge.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-southeast-2:8cf3c4b9-7ac3-4ab1-8a0b-51fa47b20416",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_eQz6A1Iif",
    "aws_user_pools_web_client_id": "6v01t4f4vcdqlmptip9tb00doq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "karviscustomerbasicinfo-dev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "karviscustomerfilesinfo-dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "karviscustomerfilestorage204111-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
