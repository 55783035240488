import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const employeeSlice = createSlice({
    name: "employees",
    initialState: { value: initialStateValue },
    reducers: {
        refreshEmployees: (state, action) => {
            state.value = action.payload;
        }

    }
});

export const { refreshEmployees } = employeeSlice.actions;
export default employeeSlice.reducer;