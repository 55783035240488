
import * as React from 'react';
import Card from '@mui/material/Card';
import { CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


const DividendCard = (props) => {

    const [dividend, setbank] = React.useState({name: ''});
    
React.useEffect(() => {
    setbank(props.dividend);
}, [props.dividend])

    const handleDelete =() =>{
        props.callBackFunction(dividend)
    }

    return ( <div>

<Card className= 'w3-border' sx={{ minWidth: 275 }}>
<CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        }
        title={dividend.name}
        
      />
       
    </Card>

    </div> );
}
 
export default DividendCard;