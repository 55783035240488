import { Button, Chip, CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {useSelector, useDispatch} from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import { listKTAPaymentRecords, listKTAWorkItems } from "../graphql/queries";
import { refreshworkitems } from "../Components/Features/workitems";
import dayjs from "dayjs";


const columns = [
    
    {
      field: 'workitemtitle',
      headerName: 'Title',
      width: 250,
      
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 150,
      renderCell: (params) => (
        dayjs(params.value).format('DD/MM/YYYY')
       
    ),
      
    },
    {
      field: 'billedamount',
      headerName: 'Billed Amount',
      
      width: 110,
      
    },
    {
        field: 'collectedamount',
        headerName: 'Collected Amount',
        
        width: 120,
        
      },
    {
    field: 'dueamount',
    headerName: 'Due Amount',
    renderCell: (params) => (
         parseFloat(params.value) ===0 ? <Chip label={params.value} color="success" /> : <Chip label={params.value} color="error" />
        
    ),
    width: 130,
    
    },
    
  ];
  
const Payments = () => {

    const [spinner, setSpinner] = useState(true);
    const [ rows, setRows ] = useState([]);
    const profile = useSelector(state => state.profile.value);
    const taxAccounts = profile.taxaccounts.items;
      const [customerWorkItems, setCustomerWorkItems] = useState([]);
      const [ totalDue  , setTotalDue ] = useState(0);
      const dispatch = useDispatch();
    

    const fetchWorkItesm = async (taxaccountid) => {

        let query = {filter: {kTAWorkItemTaxaccountId: {eq: taxaccountid}}, nextToken: null}
        let response = [];
        let data = null;
        try {
        
        do {
        data = await API.graphql(graphqlOperation(listKTAWorkItems, query));
        query.nextToken = data.data.listKTAWorkItems.nextToken;
        console.log("nextToken", data.data.listKTAWorkItems.nextToken);
        if (data.data.listKTAWorkItems) {
            response= [...response,...data.data.listKTAWorkItems.items];
        }
        } while (data.data.listKTAWorkItems.nextToken);
        // console.log(data);
        return response;
        } catch (error) {
            console.log(error);
        }

      }


      const getCustomerWorkItems = async () => {
        let array = [];
        for ( const taxaccount of taxAccounts) {
            console.log("Getting work items for taxaccount: " + taxaccount.systemid);
            const data = await fetchWorkItesm(taxaccount.systemid);
            array.push(data);
            setSpinner(false);

        }
           
        console.log(array);
        setRows(array[0]);
        dispatch(refreshworkitems( array[0]));
        
        }
      
        useEffect(() => {
            getCustomerWorkItems();
            
            
        }, [profile])

        useEffect(() => {

            console.log("Printing it from here",customerWorkItems);

            customerWorkItems[0]&&customerWorkItems[0].map((workitem) => {
                let temp = 0;
                if (workitem.dueamount){
                console.log("Due amount", workitem.dueamount);
                // convert above string to number
                temp = temp + parseFloat(workitem.dueamount);

                console.log("Total due", temp);
            }
            setTotalDue(temp);

        })

        }, [customerWorkItems])

    return (<div className="w3-light-grey">
    <Container maxWidth="xl" >
        <br />
        <Grid container spacing={3}>
         {/* <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'100px' }}>
            <Typography variant="body1" component="h2">
                        <b>How to Pay: </b>
                        <br />
                        1. Plase pay the due amount to the following bank account:
                        <br />
                        2. It takes a few minutes to reflect your payment in your account.
      
                        
                    </Typography>
                </Paper>
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'400px' }}>
                    <Typography variant="body1" component="h2">
                        <b>Pending Payments :</b>
                    </Typography>
                {!spinner?    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            sorting: {
                        sortModel: [{ field: 'dueamount', sort: 'desc' }],
                        },
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        pageSizeOptions={[5 ]}
                        // checkboxSelection
                        // disableRowSelectionOnClick
                    />:<CircularProgress />}
                

                </Paper>
            </Grid>
            {/* <Grid item xs={12} md={4} lg={4}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'400px' }}>
                    <Typography variant="body1" component="h2">
                       <b> Outstanding Payments:</b>
                    
                        </Typography>
                        <br /> <br />
                        
                </Paper>
            </Grid> */}
        

            </Grid>
        </Container>
        <br />
    </div> );
}
 
export default Payments;