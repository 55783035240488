import { Avatar, Button, CircularProgress, Container, Grid, IconButton, Input, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Amplify, Storage, API, graphqlOperation } from 'aws-amplify';
import {createKTADocument, deleteKTADocument} from "../graphql/mutations";
import { addDocument, removeDocument } from "../Components/Features/profile";
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const Documents = () => {
    const [spinner, setSpinner] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const profile = useSelector(state => state.profile.value);
    const dispatch = useDispatch();
    
    const [category, setCategory] = useState("");
    const [fileDescription, setFileDescription] = useState("");
    const [icon, setIcon] = useState(null);
    const [fieleToUpload, setFileToUpload] = useState(null);
    const [fileToDisplay, setFileToDisplay] = useState(null);

    const [ rows, setRows ] = useState([]);

    const [columns, setColumns] = useState([

        { field: 'icon', headerName: 'Icon', width: 20, 
        renderCell: (params) => {
          return <>
        <Avatar src={process.env.PUBLIC_URL+"/"+ params.row.icon} />
        
         {/* {params.row.icon} */}
        </>;
        } },
        { field: 'name', headerName: 'Name', width: 350 },
        { field: 'category', headerName: 'Category', width: 150 },
        { field: 'description', headerName: 'Description', width: 450 },
        { field: 'createdbyname', headerName: 'Created By', width: 200 },
        { field: 'createdAt', headerName: 'Created At', width: 200,
      renderCell: (params) => {
        return <>{dayjs(params.value).format('DD-MM-YYYY hh:mm')}</>;
        }
       },
        { field: 'updatedAt', headerName: 'Updated At', width: 200,
      renderCell: (params) => {
        return <>{dayjs(params.value).format('DD-MM-YYYY hh:mm')}</>;
        }
    
       },
    
      ]);

    useEffect(() => {

        setRows(profile.documents.items);
        setSpinner(false);

    }, [profile]);

    function   handleUploadClick(){

            clearFile();
            setExpanded(!expanded);
        
    }

    const inputRef = useRef(null);

    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;
        var extention = event.target.files[0].name.split(".")[1];
          var varicon;
          if (extention==="csv"||extention==="xls"||extention==="xlsx"){
              varicon="xls.png";
              setIcon(varicon);
          } else if (extention==="doc"||extention==="docx"){
              varicon="word.png";
              setIcon(varicon);
          } else if ( extention==="pdf"){
              varicon="pdf.png";
              setIcon(varicon);
          } else if (extention==="ppt"||extention==="pptx"){
              varicon="ppt.png";
              setIcon(varicon);
          }else if (extention==="zip"){
              varicon="zip.png";
              setIcon(varicon);
          }else if (extention==="img"||extention==="jpg"||extention==="jpeg"||extention==="png"||extention==="gif"){
              varicon="image.png";
              setIcon(varicon);
          } else{
              varicon="file-solid.png";
              setIcon(varicon);
          }

        console.log('selectedFile:', event.target.files[0]);
        setFileToDisplay(selectedFile);
        var name = profile.fname+dayjs().format()+selectedFile.name
        setFileToUpload(name);
        
        
      };

      function handleUpoad() {
        Storage.put(fieleToUpload, fileToDisplay).then(res => {
          
            API.graphql(graphqlOperation(createKTADocument, {input: {
              name: fileToDisplay.name, 
              s3key: res.key,
              category: category,
              description: fileDescription,
              createdbyname: profile.fname+" "+profile.lname,
              kTACustomerDocumentsId: profile.id,
              kTAEmployeeDocumentsId: "NA",
              kTAWorkItemDocumentsId: "NA",
              icon: icon,}}))
              .then(res => {
                console.log('Successfully created document!');
                console.log('res:', res);
                dispatch(addDocument(res.data.createKTADocument));
                clearFile();
              }
              )
              .catch(err => {
                console.log('Error creating document:', err);
              }
              );
  
          })
          .catch(err => {
            console.log('Error uploading file:', err);
          });
      }

      function clearFile(){
        setFileToDisplay(null);
        setFileToUpload(null);
        setIcon(null);
        setCategory("");
        setFileDescription("");
      }

      const [deleteButton, setDeleteButton] = useState(false);
        const [downloadButton, setDownloadButton] = useState(false);
        const [selctedFileforDelete, setSelctedFileforDelete] = useState(null);

      const handleRowSelectionChange = (params) => {
        console.log(params);
        if (params.length>0 && params.length<2){
          setDeleteButton(true);
          setDownloadButton(true);
          setSelctedFileforDelete(params);
        } else if (params.length>1){
          setDownloadButton(false);
          setDeleteButton(false);
        }
        else {
          setDeleteButton(false);
          setDownloadButton(false);
          setSelctedFileforDelete(null);
        }
      }

      async function handleDeleteButton() {

        selctedFileforDelete.map(async (file) => {
        //   console.log(rows.filter((row) => row.id === file)[0].s3key);
          await API.graphql(graphqlOperation(deleteKTADocument, {input: {id: file}}));
          await Storage.remove(rows.filter((row) => row.id === file)[0].s3key );
          dispatch(removeDocument(file));
          // console.log(rows.filter((row) => row.id === file)[0].s3key);
          // await Storage.remove('Symly Admin02-08-+1000CSVData.csv');
        })
      
          setDeleteButton(false);
          setSelctedFileforDelete(null);
          
        //   setRows(rows.filter((row) => !selctedFileforDelete.includes(row.id)));
      
      }
      
      async function handleDownload(){
      
        selctedFileforDelete.map(async (file) => {
       
          const SignedURL = await Storage.get(rows.filter((row) => row.id === file)[0].s3key);
          window.open(SignedURL);
        })
        
      }

    return ( <div className="w3-light-grey">
    <Container maxWidth="xl" >
        <br />
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column',  }}>
                <Button variant="contained" startIcon={!expanded?<CloudUploadIcon />:<CancelIcon/>} onClick={handleUploadClick} color={!expanded?"primary":"success"}> {!expanded?<>Upload New Document</>:<>Cancel Upload</>} </Button>

                {expanded&&<div className="w3-container w3-padding">
                    <Typography variant="body" gutterBottom component="div" color={'primary'}> 1. Select the File to upload </Typography>
                    <input
                    type="file"
                    ref={inputRef}
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    />
                    <Button onClick={()=>inputRef.current.click()} variant="outlined" > Select File </Button>
                    <br />
                    <div>
                        <table>
                            <td>
                            <Avatar src={process.env.PUBLIC_URL+"/"+icon} />
                            </td>
                            <td>
                            <Typography variant="body" gutterBottom component="div" color={'primary'}> {fileToDisplay&&fileToDisplay.name} </Typography>
                            </td>
                        </table>
                    </div>
                    <br />

                    {fileToDisplay&&<div>
                    <Typography variant="body" gutterBottom component="div" color={'primary'}> 2. Enter the File Details </Typography>
                    <Select
                        value={category}
                        label="Category"
                        onChange={(e)=>setCategory(e.target.value)}
                        fullWidth
                        
                    >
                        <MenuItem value={"Forms"}>Forms</MenuItem>
                        <MenuItem value={"Id Proof"}>Id Proof</MenuItem>
                        <MenuItem value={"Expenses"}>Expenses</MenuItem>
                        <MenuItem value={"Statements"}>Statements</MenuItem>
                        <MenuItem value={"Bank Documents"}>Bank Documents</MenuItem>
                        <MenuItem value={"Company Documents"}>Company Documents</MenuItem>
                    </Select>
                    <br /> <br />
                    <TextField id="outlined-basic" label="Description" variant="outlined" onChange={(e)=>setFileDescription(e.target.value)} fullWidth/>
                    <br /> <br />
                    <Button variant="contained" 
                    onClick={handleUpoad} 
                    color="primary"
                    disabled={category===""||fileDescription===""}
                    > Upload 
                    </Button>
                    </div>}

                </div>
                }
                
                </Paper>
            
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'400px' }}>
                <Typography variant="h6" gutterBottom component="div" color={'primary'}> Your Uploaded Documents </Typography>
                <Stack direction="row" spacing={2} padding={2}>
                {deleteButton&&<Button variant="contained" color="error" className='w3-red' onClick={handleDeleteButton} icon={<DeleteIcon />}>Delete File</Button>}
                {downloadButton&&<Button type="primary" shape="round" className='w3-green' icon={<DownloadForOfflineIcon />} onClick={handleDownload} >Download</Button>}
                </Stack>
                {!spinner>0?    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            sorting: {
                        sortModel: [{ field: 'dueamount', sort: 'desc' }],
                        },
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        pageSizeOptions={[5,10 ]}
                        onRowSelectionModelChange={handleRowSelectionChange}
                        // checkboxSelection
                        // disableRowSelectionOnClick
                    />:<CircularProgress />}
                
                </Paper>
            </Grid>
            
            </Grid>
        </Container>
        <br />
    </div> );
}
 
export default Documents;