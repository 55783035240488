import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

const steps = ['Processing', 'Sent for Sign', 'Signed', 'Paid', 'Lodged'];

export default function TaxApplicartionStepper(props) {

console.log(props);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
console.log(dayjs().diff(dayjs(props.item.lodgementdate), 'day' ));
  
React.useEffect(() => {
  if(props.item.kTAWorkItemStatusId === "83975f26-2278-4b1e-9d70-da65a3ff48f4"){
    setActiveStep(1);
  } else if ( props.item.kTAWorkItemStatusId === "a71c18ef-f8c8-48d6-81b5-79667619fab3"){
    setActiveStep(2);
  } else if ( props.item.kTAWorkItemStatusId === "95e9a9c9-c6a5-4da9-9a37-e41c75dfa970"){
    setActiveStep(3);
  } else if ( props.item.kTAWorkItemStatusId === "78756a2e-4f2b-4489-a5c9-6f68fb71aad4"){
    setActiveStep(4);
  } else if ( props.item.kTAWorkItemStatusId === "bedd4a01-b459-448c-8a7e-50009c9a0d05"){
    setActiveStep(0);
  } else if ( props.item.kTAWorkItemStatusId === "36c99f15-0056-479f-8a4e-7c8091148643"){
    setActiveStep(0);
  } else {
    setActiveStep(0);
  }
}, [props]);

  


  return (
    <Box sx={{ width: '100%' }}>
        <br />
        <Typography variant="caption" component="div" gutterBottom>{props.item.workitemtitle}</Typography>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="error">
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      
    </Box>
  );
}