import { Amplify, API } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { EmailTemplate } from "../GlobalVariables/EmailTemplate";

Amplify.configure(awsconfig);

export const SendEmailFunction = (inputs) => {

    const apiName = 'ktaexpressapis';
const path = '/sendemail';
const myInit = { // OPTIONAL    
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: { 
        fromEmail: inputs.fromEmail,
        toEmails: inputs.toEmails,
        subject: inputs.subject,
        htmlbody: EmailTemplate({title: inputs.title, greeting: inputs.greeting, body: inputs.body, link: inputs.link}),
        textbody: '',
    }
};

API.get(apiName, path, myInit).then(res => {
    // Add your code here
    console.log(res.data)
}).catch(error => {
    console.log(error)
});


}
