import * as React from 'react';
import Card from '@mui/material/Card';
import { CardHeader, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


const DpendentCard = (props) => {

    const handleDelete =() =>{
        props.callBackFunction(props.id)
    }

    return ( <div>

<Card className= 'w3-border' sx={{ minWidth: 275 }}>
<CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleDelete}>
            <DeleteIcon color='error'/>
          </IconButton>
        }
        title={<Typography variant="h6" color={'note'}> <b> {props.depname}</b> </Typography>}
        subheader={props.relationship+" : "+props.age}
      />
    </Card>

    </div> );
}
 
export default DpendentCard;