import { API, graphql, graphqlOperation } from 'aws-amplify';
import { createKTATrust, deleteKTATrust} from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { addProfileTrust, removeProfileTrust } from '../Features/profile';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import {  Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ReusableCard from './ReusableCard';
import WorkIcon from '@mui/icons-material/Work';
import OccupationCard from './Occupationcard';
import TrustCard from './TrustCard';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const TrustDetails = () => {
    
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile.value);
    
    // Modal related 
    const [open, setOpen] = React.useState(false);
    // Data collectted in modal
    const [trusts, setCompaniesList] = React.useState([{name: '', abn: ''}]);
    const [cName, setcName] = React.useState("");
    
    const [cABN, setABN] = React.useState([]);

    useEffect(() => {
        if (profile){
            if (profile.trusts){
              setCompaniesList(profile.trusts.items);
            }
        }
    }, [profile]);

    // Modal Functions
    const handleOpen = () => {
        setOpen(true);
        setcName("");
        
        setABN("");
        
    }

    const handleCreate = () => {
        setOpen(false);
        var newtrust = {
            name: cName,
            
            abn: cABN,
            kTACustomerTrustsId: profile.id,
        };
        API.graphql(graphqlOperation(createKTATrust, {input: newtrust})).then(res => {
            console.log(res.data.createKTATrust);
            dispatch(addProfileTrust(res.data.createKTATrust));
            
        }).catch(e => {
            console.warn(e);
        }
        );
        
    }

    const handleModalLooseFocus = () => {
        setOpen(false);
    }
    

        const delteItem = (item) =>{
            
          API.graphql(graphqlOperation(deleteKTATrust, {input: {id: item.id}})).then(res => {
              console.log(res);
              dispatch(removeProfileTrust(item.id));
          }).catch(e => {
              console.warn(e);
          });
            
        }

    return ( <div>
      {JSON.parse(profile.trust_flag)?
    <div className='w3-container'>
      <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>My Trust Details:</b> </Typography>
       {/* {props.state.incomesources.salary ? <div className="w3-padding">  */}
       <div className="w3-padding"> 
    <div className="w3-container"> 
    <Button variant="contained" endIcon={<WorkIcon />} onClick={handleOpen}> Click to Add Trust</Button>
    <br />  <br />
    </div>   
        {   trusts.map(company => (
            <TrustCard key={company.id} company={company} callBackFunction={delteItem}/>
        ))}
            <br />
        
        
    
      <Modal
        open={open}
        onClose={handleModalLooseFocus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2"> 
            Add Trust
          </Typography>
          <TextField
          label="Trust Name"
          fullWidth
          margin='dense'
          value={cName}
          onChange={(e)=> setcName( e.target.value)}
        />
        <TextField
          label="ABN"
          fullWidth
          margin='dense'
          value={cABN}
          onChange={(e)=> setABN( e.target.value)}
        />
        <br />
        <br />
        <Typography variant='body2' color='secondary'> We may be asking you to email us some documents for tax return calculations. </Typography>
        <br />
        <Button variant="contained" onClick={handleCreate}>Submit</Button>
        </Box>
      </Modal>


    </div>
    </div> :<></>}
    </div>
    );
}
 
export default TrustDetails;

