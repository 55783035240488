/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getKTACustomer = /* GraphQL */ `
  query GetKTACustomer($id: ID!) {
    getKTACustomer(id: $id) {
      id
      email
      phone
      fname
      lname
      tfn
      address
      dob
      description
      slug
      maritalstatuschanged
      bank_bsb
      bank_account
      salaried_flag
      soletrader_flag
      acn_flag
      trust_flag
      investmentproperty_flag
      bankinterest_flag
      dividend_flag
      taxaccounts {
        items {
        systemid
        email
        kTACustomerTaxaccountsId
        code
        abn
        acn
      }
        nextToken
        __typename
      }
      privileges {
        nextToken
        __typename
      }
      documents {
        items {
          updatedAt
          s3key
          name
          kTAWorkItemDocumentsId
          kTAEmployeeDocumentsId
          kTACustomerDocumentsId
          id
          icon
          description
          createdbyname
          createdAt
          category
        }
        nextToken
        __typename
      }
      paymentrecords {
        nextToken
        __typename
      }
      dependants {
        nextToken
        items {
            id
        age
        createdAt
        name
        relation
        updatedAt
        kTACustomerDependantsId
        }
        __typename
      }
      occupations {
        nextToken
        items {
        createdAt
        employer
        grosspayments
        id
        kTACustomerOccupationsId
        name
        occupationdocuments {
          items {
            id
          }
        }
        taxwitheld
        updatedAt
      }
        __typename
      }
      investmentproperties {
        nextToken
        items {
        address
        advertisingcost
        bankfees
        bodycorporatecost
        borrowingcost
        capitalallowances
        capitalworks
        cleaningcost
        councilrates
        createdAt
        datefirstrentincome
        depreciationschedulefee
        gardeningcost
        id
        insurancecost
        interestcost
        investmentpropertydocuments {
          items {
            category
            createdAt
            createdbyname
            description
            icon
            id
            name
            kTAInvestmentPropertyInvestmentpropertydocumentsId
            s3key
            updatedAt
          }
        }
        kTACustomerInvestmentpropertiesId
        landtax
        yourshare
        legalcost
        loannegotiated
        othercosts
        pestcontrolcost
        propertyagentcost
        purchaseyear
        purchaseprice
        rentalincome
        repairsandmaintenancecost
        stationerycost
        sundrycost
        travelcost
        updatedAt
        watercost
        weeksavailableforrent
        weeksrented
      }
        __typename
      }
      bankinterests {
        nextToken
        items {
        createdAt
        id
        interest
        kTACustomerBankinterestsId
        name
        updatedAt
        yourshare
      }
        __typename
      }
      dividends {
        nextToken
        items {
        createdAt
        datepaid
        franked
        frankingcredit
        grosspayments
        id
        kTACustomerDividendsId
        name
        taxwitheld
        unfranked
        updatedAt
      }
        __typename
      }
      companies {
        nextToken
        items {
        abn
        acn
        companydescription
        id
        createdAt
        kTACustomerCompaniesId
        name
        updatedAt
      }
        __typename
      }
      trusts {
        nextToken
        items {
        abn
        createdAt
        id
        kTACustomerTrustsId
        name
        netcapitalgain
        tfn
        trustee
        trusteeabn
        trusteeacn
        trusteeaddress
        trusteebankaccount
        trusteebankbsb
        trusteeemail
        trusteetfn
        updatedAt
      }
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      engagingtaxagent
      spousename
      spousetfn
      spouseincome
      spousedob
      createdAt
      updatedAt
      __typename
    }
  }
`;