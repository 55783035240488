import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid, Paper } from '@mui/material';
import PersonalDetails from '../Components/OnboardingForms/PersonalDetails';
import SpouseDetails from '../Components/OnboardingForms/SpouseDetails';
import DependentsDetails from '../Components/OnboardingForms/DependentDetails';
import ElectronicBankingDetails from '../Components/OnboardingForms/ElectronicBanking';
import OccupationDetails from '../Components/OnboardingForms/Occupation';
import IncomeTypes from '../Components/OnboardingForms/IncomeTypes';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';
import { useDispatch, useSelector } from 'react-redux';
import { 
  updatePersonalDetailFlag,
  updateSouseDetailFlag,
  updateDependentDetailFlag,
  completePersonalData,
  completeBankData,
  completeIncomeTypesData,
  completeOccupationData,
  completePropertiesData,
  completeCompaniesData,
  completeTrustsData,
  completeBankInterestData,
  completeDividendsData
} from '../Components/Features/detailschangedflags';
import InvestmentPropertyDetails from '../Components/OnboardingForms/InvestmentProperty';
import TrustDetails from '../Components/OnboardingForms/TrustDetails';

import BankInterestDetails from '../Components/OnboardingForms/BankInterestDetails';
import CompanyDetails from '../Components/OnboardingForms/CompanyDetails';
import DividendDetails from '../Components/OnboardingForms/DividendDetails';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ overflowY: 'auto', height: '750px', width:'100%', border: '1px solid #ccc', padding: '10px' }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function PersonalProfile() {
  const [value, setValue] = React.useState(0);
  const dcflags = useSelector(state => state.dcflags.value);
  const profile = useSelector(state => state.profile.value);
  const dispatch = useDispatch();
  
  // React.useEffect(() => {
  //   if (profile.fname && profile.lname && profile.tfn && profile.address && profile.phone && profile.dob ){
  //     dispatch(completePersonalData(false));
  //   }
  //   if (profile.bank_bsb && profile.bank_account){
  //     dispatch(completeBankData(false));
  //   }
  //   if (profile.salaried_flag || profile.soletrader_flag || profile.acn_flag || profile.trust_flag || profile.investmentproperty_flag || profile.bankinterest_flag || profile.dividend_flag){
  //     dispatch(completeIncomeTypesData(false));
  //   }
  //   if (profile.occupations.items.length>0){
  //     console.error("Test")
  //     dispatch(completeOccupationData(false));
  //   }
  //   if (profile.investmentproperties.items.length>0){
      
  //     dispatch(completePropertiesData(false));
  //   }
  //   if (profile.companies.items.length>0){  
  //     dispatch(completeCompaniesData(false));
  //   }
  //   if (profile.trusts.items>0){
  //     dispatch(completeTrustsData(false));
  //   }
  //   if (profile.bankinterests.items.length>0){
  //     dispatch(completeBankInterestData(false));
  //   }
  //   if (profile.dividends.items.length>0){
  //     dispatch(completeDividendsData(false));
  //   }

  // }, [profile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div >
        <br />
    <Container maxWidth="xl" >
    <Grid container spacing={2}>
        
        <div className='w3-container w3-center-align'>
            <br />
        <Typography variant="h6" gutterBottom component="div" color={'primary'} > <b>My Profile:</b> </Typography>
        </div>
        <Grid item xs={12} sm={12}>
        
            
        <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 800 }}
        >
            
            
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
        >
            {/* <Tab label={dcflags.personaldatapending?<div>Personal <PendingIcon color='error'/></div>:<div>Personal <CheckCircleOutlineIcon color='success'/></div>}  {...a11yProps(0)}/>
            <Tab label={dcflags.bankdatapending?<div>Bank <PendingIcon color='error'/></div>:<div>Bank <CheckCircleOutlineIcon color='success'/></div>}  {...a11yProps(1)} />
            <Tab label={dcflags.incometypesdatapending?<div>Income Types <PendingIcon color='error'/></div>:<div>Income Types <CheckCircleOutlineIcon color='success'/></div>}  {...a11yProps(2)} />
            {JSON.parse(profile.salaried_flag)&&<Tab label={dcflags.occupationdatapending?<div>Occupations <PendingIcon color='error'/></div>:<div>Occupations <CheckCircleOutlineIcon color='success'/></div>}  {...a11yProps(3)} />}
            {JSON.parse(profile.investmentproperty_flag)&&<Tab label={dcflags.propertiesdatapending?<div>Properties <PendingIcon color='error'/></div>:<div>Properties <CheckCircleOutlineIcon color='success'/></div>} {...a11yProps(4)} />}
            {JSON.parse(profile.acn_flag)&&<Tab label={dcflags.companiesdatapending?<div>Companies <PendingIcon color='error'/></div>:<div>Companies <CheckCircleOutlineIcon color='success'/></div>} {...a11yProps(5)} />}
            {JSON.parse(profile.trust_flag)&&<Tab label={dcflags.trustsdatapending?<div>Trusts <PendingIcon color='error'/></div>:<div>Trusts <CheckCircleOutlineIcon color='success'/></div>} {...a11yProps(6)} />}
            {JSON.parse(profile.bankinterest_flag)&&<Tab label={dcflags.bankinterestdatapending?<div>Bank Interest <PendingIcon color='error'/></div>:<div>Bank Interest <CheckCircleOutlineIcon color='success'/></div>} {...a11yProps(7)} />}
            {JSON.parse(profile.dividend_flag)&&<Tab label={dcflags.dividendsdatapending?<div>Dividends <PendingIcon color='error'/></div>:<div>Dividends <CheckCircleOutlineIcon color='success'/></div>} {...a11yProps(8)} />} */}

          <Tab label='Personal' {...a11yProps(0)}/>
            <Tab label='Bank' {...a11yProps(1)} />
            <Tab labb label='Income Types' {...a11yProps(2)} />
            {JSON.parse(profile.salaried_flag)&&<Tab label='Occupations'  {...a11yProps(3)} />}
            {JSON.parse(profile.investmentproperty_flag)&&<Tab label='Properties' {...a11yProps(4)} />}
            {JSON.parse(profile.acn_flag)&&<Tab label='Companies' {...a11yProps(5)} />}
            {JSON.parse(profile.trust_flag)&&<Tab label='Trusts'   {...a11yProps(6)} />}
            {JSON.parse(profile.bankinterest_flag)&&<Tab  label='Bank' Interest  {...a11yProps(7)} />}
            {JSON.parse(profile.dividend_flag)&&<Tab  label='Dividends' {...a11yProps(8)} />}
        </Tabs>
        <TabPanel value={value} index={0}>
            <PersonalDetails />
            <SpouseDetails />
            <DependentsDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <ElectronicBankingDetails />
        </TabPanel>
        <TabPanel value={value} index={2}>
        <IncomeTypes />

        </TabPanel>
        <TabPanel value={value} index={3}>
        <OccupationDetails />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <InvestmentPropertyDetails/>
        </TabPanel>
        <TabPanel value={value} index={5}>
        <CompanyDetails />
        </TabPanel>
        <TabPanel value={value} index={6}>
            <TrustDetails />
        </TabPanel>
        <TabPanel value={value} index={7}>
            <BankInterestDetails />
        </TabPanel>
        <TabPanel value={value} index={8}>
            <DividendDetails />
        </TabPanel>
        </Box>
    </Grid>
    </Grid>
    </Container>
    
    </div>
  );
}