import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import WarningModal from '../Components/WarningModal';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ForgotPassword() {
  
const [warningflag, setWarningFlag] = React.useState(false);
  const [warningheader, setwarningheader] = React.useState("");
  const [warningtext, setwarningtext] = React.useState("");
  function resetwarningflag(){
      setWarningFlag(false);
      setwarningheader("");
      setwarningtext("");

  }
  
  const [UserNotConfirmedException, setUserNotConfirmedException] = React.useState(false);
  const [step, setStep] = React.useState(false);
  const [confirmationcode, setconfirmationcode] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [newPassword, setnewPassword] = React.useState("");
  
  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(username);
      setWarningFlag(true);
      setwarningheader("Code Sent Successfully");
      setwarningtext("Please check your email.📝 Sometimes the code may land in Junk 🍕🍔 Mailbox. Please check there as well.")
    } catch (err) {
      console.log('error resending code: ', err);
      setWarningFlag(true);
      setwarningheader(err.name);
      setwarningtext(err.message)
    }
  }
  async function confirmSignUp() {

    Auth.forgotPasswordSubmit(username, confirmationcode, newPassword).then(response =>{
        window.location.href= '/';
    }).catch(error =>{
        console.log('error updating password', error);
      setWarningFlag(true);
      setwarningheader(error.name);
      setwarningtext(error.message)

    })

    
  }
    const handleSubmit = (event) => {
    
    if (/\S+@\S+\.\S+/.test(username)){

    Auth.forgotPassword(username).then(response =>{
       
        setStep(true);

    }).catch (err=>{
        if (err.name === "UserNotConfirmedException"){
            setUserNotConfirmedException(true);
            
            
        } else {
        console.log(err);
        setWarningFlag(true);
        setwarningheader(err.name);
        setwarningtext(err.message);
        }
    })
}else {
    setWarningFlag(true);
    setwarningheader("Please enter a valid Email")
}

  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img src='KarvisGraphics.svg' className='w3-image' style={{height:'150px'}}/>
          <Typography component="h1" variant="h5">
            Forgot password
          </Typography>
          {UserNotConfirmedException&& <Typography variant='body' color={'error'}>Goto Login scree and first confirm your email</Typography>}
          <Typography variant="body">
          Lost your password? Please enter your email address. You will receive a conformation code and will be able to create a new password .
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={(e)=>setUserName(e.target.value)}
            />
            {step? <div> <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={newPassword}
              onChange={(e)=>setnewPassword(e.target.value)}
            /> <TextField
              margin="normal"
              required
              fullWidth
              name="code"
              label="Code"
              id="code"
              value={confirmationcode}
              onChange={(e=> setconfirmationcode(e.target.value))}
            /></div>:<></>}
           <br />
            {!step? <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Get Cofirmation Code
            </Button>:
            <div>
            <Button
              onClick={confirmSignUp}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Update Password
            </Button>
           </div>}
            <Grid container>
            
              
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <WarningModal warningflag={warningflag} warningheader={warningheader} warningtext={warningtext} resetwarningflag={resetwarningflag} />
    </ThemeProvider>
  );
}