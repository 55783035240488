import { API, graphql, graphqlOperation } from 'aws-amplify';
import { createKTADependant,updateKTACustomer,updateKTADependant, deleteKTADependan, createKTAOccupation,deleteKTADependant, deleteKTAOccupation } from '../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import {  Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ReusableCard from './ReusableCard';
import WorkIcon from '@mui/icons-material/Work';
import OccupationCard from './Occupationcard';

import  {addProfileOccupation, removeProfileOccupation} from '../Features/profile';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const OccupationDetails = () => {
    
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile.value);
    const dcflags = useSelector(state => state.dcflags.value);


    // Modal related 
    const [open, setOpen] = React.useState(false);
    // Data collectted in modal
    const [ocName, setocName] = React.useState("");
    const [ocEmployer, setocEmployer] = React.useState("");
    const [ocTaxwitheld, setocTaxwitheld] = React.useState("");
    const [ocGrossPayment, setocGrossPayment] = React.useState("");
    const [occupationList, setOccupationList] = React.useState([]);

    useEffect(() => {
        if (profile){
            if (profile.occupations){
              setOccupationList(profile.occupations.items);
            }
        }
    }, [profile]);



    // Modal Functions
    const handleOpen = () => {
        setOpen(true);
        setocName("");
        setocEmployer("");
        setocTaxwitheld("");
        setocGrossPayment("");
    }
    const handleCreate = () => {
        setOpen(false);
        var newOccupation = {
            name: ocName,
            employer: ocEmployer,
            taxwitheld: ocTaxwitheld,
            grosspayments: ocGrossPayment,
            kTACustomerOccupationsId: profile.id

        };
        API.graphql(graphqlOperation(createKTAOccupation, {input: newOccupation})).then(res => {
            console.log(res.data.createKTAOccupation);
            dispatch(addProfileOccupation(res.data.createKTAOccupation));
            
        }).catch(e => {
            console.warn(e);
        }
        );
        
    }

    const handleModalLooseFocus = () => {
        setOpen(false);
    }
    

        const delteItem = (item) =>{
            
          API.graphql(graphqlOperation(deleteKTAOccupation, {input: {id: item.id}})).then(res => {
              
              dispatch(removeProfileOccupation(item.id));
          }).catch(e => {
              console.warn(e);
          });
            
        }

    return ( <div>
      {JSON.parse(profile.salaried_flag)?
  
    <div className='w3-container'>
      <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>My Occupation Details:</b> </Typography>
       {/* {props.state.incomesources.salary ? <div className="w3-padding">  */}
       {true ? <div className="w3-padding"> 
    <div className="w3-container"> 
    <Button variant="contained" endIcon={<WorkIcon />} onClick={handleOpen}> Click to Add Occupation</Button>
    <br />  <br />
    </div>   
        {   occupationList.map(occupation => (
            <OccupationCard key={occupation.id} occupation={occupation} callBackFunction={delteItem}/>
        ))}
            <br />
        <Typography variant='button'> Click next to skip or continue when done</Typography>
        
    
      <Modal
        open={open}
        onClose={handleModalLooseFocus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2"> 
            Add Occupation
          </Typography>
          <TextField
          label="Occupation Name"
          fullWidth
          margin='dense'
          value={ocName}
          onChange={(e)=> setocName( e.target.value)}
        />
        <TextField
          label="Employer"
          fullWidth
          margin='dense'
          value={ocEmployer}
          onChange={(e)=> setocEmployer( e.target.value)}
        />
         
        <br />
        <Typography variant='body2' color='secondary'> Below information can be entered if handy. Otherwise your tax agent may request to send this information at a later stage.</Typography>
        <br />
        <TextField
          label="Tax Witheld"
          fullWidth
          margin='dense'
          value={ocTaxwitheld}
          onChange={(e)=> setocTaxwitheld( e.target.value)}
        />
        <TextField
          label="Gross Payments"
          fullWidth
          margin='dense'
          value={ocGrossPayment}
          onChange={(e)=> setocGrossPayment( e.target.value)}
        />
       
       
        <Button variant="contained" onClick={handleCreate}>Submit</Button>
        </Box>
      </Modal>


    </div>:<div className='w3-large'> 
            
        <br/> 
            <img className="w3-image" src="noworries.jpg" alt="" />
        <br/>You have indicated that you are not a salaried employee. <b>Please proceed to next step.</b></div>}
    </div>:<></>}
    </div>
    );
}
 
export default OccupationDetails;

