import { Button, Chip, Container, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { useHistory } from "react-router-dom";
import PaidIcon from '@mui/icons-material/Paid';
import TaxApplicartionStepper from "../Components/TaxApplicationStepper";
import {useSelector, useDispatch} from "react-redux";
import {refreshworkitems} from "../Components/Features/workitems";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { listKTAWorkItems } from "../graphql/queries";
import dayjs from "dayjs";
import PaymentButton from "../Components/PaymentButton";


const Home = () => {

    const history = useHistory();
    const profile = useSelector(state => state.profile.value);
    const datenow = new Date();
    const dispatch = useDispatch();
    const [totalDue, setTotalDue] = useState(0);

    const headCells = [
        {
          id: 'trackingNo',
          align: 'left',
          disablePadding: false,
          label: 'Tracking No.'
        },
        {
          id: 'name',
          align: 'left',
          disablePadding: false,
          label: 'Product Name'
        },
        {
          id: 'fat',
          align: 'right',
          disablePadding: false,
          label: 'Total Order'
        },
        {
          id: 'carbs',
          align: 'left',
          disablePadding: false,
      
          label: 'Status'
        },
        {
          id: 'protein',
          align: 'right',
          disablePadding: false,
          label: 'Total Amount'
        }
      ];

      const taxAccounts = profile.taxaccounts.items;
      const [customerWorkItems, setCustomerWorkItems] = useState([]);
      
      const fetchWorkItesm = async (taxaccountid) => {

        let query = {filter: {kTAWorkItemTaxaccountId: {eq: taxaccountid}}, nextToken: null}
        let response = [];
        let data = null;
        try {
        
        do {
        data = await API.graphql(graphqlOperation(listKTAWorkItems, query));
        query.nextToken = data.data.listKTAWorkItems.nextToken;
        console.log("nextToken", data.data.listKTAWorkItems.nextToken);
        if (data.data.listKTAWorkItems) {
            response= [...response,...data.data.listKTAWorkItems.items];
        }
        } while (data.data.listKTAWorkItems.nextToken);
        // console.log(data);
        return response;
        } catch (error) {
            console.log(error);
        }

      }


      const getCustomerWorkItems = async () => {
        let array = [];
        for ( const taxaccount of taxAccounts) {
            console.log("Getting work items for taxaccount: " + taxaccount.systemid);
            const data = await fetchWorkItesm(taxaccount.systemid);
            array.push(data);

        }
           
        console.log(array);
        setCustomerWorkItems(array);
        dispatch(refreshworkitems( array[0]));
        
        }
      
        useEffect(() => {
            getCustomerWorkItems();
            
            
        }, [profile])

        useEffect(() => {

            console.log("Printing it from here",customerWorkItems);

            customerWorkItems[0]&&customerWorkItems[0].map((workitem) => {
                let temp = 0;
                if (workitem.dueamount){
                console.log("Due amount", workitem.dueamount);
                // convert above string to number
                temp = temp + parseFloat(workitem.dueamount);

                console.log("Total due", temp);
            }
            setTotalDue(temp);

        })

        }, [customerWorkItems])
            

    return ( <div className="w3-light-grey">
        <Container maxWidth="xl" >
            <br />
            <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'100px' }}>
                <Typography variant="body1" component="h2">
                            <b>Status of your requests: </b>
                <Typography variant="caption" >Completed items older than 30 days are not shown</Typography>
                       {
                        customerWorkItems[0]&&customerWorkItems[0].map((workitem) => (
                            (workitem.kTAWorkItemStatusId !=="78756a2e-4f2b-4489-a5c9-6f68fb71aad4" || dayjs().diff(dayjs(workitem.lodgementdate), 'day' ) < 30)  &&   <TaxApplicartionStepper item={workitem} />
                        
                           
                        ))

                       }
                            
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'400px' }}>
                        <Typography variant="body1" component="h2">
                            <b>{profile.fname} Actions</b>
                        </Typography>
                    {profile.id==='7a5a4756-973f-41c5-92a7-c5fd98d64804'?<div>
                        <Typography variant="body2" component="p">
                            Your Accountant have requested the following actions from you. Please complete them as soon as possible.
                            
                        </Typography>
                        
                        <div className="w3-panel w3-leftbar w3-sand w3-serif w3-padding"   >
                        <IconButton  onClick={() => history.push('/forms')}>
                        <Stack direction="row" spacing={3}>
                        <AdsClickIcon color="primary"/>
                        <Typography variant="button" component="p" color="primary">  "Please fill the rental data form for your investment properties"</Typography>
                        <Typography variant="button" component="p" color="info"> DueDate: {'21/08/2023'} </Typography>
                        <Chip label="Pending" color="secondary" />
                        
                        </Stack>
                        </IconButton>
                        </div> 

                        <div className="w3-panel w3-leftbar w3-sand w3-serif w3-padding"   >
                        <IconButton  onClick={() => history.push('/forms')}>
                        <Stack direction="row" spacing={3}>
                        <AdsClickIcon color="primary"/>
                        <Typography variant="button" component="p" color="primary">  "Complete the form for dividends and shares."</Typography>
                        <Typography variant="button" component="p" color="info"> DueDate: {'21/08/2023'} </Typography>
                        <Chip label="Pending" color="secondary" />
                        
                        </Stack>
                        </IconButton>
                        </div> 
                        
                    </div>:<div></div>}

                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'400px' }}>
                        <Typography variant="body1" component="h2">
                           <b> Outstanding Payments:</b>
                        </Typography>
            {    profile?    <div>
                            <Typography variant="body2" component="p">
                                Following payments are due. Please make the payment as soon as possible.
                            </Typography>
                            <Box
                                sx={{
                                    width: 300,
                                    height: 300,
                                    padding: 2,
                                    marginLeft: 6,
                                    left: 6,
                                    alignItems: 'center',
            
                                    backgroundColor: 'primary',
                                    
                                }}
                                >
                                <Typography variant="h6" component="h2">
                                    Total Due Amount 
                                </Typography>
                                <div className="w3-center"   >
                                <Typography variant="h1" component="p">
                                    ${totalDue}
                                </Typography>
                                </div>
                                <br />
                                <Typography variant="body2" component="p">
                                    <a href="/payments" target="_blank" rel="noreferrer">View Details</a>
                                </Typography>
                            
                                
                                </Box>
                            
                            
                    </div>: <div>
                        <br /> <br />
                        <Typography variant="body2" component="p">
                               There are no payments due from you at the moment.
                            </Typography>
                            <br /> <br />
                        </div>}

                            
                                    
                </Paper>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight:'100px' }}>
                    <Typography variant="body1" component="h2">
                        <b>Account Activity Log</b>
                    </Typography>
                    <div className="w3-container">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell><b>Date</b> </TableCell>
                                    <TableCell align="right"><b>Description</b></TableCell>
                                    <TableCell align="right"><b>Amount</b></TableCell>
                                    <TableCell align="right"><b>Status</b></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody >
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                >
                                    <TableCell component="th" scope="row">
                                        {'21/08/2023'}
                                    </TableCell>
                                    <TableCell align="right">{'Payment Title'}</TableCell>
                                    <TableCell align="right">{'$23.55'}</TableCell>
                                    <TableCell align="right">{'Paid'}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                >
                                    <TableCell component="th" scope="row">
                                        {'21/08/2023'}
                                    </TableCell>
                                    <TableCell align="right">{'Payment Title'}</TableCell>
                                    <TableCell align="right">{'$23.55'}</TableCell>
                                    <TableCell align="right">{'Paid'}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                >
                                    <TableCell component="th" scope="row">
                                        {'21/08/2023'}
                                    </TableCell>
                                    <TableCell align="right">{'Payment Title'}</TableCell>
                                    <TableCell align="right">{'$23.55'}</TableCell>
                                    <TableCell align="right">{'Paid'}</TableCell>
                                </TableRow>

                                <TableRow
                                    hover
                                    tabIndex={-1}
                                >
                                    <TableCell component="th" scope="row">
                                        {'21/08/2023'}
                                    </TableCell>
                                    <TableCell align="right">{'Payment Title'}</TableCell>
                                    <TableCell align="right">{'$23.55'}</TableCell>
                                    <TableCell align="right">{'Paid'}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                >
                                    <TableCell component="th" scope="row">
                                        {'21/08/2023'}
                                    </TableCell>
                                    <TableCell align="right">{'Payment Title'}</TableCell>
                                    <TableCell align="right">{'$23.55'}</TableCell>
                                    <TableCell align="right">{'Paid'}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                >
                                    <TableCell component="th" scope="row">
                                        {'21/08/2023'}
                                    </TableCell>
                                    <TableCell align="right">{'Payment Title'}</TableCell>
                                    <TableCell align="right">{'$23.55'}</TableCell>
                                    <TableCell align="right">{'Paid'}</TableCell>
                                </TableRow>
                                </TableBody >
                                </Table>
                            </TableContainer>
                        </div>
                                            

                        </Paper>
                </Grid> */}

                </Grid>
            </Container>
            <br />
        </div> );
}
 
export default Home;