import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';
import  {validateEmail, validatePhone, validateDollar, validateTFN} from '../../GlobalVariables/GlobalValidator';
import { Button, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {updateDependentDetailFlag, updatePersonalDetailFlag, updateSouseDetailFlag} from '../Features/detailschangedflags';
import WarningModal from '../WarningModal';
import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { gblRefreshProfile, useRefreshProfile } from '../../GlobalVariables/GlobalFunctions';
import { updateProfileFields } from '../Features/profile';

const SpouseDetails = (props) => {

    const [spIncValidation, setSpIncValidation] = React.useState(false)
    const [errTfn, setErrTfn] = React.useState(false);
    const profile = useSelector(state => state.profile.value);
    const dcflags = useSelector(state => state.dcflags.value);
    const dispatch = useDispatch();
    const [detailsChanged, setDetailsChanged] = React.useState(false);
    
    const [spousename, setSpousename] = React.useState("");
    const [spousetfn, setSpousetfn] = React.useState("");
    const [spouseincome, setSpouseincome] = React.useState("");
    const [spousedob, setSpousedob] = React.useState("");
    const [maritalstatuschanged, setMaritalstatuschanged] = React.useState("");
    const [warningflag, setWarningFlag] = React.useState(false);
    const [warningheader, setwarningheader] = React.useState("");
    const [warningtext, setwarningtext] = React.useState("");

useEffect(() => {
    if (profile.id){
      setSpousename(profile.spousename);
      setSpousetfn(profile.spousetfn);
      setSpouseincome(profile.spouseincome);
      setSpousedob(profile.spousedob);
      setMaritalstatuschanged(profile.maritalstatuschanged);
    }
  }, [profile]);


    const resetwarningflag = () => {

        setWarningFlag(false);
        setwarningheader("");
        setwarningtext("");
    }

    const handleSpouseIncomeInput = (e) => {
      setDetailsChanged(true);
        dispatch(updateSouseDetailFlag(true) );
        if (!validateDollar(e.target.value)){
            console.log('here');
            
            setSpIncValidation(true);
        } else {
            console.log('Here3');
            
            setSpIncValidation(false)}
        setSpouseincome(e.target.value);
    }
     const handlesetSpousename = (value) => {
      setDetailsChanged(true);
        dispatch(updateSouseDetailFlag(true) );
        setSpousename(value);
    }

    const handlesetSpousetfn = (value) => {
      setDetailsChanged(true);

        dispatch(updateSouseDetailFlag(true) );
        setSpousetfn(value);
        if (!validateTFN (value)){
          
          setErrTfn(true);
          
        }else {
          setErrTfn(false);
          
        }
    }
    const handlesetSpousedob = (value) => {
      setDetailsChanged(true);
        dispatch(updateSouseDetailFlag(true) );
        setSpousedob(value);
    }
    const handlesetMaritalstatuschanged = (value) => {
      setDetailsChanged(true);
          dispatch(updateSouseDetailFlag(true) );
          setMaritalstatuschanged(value);
    }


function handleSave(){
  if (spIncValidation || errTfn){
    return;
  }else {

  
        let spousedata = {
          id: profile.id,
          spousename: spousename,
          spousetfn: spousetfn,
          spouseincome: spouseincome,
          spousedob: spousedob,
          maritalstatuschanged: maritalstatuschanged
        }
        API.graphql({query: mutations.updateKTACustomer, variables: {input: spousedata}}).then(res => {
          console.log(res);
          dispatch(updateSouseDetailFlag(false) );
          setDetailsChanged(false);
          dispatch(updateProfileFields(spousedata));
                  
        }
        ).catch(e => {
          console.warn(e);
          setWarningFlag(true);
          setwarningheader("Error");
          setwarningtext("Error Saving Spouse Details");
        });
    }
}
  
    return ( <div className="w3-padding w3-container"> <br />
         {detailsChanged?<Button variant="contained" color='secondary' onClick={handleSave}>Save This Section</Button>:<></>}
         <Typography variant="h6" gutterBottom component="div" color={'primary'}> <b>My Spouse / Parter Details (if applicable)</b> </Typography>
        <TextField
          error={false}
          id="spousefullname"
          label="Souse Full Name"
          onBlur={handleSave}
          fullWidth
          margin='dense'
          value={spousename}
          onChange={(e)=> handlesetSpousename(e.target.value)}
          // onChange={(e)=> props.dispatch({type: 'SPOUSENAME_CHANGED', value: e.target.value})}
        />
        <TextField
          error={errTfn}
          id="spousetfn"
          label="Spouse TFN"
          value={spousetfn}
          onChange={(e)=> handlesetSpousetfn(e.target.value)}
          // onChange={(e)=> props.dispatch({type: 'SPOUSETFN_CHANGED', value: e.target.value})}
          helperText={" "}
          fullWidth
          margin='dense'
          onBlur={handleSave}
        />
        <FormControl fullWidth sx={{ m: 0 }} >
          <InputLabel htmlFor="outlined-adornment-amount">Spouse Income</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="SPOUSE INCOME"
            value={spouseincome}
          onChange={(e)=> handleSpouseIncomeInput(e)}
          error={spIncValidation}
          onBlur={handleSave}
          />
          <Typography variant='caption'>Number up-to two decimal places</Typography>
        </FormControl>
<br /><br />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale ="en">
       <DatePicker 
        error={false}
          id="spousedob"
          label="Spouse Date of birth"
          defaultValue=""
          format ={'DD/MM/YYYY'}
          helperText={""}
          value={spousedob?dayjs(spousedob):""}
          onChange={(e)=> handlesetSpousedob(e)}
          onBlur={handleSave}
          // onChange={(e)=> props.dispatch({type: 'SPOUSEDOB_CHANGED', value: e})}
       />
       </LocalizationProvider>
       
       <FormControl fullWidth margin='normal'>
       
        <InputLabel id="">Did your marital status change during the year?</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={maritalstatuschanged}
            label="No"
            onChange={(e)=> handlesetMaritalstatuschanged(e.target.value)}
            onBlur={handleSave}
            // onChange={(e)=> props.dispatch({type: 'MARITALSTATUSUPDATE_CHANGED', value: e.target.value})}
        >
            <MenuItem value={"Yes"}>Yes</MenuItem>
            <MenuItem value={"No"}>No</MenuItem>

        </Select>
        </FormControl>
        {detailsChanged?<Button variant="contained" color='secondary' onClick={handleSave}>Save This Section</Button>:<></>}
       <WarningModal warningflag={warningflag} warningheader={warningheader} warningtext={warningtext} resetwarningflag={resetwarningflag} />
    </div> );
};
 
export default SpouseDetails;
