
import * as React from 'react';
import Card from '@mui/material/Card';
import { CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


const BankInterestCard = (props) => {

    const [bank, setbank] = React.useState({name: '', interest: '', yourshare: ''});
    
React.useEffect(() => {
    setbank(props.bank);
}, [props.bank])

    const handleDelete =() =>{
        props.callBackFunction(bank)
    }

    return ( <div>

<Card className= 'w3-border' sx={{ minWidth: 275 }}>
<CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        }
        title={bank.name}
        subheader={'Interest : '+bank.interest+' Your Share : '+bank.yourshare}
      />
       
    </Card>

    </div> );
}
 
export default BankInterestCard;